import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import { IconArrowRight } from 'public/icons';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '12px 16px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
  },
  directionBadge: {
    borderRadius: 4,
    padding: '2px 4px',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white.main,
    fontWeight: 600,
    fontSize: 12,
    lineHeight: '14px',
  },
  stations: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
    marginTop: 8,
  },
  station: {
    color: theme.palette.black.black3,
    fontSize: 16,
    lineHeight: '20px',
    fontWeight: 600,
  },
  infos: { gap: 4, display: 'flex', marginTop: 6 },
  info: { color: theme.palette.gray.grayDark8, fontSize: 12, fontWeight: 14 },
  dot: { color: theme.palette.gray.grayLight24, fontSize: 12, fontWeight: 14 },
}));

const TwoWayTrainItem = ({
  trainTicket,
  containerStyle,
  isBackward = false,
}) => {
  const classes = useStyles();
  return (
    <Box className={classes.container} sx={containerStyle}>
      <Box className={classes.directionBadge}>
        {isBackward ? 'Chiều về' : 'Chiều đi'}
      </Box>
      <Box className={classes.stations}>
        <Box className={classes.station}>
          {trainTicket?.departureStationName}
        </Box>
        <IconArrowRight />
        <Box className={classes.station}>{trainTicket?.arrivedStationName}</Box>
      </Box>
      <Box className={classes.infos}>
        <Box className={classes.info}>
          {moment(trainTicket?.departureDate).format('HH:mm, DD/MM')}
        </Box>
        <Box className={classes.dot}>•</Box>
        <Box className={classes.info}>
          {moment(trainTicket?.arriveDate).format('HH:mm, DD/MM')}
        </Box>
        <Box className={classes.dot}>•</Box>
        <Box className={classes.info}>{trainTicket?.tripCode}</Box>
      </Box>
    </Box>
  );
};
export default TwoWayTrainItem;
