import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { Col } from 'modules/common/Elements';
import EastIcon from '@mui/icons-material/East';

const DetailBooking = ({ briefBooking }) => {
  const { t } = useTranslation();
  return (
    <>
      <Box width="100%" mt={3}>
        <Box
          style={{
            backgroundColor: '#F2F3F3',
            padding: '8px',
            display: 'flex',
          }}
        >
          <Box
            style={{
              width: '30%',
              backgroundColor: '#F2F3F3',
              borderRight: '2px solid rgba(117, 117, 117, 0.32)',
            }}
          >
            <Typography
              variant="h6"
              style={{ fontWeight: 'bold', color: '#FF3366' }}
            >
              {briefBooking?.hotelBookingId}
            </Typography>
            <Typography
              variant="body2"
              style={{
                color: '#8C9497',
              }}
            >
              {t('IDS_MT_TEXT_ROOM_CODE')}
            </Typography>
          </Box>
          <Box display="flex" marginLeft="4px" marginTop="4px">
            <Typography
              style={{ color: '#00B6F3', fontSize: 16, fontWeight: 600 }}
            >
              {briefBooking?.checkIn}
            </Typography>
            <Col style={{ marginLeft: 4 }}>
              <EastIcon style={{ color: '#8C9497' }} />
              <span style={{ color: '#8C9497', fontSize: 14 }}>
                {briefBooking?.numNights}
              </span>
            </Col>
            <Typography
              style={{ color: '#00B6F3', fontSize: 16, fontWeight: 600 }}
            >
              {briefBooking?.checkOut}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box mt={2}>
        <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
          Thông tin khách sạn
        </Typography>
        <Box display="flex">
          <Typography
            variant="body2"
            style={{
              color: '#8C9497',
              width: '60px',
            }}
          >
            {t('IDS_MT_TEXT_HOTEL_NAME')}
          </Typography>
          <Typography variant="body2" style={{ textAlign: 'left' }}>
            {briefBooking?.hotelName}
          </Typography>
        </Box>
        <Box display="flex">
          <Typography
            variant="body2"
            style={{
              color: '#8C9497',
              width: '60px',
            }}
          >
            {t('IDS_MT_TEXT_HOTEL_ADDRESS')}
          </Typography>
          <Typography variant="body2" style={{ textAlign: 'left' }}>
            {briefBooking?.hotelAddress}
          </Typography>
        </Box>
      </Box>
      <Box mt={3} style={{ border: '1px solid #FF3366', padding: 4 }}>
        <Typography variant="subtitle2" style={{}}>
          {briefBooking?.roomNightText}
        </Typography>
      </Box>
    </>
  );
};
export default DetailBooking;
