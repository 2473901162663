import { Divider, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import {
  cancelRequestBooking,
  confirmRequestBooking,
  getHotelBriefBooking,
} from 'api/hotel';
import cookie from 'js-cookie';
import moment from 'moment';
import { IconMytour, IconSucessfull } from 'public/icons';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as constants from 'utils/constants';
import * as gtm from 'utils/gtm';

const HotelConfirmRequestBooking = () => {
  const { status } = useParams();
  const location = useLocation();
  const eventName = 'hotel_confirmed_booking';
  const hotelConfirmedType = 'Email';

  const { t } = useTranslation();
  const [errorConfirm, setErrorConfirm] = useState();
  const params = new URLSearchParams(location.search);
  const bookingHash = params.get('bookingHash');
  const headerUtmSource = params.get('utm_source');
  const headerUtmMedium = params.get('utm_medium');
  const headerUtmCampaign = params.get('utm_campaign');
  const [briefBooking, setBriefBooking] = useState();
  let headerUtm = {};
 
  const fetchBriefBooking = useCallback(async () => {
    const { data } = await getHotelBriefBooking({
      bookingHash,
    });
    setBriefBooking(data);
  }, [bookingHash]);

  const handleAddHeader = () => {
    const addUtmHeader = (key, value) => {
      if (value) {
        headerUtm[key] = value;
      }
    };
    addUtmHeader('utm_source', headerUtmSource);
    addUtmHeader('utm_medium', headerUtmMedium);
    addUtmHeader('utm_campaign', headerUtmCampaign);
    return headerUtm;
  };
  
  useEffect(() => {
    if (!bookingHash) return;
    fetchBriefBooking();
  }, [bookingHash, fetchBriefBooking]);

  useEffect(() => {
    if (!briefBooking) return;
    const dataGtm = {
      device_id: cookie.get(constants.DEVICE_ID_IN_SERVER),
      user_id: null,
      hotel_id: briefBooking?.rootHotelId,
      booking_id: briefBooking?.hotelBookingId,
      hotel_confirmed_type: hotelConfirmedType,
      hotel_name: briefBooking?.hotelName,
      create_date: moment(briefBooking?.createdDate).format(
        'HH:mm:ss, DD/MM/YYYY'
      ),
      hotel_confirmed_date: '',
      hotel_confirmed: null,
    };
    gtm.addEventGtm(eventName, dataGtm);
  }, [briefBooking, hotelConfirmedType]);

  const confirmEmptyRoom = useCallback(async () => {
    try {
      const headers = handleAddHeader() || {};
      const { data } = await confirmRequestBooking(
        {
          reason: '',
          expiredTime: '30',
          bookingHash,
        },
        headers
      );
      if (data?.code === 200) {
        if (briefBooking) {
          const dataGtm = {
            device_id: cookie.get(constants.DEVICE_ID_IN_SERVER),
            user_id: null,
            hotel_id: briefBooking?.rootHotelId,
            booking_id: briefBooking?.hotelBookingId,
            hotel_confirmed_type: hotelConfirmedType,
            hotel_name: briefBooking?.hotelName,
            create_date: moment(briefBooking?.createdDate).format(
              'HH:mm:ss, DD/MM/YYYY'
            ),
            hotel_confirmed_date: moment().format('HH:mm:ss, DD/MM/YYYY'),
            hotel_confirmed: 1,
          };
          gtm.addEventGtm(eventName, dataGtm);
        }

        toast.success(data.message);
        setErrorConfirm(false);
      } else {
        toast.error(data.message);
        setErrorConfirm(data.message);
      }
    } catch (error) {}
  }, [bookingHash, briefBooking]);

  const cancelEmptyRoom = useCallback(async () => {
    try {
      const headers = handleAddHeader() || {};
      const { data } = await cancelRequestBooking(
        {
          reason: '',
          expiredTime: '30',
          bookingHash,
        },
        headers
      );
      if (data?.code === 200) {
        if (briefBooking) {
          const dataGtm = {
            device_id: cookie.get(constants.DEVICE_ID_IN_SERVER),
            user_id: null,
            hotel_id: briefBooking?.rootHotelId,
            booking_id: briefBooking?.hotelBookingId,
            hotel_confirmed_type: hotelConfirmedType,
            hotel_name: briefBooking?.hotelName,
            create_date: moment(briefBooking?.createdDate).format(
              'HH:mm:ss, DD/MM/YYYY'
            ),
            hotel_confirmed_date: moment().format('HH:mm:ss, DD/MM/YYYY'),
            hotel_confirmed: 0,
          };
          gtm.addEventGtm(eventName, dataGtm);
        }
        toast.success(data.message);
        setErrorConfirm(false);
      } else {
        toast.error(data.message);
        setErrorConfirm(data.message);
      }
    } catch (error) {}
  }, [bookingHash, briefBooking]);

  const sendRequestBooking = useCallback(() => {
    if (status === 'success') {
      confirmEmptyRoom();
    } else {
      cancelEmptyRoom();
    }
  }, [cancelEmptyRoom, confirmEmptyRoom, status]);

  useEffect(() => {
    if (!bookingHash) return;
    if (briefBooking) {
      sendRequestBooking();
    }
  }, [bookingHash, briefBooking, sendRequestBooking]);

  return (
    <React.Fragment>
      <Container maxWidth="lg">
        <Box
          sx={{
            minHeight: '100vh',
            padding: '24px',
            color: '#0B0C0D',
          }}
        >
          <Box display="flex" justifyContent="space-between">
            <IconMytour />
          </Box>
          <Divider
            style={{ backgroundColor: '#FF3366', height: 2, marginTop: 8 }}
          />
          {errorConfirm ? (
            <Box
              mt={4}
              p={4}
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              textAlign="center"
            >
              <Typography variant="h5">{errorConfirm}</Typography>
            </Box>
          ) : (
            <Box
              mt={4}
              p={4}
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              textAlign="center"
            >
              <IconSucessfull />
              <Typography>
                {t('IDS_TEXT_CONFIRM_1')}{' '}
                <b>
                  {status === 'success'
                    ? `"${t('IDS_MT_TEXT_CONFIRM_EMPTY_ROOM')}"`
                    : `"${t('IDS_MT_TEXT_EMPTY_ROOM')}"`}
                </b>
              </Typography>
              <Typography>{t('IDS_TEXT_CONFIRM_2')}</Typography>
            </Box>
          )}
        </Box>
      </Container>
    </React.Fragment>
  );
};
export default HotelConfirmRequestBooking;
