/* eslint-disable no-nested-ternary */
import { Box } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { WHITE } from 'assets/theme/colors';
import clsx from 'clsx';
import moment from 'moment';
import { IconCheckList, IconOrderCode } from 'public/icons';
import { useTranslation } from 'react-i18next';
import {
  IS_MYTOUR,
  flightPaymentStatus,
  flyxBookingStatus,
  listIcons,
  prefixUrlIcon,
} from 'utils/constants';
import { getExpiredTimeMillisecond } from 'utils/helpers';
import { DATE_FORMAT_ALL_ISOLATE } from 'utils/moment';

const useStyles = makeStyles((theme) => ({
  listIcon: {
    backgroundColor: theme.palette.gray.grayLight26,
    borderRadius: 8,
    height: 48,
    display: 'flex',
    gap: '8px',
    padding: 16,
    position: 'relative',
  },
  iconBoxItemOder: {
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
  widthNormal: {
    width: '25%',
  },
  widthTwoWay: {
    width: '20%',
  },
  widthTwoWayDouble: {
    width: '40%',
  },
  font18: {
    fontSize: 18,
    lineHeight: '22px',
  },
  timeHold: {
    color: `${theme.palette.orange.main} !important`,
    paddingLeft: 8,
  },
  frameStt: {
    padding: '4px 8px',
    borderRadius: 4,
    color: WHITE,
    textTransform: 'uppercase',
    zIndex: 1,
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '17px',
  },
  crushText: {
    zIndex: 1,
    position: 'relative',
    fontSize: '16px',
    lineHeight: '19px',
    color: theme.palette.black.black3,
  },
  iconStatusOrderHotel: {
    width: 86,
    height: 64,
  },
  boxName: {
    fontSize: '14px',
    lineHeight: '17px',
    color: theme.palette.black.black3,
  },
}));

const OrderInfo = ({ orderDetail = {}, paymentStatus = {} }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();

  const isTwoWay = orderDetail?.tickets?.length === 2;
  const bookingCode = orderDetail?.booking?.bookingCode;
  const orderStatus = orderDetail?.booking?.bookingStatus;
  const outBoundReservationCode = orderDetail?.tickets[0]?.reservationCode;
  const inBoundReservationCode = isTwoWay
    ? orderDetail?.tickets[1]?.reservationCode
    : null;
  const paymentExpiredTime = orderDetail?.booking?.paymentExpiredTime;
  const hideReservationCode = !(
    IS_MYTOUR &&
    orderDetail?.booking?.bookingStatus === flyxBookingStatus.HOLDING
  );

  return (
    <Box sx={{ display: 'flex', gap: '16px' }}>
      <Box
        className={clsx(
          classes.listIcon,
          isTwoWay ? classes.widthTwoWay : classes.widthNormal
        )}
        sx={{ flexDirection: 'column' }}
      >
        <Box className={classes.iconBoxItemOder}>
          <IconOrderCode />
        </Box>
        <Box className={classes.boxName}>{t('IDS_TEXT_FLIGHT_ORDER_CODE')}</Box>
        <Box
          sx={{
            fontWeight: '600',
            fontSize: '18px',
            lineHeight: '21px',
            color: theme.palette.primary.main,
          }}
        >
          {bookingCode}
        </Box>
      </Box>
      {hideReservationCode && (
        <Box
          className={clsx(
            classes.listIcon,
            isTwoWay ? classes.widthTwoWayDouble : classes.widthNormal
          )}
          sx={{ flexDirection: !isTwoWay ? 'column' : undefined }}
        >
          <Box className={classes.iconBoxItemOder}>
            <IconOrderCode />
          </Box>
          {isTwoWay ? (
            <>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '8px',
                  width: '50%',
                }}
              >
                <Box className={classes.boxName}>
                  {t('IDS_TEXT_FLIGHT_BOOK_CODE_OUTBOUND')}
                </Box>
                <Box
                  sx={{
                    fontWeight: '600',
                    fontSize: '18px',
                    lineHeight: '21px',
                    color: theme.palette.secondary.main,
                  }}
                >
                  {outBoundReservationCode
                    ? orderStatus === flightPaymentStatus.REFUNDED ||
                      orderStatus === flightPaymentStatus.REFUNDED_POSTED
                      ? t('IDS_TEXT_ORDER_PAYMENT_STATUS_CANCELLED')
                      : outBoundReservationCode
                    : t('IDS_TEXT_ORDER_PAYMENT_STATUS_PROCESSING')}
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '8px',
                  width: '50%',
                }}
                borderLeft={`solid 1px ${theme.palette.gray.grayLight23}`}
                pl={2}
              >
                <Box className={classes.boxName}>
                  {t('IDS_TEXT_FLIGHT_BOOK_CODE_INBOUND')}
                </Box>
                <Box
                  sx={{
                    fontWeight: '600',
                    fontSize: '18px',
                    lineHeight: '21px',
                    color: theme.palette.secondary.main,
                  }}
                >
                  {inBoundReservationCode
                    ? orderStatus === flightPaymentStatus.REFUNDED ||
                      orderStatus === flightPaymentStatus.REFUNDED_POSTED
                      ? t('IDS_TEXT_ORDER_PAYMENT_STATUS_CANCELLED')
                      : inBoundReservationCode
                    : t('IDS_TEXT_ORDER_PAYMENT_STATUS_PROCESSING')}
                </Box>
              </Box>
            </>
          ) : (
            <>
              <Box className={classes.boxName}>
                {t('IDS_TEXT_FLIGHT_BOOK_CODE')}
              </Box>
              <Box
                sx={{
                  fontWeight: '600',
                  fontSize: '18px',
                  lineHeight: '21px',
                  color: theme.palette.secondary.main,
                }}
              >
                {outBoundReservationCode
                  ? orderStatus === flightPaymentStatus.REFUNDED ||
                    orderStatus === flightPaymentStatus.REFUNDED_POSTED
                    ? t('IDS_TEXT_ORDER_PAYMENT_STATUS_CANCELLED')
                    : outBoundReservationCode
                  : t('IDS_TEXT_ORDER_PAYMENT_STATUS_PROCESSING')}
              </Box>
            </>
          )}
        </Box>
      )}
      <Box
        className={clsx(
          classes.listIcon,
          isTwoWay ? classes.widthTwoWay : classes.widthNormal
        )}
        sx={{ flexDirection: 'column', alignItems: 'flex-start' }}
      >
        <Box className={classes.iconBoxItemOder}>
          <IconCheckList />
        </Box>
        <Box sx={{ display: 'flex' }}>
          <Box className={classes.boxName}>{t('IDS_MT_TEXT_STATUS')}</Box>
          {(orderStatus === flightPaymentStatus.HOLDING ||
            orderStatus === flightPaymentStatus.PENDING) && (
            <Box className={clsx(classes.boxName, classes.timeHold)}>
              {`Còn ${getExpiredTimeMillisecond(paymentExpiredTime || 0)}`}
            </Box>
          )}
        </Box>
        <Box className={classes.frameStt} bgcolor={paymentStatus.color}>
          {paymentStatus.title}
        </Box>
      </Box>
      <Box
        className={clsx(
          classes.listIcon,
          isTwoWay ? classes.widthTwoWay : classes.widthNormal
        )}
        sx={{ flexDirection: 'column', alignItems: 'flex-start' }}
      >
        <Box className={classes.iconBoxItemOder}>
          <img src={`${prefixUrlIcon}${listIcons.IconCalenderOrder}`} alt="" />
        </Box>
        <Box className={classes.boxName}>{t('IDS_MT_TEXT_DATE_BOOKING')}</Box>
        <Box className={classes.crushText}>
          {moment(orderDetail.booking?.createdTime).format(
            DATE_FORMAT_ALL_ISOLATE
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default OrderInfo;
