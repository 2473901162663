import { Box, Button, useTheme } from '@mui/material';
import Link from '@mui/material/Link';
import { makeStyles } from '@mui/styles';
import { IconArrowRight } from 'public/icons';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TEST } from 'utils/constants';
import { isEmpty, stringSlug } from 'utils/helpers';
import SlideShow from '../slideShow/SlideShow';
import HotelNewCard from './HotelNewCard';

export const customStyle = {
  background: 'white',
  height: '40px',
  width: '40px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 2,
  borderRadius: '100px',
  boxShadow: '0px 8px 12px rgb(0 0 0 / 10%)',
  cursor: 'pointer',
  position: 'absolute',
  top: '50%',
  transform: 'translate(-50%, -50%)',
};

export function SamplePrevArrow(props) {
  const classes = useStyles();
  const { customStyleArrow, onClick } = props;
  return (
    <div
      className={classes.wrapArrow}
      style={{ ...customStyleArrow, left: 0 }}
      onClick={onClick}
      aria-hidden="true"
    >
      <IconArrowRight
        className={`svgFillAll ${classes.iconArrow}`}
        style={{ transform: 'rotate(180deg)' }}
      />
    </div>
  );
}

export function SampleNextArrow(props) {
  const classes = useStyles();
  const { customStyleArrow, onClick } = props;
  return (
    <div
      className={classes.wrapArrow}
      style={{ ...customStyleArrow, right: -38 }}
      onClick={onClick}
      aria-hidden="true"
    >
      <IconArrowRight className={`svgFillAll ${classes.iconArrow}`} />
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    marginBottom: 24,
    marginTop: 8,
    backgroundColor: 'white',
  },
  content: {
    maxWidth: 1200,
    width: '100%',
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  hotelGroup: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  hotelText: { display: 'flex', flexDirection: 'column' },
  titleText: {
    fontWeight: 600,
    fontSize: 18,
    lineHeight: '21px',
    color: '#1A202C',
    display: 'flex',
    justifyContent: 'space-between',
  },
  imageContent: {
    borderRadius: 8,
    display: 'flex',
    alignItems: 'center',
  },
  titleSubText: {
    fontSize: 14,
    lineHeight: '17px',
    color: '#4A5568',
    margin: '4px 0px 12px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  iconArrow: {
    width: 16,
    height: 17,
    stroke: theme.palette.black.black3,
  },
  wrapArrow: {
    '&:hover': {
      '& svg': {
        stroke: theme.palette.blue.blueLight8,
        transition: 'all ease .2s',
      },
    },
  },
  listChild: {
    gridTemplateColumns: 'repeat(5, 1fr)',
    display: 'grid',
    gap: '12px',
  },
  btnNoView: {
    border: '1px solid #CBD5E0',
    borderRadius: 6,
    padding: '5px 6px',
    fontSize: 12,
    lineHeight: '14px',
    color: '#4A5568',
    cursor: 'pointer',
  },
}));

const CrossSaleHotel = ({
  topHotels,
  provinceInfo,
  paramsUrl,
  cityArrival,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const { t: trans } = useTranslation();

  const [idxActive, setActiveIdx] = useState(0);
  const [isView] = useState(true);

  const getLinkInfo = () => {
    const baseUrl = TEST ? 'https://alpha.mytour.vn' : 'https://mytour.vn';
    return `${baseUrl}/khach-san/${provinceInfo.provinceId}/khach-san-tai-${
      !isEmpty(provinceInfo.arrival) ? stringSlug(provinceInfo.arrival) : ''
    }.html?checkIn=${paramsUrl?.checkIn}&checkOut=${
      paramsUrl?.checkOut
    }&children=0&rooms=1&adults=2&filters=&aliasCode=${
      provinceInfo.provinceId
    }&useBasePrice=true&page=1&size=40&categoryGroup=hotel`;
  };

  const settings = {
    dots: false,
    infinite: topHotels.length > 3,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow customStyleArrow={customStyle} />,
    prevArrow:
      idxActive > 0 ? (
        <SamplePrevArrow customStyleArrow={customStyle} />
      ) : (
        <></>
      ),
    beforeChange: (current, next) => setActiveIdx(next),
  };

  const convertedChain = (chains) => {
    const convertedChains = [];
    let temp = [];
    chains.forEach((el, idx) => {
      if (!el.hiddenPrice) {
        temp.push(el);
        if (temp.length % 5 === 0 || idx === chains.length - 1) {
          convertedChains.push(temp);
          temp = [];
        }
      }
    });
    return convertedChains.slice(0, 9);
  };

  const pushEventGtmHoltelList = () => {
    // push gtm log
    // const informationUser = systemReducer?.informationUser || {};
    // let dataGtm = {
    //   ...gtm.getCrossSaleHotelToFlightTicket(informationUser),
    // };
    // gtm.addEventGtm(listEventFlight.cross_sale_flight_view_hotel_list, dataGtm);
  };

  if (isEmpty(topHotels) || !isView) return null;
  const convertedTopHotels = convertedChain(topHotels);
  return (
    <Box className={classes.container}>
      <Box className={classes.content}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box className={classes.hotelText} mt={1}>
            <Box className={classes.titleText}>
              {trans('IDS_MT_TEXT_TO_GO_SINGLE')} {cityArrival}{' '}
              {trans('IDS_MT_TEXT_DONT_KNOW_WHERE_TO_BOOK')}
            </Box>
            <Box className={classes.titleSubText}>
              {trans('IDS_MT_TEXT_GREAT_HOTELS_ON_OFFER')}{' '}
            </Box>
          </Box>
          <Button
            variant="outlined"
            color="secondary"
            onClick={pushEventGtmHoltelList}
          >
            <Link
              color="inherit"
              href={getLinkInfo()}
              style={{
                marginRight: 4,
                color: theme.palette.secondary.main,
                marginLeft: 4,
                textTransform: 'none',
                textDecoration: 'none',
              }}
              target="_blank"
            >
              {trans('IDS_MT_TEXT_LOAD_MORE')}
            </Link>
          </Button>
        </Box>
        <SlideShow
          settingProps={settings}
          className={classes.imageContent}
          slickDotStyle={classes.slickDot}
        >
          {convertedTopHotels?.map((topHotel, idx) => (
            <div>
              <Box className={classes.listChild} key={idx.toString()}>
                {topHotel?.map((el) => {
                  return (
                    <HotelNewCard
                      item={{
                        ...el,
                        thumbnail: { image: el.image },
                        rating: {
                          hotel: {
                            rating: el.ratingLevel,
                            count: el.numberOfReviews,
                          },
                        },
                      }}
                      paramsInit={paramsUrl}
                      key={el.id}
                      isCrossSale
                    />
                  );
                })}
              </Box>
            </div>
          ))}
        </SlideShow>
      </Box>
    </Box>
  );
};

export default CrossSaleHotel;
