/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */

import { Box, LinearProgress } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { getTrainTicketDetail, getTrainTicketStatus } from 'api/train';
import { NEUTRAL_200, WHITE } from 'assets/theme/colors';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { getBaseUrl } from 'utils/api';
import * as constants from 'utils/constants';
import TwoWayTrainItem from './components/TwoWayTrainItem';
import WayTrainItem from './components/WayTrainItem';
import BookingFail from './status/BookingFail';
import BookingPending from './status/BookingPending';
import BookingSucess from './status/BookingSucess';
import BookingUndefined from './status/BookingUndefined';

const useStyles = makeStyles((theme) => ({
  divider: {
    borderBottom: `1px solid ${theme.palette.gray.grayLight22}`,
    width: '100%',
  },
  container: {
    margin: 'auto',
    maxWidth: 630,
    background: WHITE,
    borderRadius: '0px 0px 20px 20px',
    paddingBottom: 12,
  },
  circles: {
    display: 'grid',
    gridTemplateColumns: 'repeat(24, 1fr)',
    placeItems: 'center',
    position: 'absolute',
    left: 20,
    right: 20,
    top: -2,
  },
  circle: {
    width: 4,
    height: 4,
    borderRadius: '50%',
    backgroundColor: theme.palette.secondary.main,
  },
  boxCodeInfo: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    backgroundColor: theme.palette.common.white,
    borderRadius: 20,
    minHeight: 80,
    padding: '16px 0',
    maxWidth: 630,
    margin: 'auto',
    position: 'relative',
  },
  orderCode: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: 4,
    textAlign: 'center',
  },
  codeLeft: {
    color: theme.palette.blue.blueLight8,
    fontSize: 24,
    lineHeight: '30px',
    fontWeight: 600,
  },
  codeRight: {
    color: theme.palette.secondary.main,
    fontSize: 24,
    lineHeight: '30px',
    fontWeight: 600,
  },
}));

const Status = (props) => {
  const { status } = props;
  switch (status) {
    case 'success':
      return <BookingSucess />;
    case 'fail':
      return <BookingFail />;
    case 'undefined':
      return <BookingUndefined />;
    default:
      return <BookingPending />;
  }
};

const Booking = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { bookingId } = useParams();
  const location = useLocation();

  const [trainTicket, setTrainTicket] = useState();
  const [paymentStatus, setPaymentStatus] = useState();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchPaymentStatus = async () => {
      const { data } = await getTrainTicketStatus({
        url:
          getBaseUrl(constants.CHECKOUT_PAGE) +
          location.pathname +
          location.search,
      });
      if (data?.code === 200) setPaymentStatus(data?.paymentStatus);
      else setPaymentStatus('undefined');
    };

    const fetchTrainTicket = async () => {
      const { data } = await getTrainTicketDetail({
        bookId: bookingId,
      });
      if (data?.code === 200) setTrainTicket(data?.data?.history?.[0]);
    };

    bookingId && fetchTrainTicket();
    bookingId && fetchPaymentStatus();
  }, []);

  if (!trainTicket) return <LinearProgress />;

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.secondary.main,
        minHeight: '100vh',
        padding: '16px',
      }}
    >
      <Status status={paymentStatus} />
      <Box className={classes.container}>
        <Box className={classes.divider} />
        {trainTicket?.trains?.length > 1 ? (
          trainTicket?.trains?.map((p, index) => (
            <TwoWayTrainItem trainTicket={p} key={p?.Id} isBackward={!!index} />
          ))
        ) : (
          <WayTrainItem trainTicket={trainTicket?.trains?.[0]} />
        )}
      </Box>

      <Box className={clsx(classes.boxCodeInfo)}>
        <Box className={classes.circles}>
          {[...Array(24)].map((p, index) => (
            <Box className={classes.circle} key={index} />
          ))}
        </Box>
        <Box
          className={classes.orderCode}
          sx={{
            borderRight: `1px solid ${NEUTRAL_200}`,
          }}
        >
          <Box
            sx={{
              fontSize: '14px',
              lineHeight: '17px',
              color: theme.palette.black.black3,
            }}
          >
            {t('IDS_TEXT_FLIGHT_ORDER_CODE')}
          </Box>
          <div className={classes.codeLeft}>{trainTicket?.bookId}</div>
        </Box>
        {true && (
          <Box className={classes.orderCode}>
            <Box
              sx={{
                fontSize: '14px',
                lineHeight: '17px',
                color: theme.palette.black.black3,
              }}
            >
              {t('IDS_TEXT_FLIGHT_BOOK_CODE')}
            </Box>
            <Box className={classes.codeRight}>{trainTicket?.bookCode}</Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Booking;
