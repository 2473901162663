import { Box, ButtonBase, LinearProgress } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { makeStyles, useTheme } from '@mui/styles';
import { getHotelsAvailability } from 'api/flight';
import { NEUTRAL_100, NEUTRAL_400, RED, WHITE } from 'assets/theme/colors';
import clsx from 'clsx';
import LanguageSwitcher from 'modules/common/LanguageSwitcher';
import useFlightBooking from 'modules/hooks/useFlightBooking';
import moment from 'moment';
import { IconChevronRight } from 'public/icons';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AGE_CATEGORY,
  CUSTOMER_TYPE_TEXT,
  DEV,
  GENDER_LIST,
  IS_MYTOUR,
  LIST_SCREEN_STATUS,
} from 'utils/constants';
import { getPlatform, isEmpty } from 'utils/helpers';
import {
  DELAY_TIMEOUT_POLLING,
  flyxBookingStatus,
} from './../../../utils/constants';
import { domesticInBound } from './../../../utils/domestic';
import CrossSaleHotel from './../../common/CrossSaleHotel/CrossSaleHotel';
import BookingBankTranfer from './payment/BookingBankTranfer';
import BookingFail from './payment/BookingFail';
import BookingFailPaymentSuccess from './payment/BookingFailPaymentSuccess';
import BookingHoldingFail from './payment/BookingHoldingFail';
import BookingHoldingSucess from './payment/BookingHoldingSucess';
import BookingPending from './payment/BookingPending';
import BookingSucess from './payment/BookingSucess';
import BookingSucessAndExporting from './payment/BookingSucessAndExporting';
import BookingUndefined from './payment/BookingUndefined';
import OrderDetail from './payment/orderDetail/OrderDetail';
import WayFlightItemDetail from './payment/orderDetail/WayFlightItemDetail';

const useStyles = makeStyles((theme) => ({
  divider: {
    borderBottom: `1px solid ${theme.palette.gray.grayLight22}`,
    width: '100%',
  },
  container: {
    margin: 'auto',
    maxWidth: 630,
    height: 'auto',
    padding: 24,
    background: WHITE,
    borderRadius: '0px 0px 8px 8px',
  },
  wrapBorder: {
    display: 'flex',
    justifyContent: 'space-around',
    margin: 'auto',
    maxWidth: 630,
    marginTop: -8,
  },
  circle: {
    width: 16,
    height: 16,
    transform: 'rotate(-45deg)',
    borderRadius: '50%',
    borderLeftColor: 'transparent',
    borderBottomColor: 'transparent',
    background: '#ecf3f7',
    marginTop: -8,
  },
  boxCodeInfo: {
    display: 'flex',
    gap: '16px',
    marginTop: 12,
  },
  oneColumn: {
    gridTemplateColumns: '1fr',
  },
  orderCode: {
    flex: 1,
    padding: '0 16px',
    borderRadius: 12,
    boxSizing: 'border-box',
    border: `2px dashed ${NEUTRAL_400}`,
    background: NEUTRAL_100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '4px',
    textAlign: 'center',
    width: '100%',
  },
  codeLeft: {
    color: theme.palette.blue.blueLight8,
    fontSize: 18,
    lineHeight: '22px',
    fontWeight: 600,
  },
  codeRight: {
    color: theme.palette.secondary.main,
    fontSize: 18,
    lineHeight: '22px',
    fontWeight: 600,
  },
  ticketItem: {
    width: '100%',
    padding: '16px 0',
  },
  title: {
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '20px',
    color: theme.palette.black.black3,
  },
  information: {
    fontSize: '14px',
    lineHeight: '20px',
    color: theme.palette.black.black3,
  },
  row: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const Booking = () => {
  const {
    screenStatus,
    flyxBooking: orderDetail,
    paymentStatusDetail,
    agentsInfo,
  } = useFlightBooking();

  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const platform = getPlatform();
  const isWebsite = platform === 'website';

  const [viewOrderDetail, setViewOrderDetail] = useState(false);
  const [topHotels, setTopHotels] = useState([]);
  const [provinceInfo, setProvinceInfo] = useState(0);
  const [paramsUrl, setParamsUrl] = useState();

  const toggleDrawerOrderDetail = (open) => () => {
    setViewOrderDetail(open);
  };

  let breakPolling = true;
  const fetchAvailability = async () => {
    let pollingAvailability = true;
    try {
      const tempParamsUrl = {
        checkIn: orderDetail?.tickets[0]?.departureDate,
        checkOut: moment(orderDetail?.tickets[0]?.departureDate, 'DD-MM-YYYY')
          .add(1, 'd')
          .format('DD-MM-YYYY'),
        children: 0,
        rooms: 1,
        adults: 2,
        filters: {},
        aliasCode: provinceInfo.provinceId,
        useBasePrice: true,
        page: 1,
        size: 40,
        categoryGroup: 'hotel',
      };
      while (pollingAvailability && breakPolling) {
        const { data } = await getHotelsAvailability(tempParamsUrl);
        if (data.code === 200) {
          setParamsUrl(tempParamsUrl);
          if (data.data.completed) {
            if (data.data.total > 0) {
              setTopHotels(data.data.items);
            }
            pollingAvailability = false;
            break;
          }
          await new Promise((resolve) =>
            setTimeout(resolve, DELAY_TIMEOUT_POLLING)
          );
        } else {
          pollingAvailability = false;
        }
      }
    } catch (error) {
      pollingAvailability = false;
    }
  };

  useEffect(() => {
    if (isEmpty(provinceInfo) || isEmpty(orderDetail)) return;
    fetchAvailability();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provinceInfo, orderDetail]);

  const getProvincesFromList = () => {
    // eslint-disable-next-line array-callback-return
    domesticInBound?.map((ele) => {
      if (
        ele?.code?.split('-')[1] === orderDetail?.tickets[0]?.arrivalAirport &&
        ele?.code?.split('-')[0] === orderDetail?.tickets[0]?.departureAirport
      ) {
        setProvinceInfo(ele);
      }
    });
  };

  useEffect(() => {
    if (isEmpty(orderDetail)) return;
    getProvincesFromList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderDetail]);

  if (!orderDetail || !paymentStatusDetail || !agentsInfo)
    return <LinearProgress />;

  const cityArrival = orderDetail?.misc?.airports.find(
    (ele) => ele?.code === orderDetail?.tickets[0]?.arrivalAirport
  )?.cityName;

  const isTwoWay = orderDetail?.tickets?.length === 2;
  const bookingCode = orderDetail?.booking?.bookingCode;
  const outBoundReservationCode = orderDetail?.tickets?.[0]?.reservationCode;
  const inBoundReservationCode = isTwoWay
    ? orderDetail?.tickets?.[1]?.reservationCode
    : null;
  const passengers = orderDetail?.passengers;
  const numPassenger = orderDetail?.booking?.numPassenger;
  const contact = orderDetail?.contact;

  const airLinesInfo = orderDetail?.misc?.airlines;
  const airPortsInfo = orderDetail?.misc?.airports;

  const bankTransferInfo = paymentStatusDetail?.transferInfo;
  const bankTransferOptions = paymentStatusDetail?.transferOptions;

  const flightDetails = orderDetail?.tickets?.map((p) => p?.flightDetails);

  const linkWebsite = agentsInfo.website;
  const hideReservationCode =
    !(IS_MYTOUR &&
    orderDetail?.booking?.bookingStatus === flyxBookingStatus.HOLDING);

  const getScreenStatus = () => {
    switch (screenStatus) {
      case LIST_SCREEN_STATUS.SUCCESS:
        return <BookingSucess />;
      case LIST_SCREEN_STATUS.SUCCESS_AND_EXPORTING:
        return <BookingSucessAndExporting />;
      case LIST_SCREEN_STATUS.HOLDING_SUCCESS:
        return (
          <BookingHoldingSucess orderDetail={orderDetail} contact={contact} />
        );
      case LIST_SCREEN_STATUS.FAILED:
        return <BookingFail orderDetail={orderDetail} />;
      case LIST_SCREEN_STATUS.BOOKING_FAIL:
        return <BookingFailPaymentSuccess orderDetail={orderDetail} />;
      case LIST_SCREEN_STATUS.HOLDING_FAIL:
        return <BookingHoldingFail orderDetail={orderDetail} />;
      case LIST_SCREEN_STATUS.UNDEFINED:
        return <BookingUndefined />;
      case LIST_SCREEN_STATUS.LOADING:
        return <BookingPending />;
      case LIST_SCREEN_STATUS.WAITING_PAYMENT:
        return (
          <BookingBankTranfer
            bankTransferInfo={bankTransferInfo}
            bankTransferOptions={bankTransferOptions}
          />
        );
      default:
        return <BookingUndefined />;
    }
  };

  return (
    <Box
      sx={{
        background: '#ecf3f7',
        minHeight: '100vh',
      }}
    >
      <Box display="flex" justifyContent="flex-end" width="100%">
        <LanguageSwitcher languageCode={orderDetail?.booking?.languageCode} />
      </Box>
      {isWebsite && (IS_MYTOUR || DEV) && (
        <Box mt={3}>
          <CrossSaleHotel
            topHotels={topHotels}
            provinceInfo={provinceInfo}
            paramsUrl={paramsUrl}
            cityArrival={cityArrival}
          />
        </Box>
      )}
      {getScreenStatus()}
      <Box className={classes.container} mb={1}>
        {(moment().hour() >= 23 || moment().hour() <= 7) && (
          <Typography sx={{ color: RED }}>{t('IDS_TEXT_NOTE')}</Typography>
        )}
        <div className={classes.divider} />
        <Box display="flex" justifyContent="center" width="100%">
          <Button
            typeButton="text"
            width="fit-content"
            onClick={toggleDrawerOrderDetail(true)}
          >
            <Typography sx={{ marginRight: '8px', textTransform: 'none' }}>
              {t('IDS_TEXT_ORDER_DETAIL')}
            </Typography>
            <IconChevronRight
              className="svgFillAll"
              stroke={theme.palette.blue.blueLight8}
            />
          </Button>
          <OrderDetail
            open={viewOrderDetail}
            handleClose={toggleDrawerOrderDetail}
            orderDetail={orderDetail}
            airLinesInfo={airLinesInfo}
            airPortsInfo={airPortsInfo}
          />
        </Box>
        <div className={classes.divider} />
        <Box className={classes.boxCodeInfo}>
          <Box className={classes.orderCode}>
            <Box
              sx={{
                fontSize: '14px',
                lineHeight: '17px',
                color: theme.palette.black.black3,
              }}
            >
              {t('IDS_TEXT_FLIGHT_ORDER_CODE')}
            </Box>
            <p className={classes.codeLeft}>{bookingCode}</p>
          </Box>
          {isTwoWay
            ? (!!outBoundReservationCode || !!inBoundReservationCode) &&
              hideReservationCode && (
                <Box className={classes.orderCode} flexDirection="column">
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    width="100%"
                    pt={2}
                  >
                    <Box
                      sx={{
                        fontSize: '14px',
                        lineHeight: '17px',
                        color: theme.palette.black.black3,
                      }}
                    >
                      {`${t('IDS_TEXT_FLIGHT_BOOK_CODE_OUTBOUND')} `}
                    </Box>
                    <Box className={classes.codeRight}>
                      {outBoundReservationCode}
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    width="100%"
                    pb={2}
                  >
                    <Box
                      sx={{
                        fontSize: '14px',
                        lineHeight: '17px',
                        color: theme.palette.black.black3,
                      }}
                    >
                      {`${t('IDS_TEXT_FLIGHT_BOOK_CODE_INBOUND')} `}
                    </Box>
                    <Box className={classes.codeRight}>
                      {inBoundReservationCode}
                    </Box>
                  </Box>
                </Box>
              )
            : outBoundReservationCode &&
              hideReservationCode && (
                <Box className={classes.orderCode}>
                  <Box
                    sx={{
                      fontSize: '14px',
                      lineHeight: '17px',
                      color: theme.palette.black.black3,
                    }}
                  >
                    {`${t('IDS_TEXT_FLIGHT_BOOK_CODE')} `}
                  </Box>
                  <Box className={classes.codeRight}>
                    {outBoundReservationCode}
                  </Box>
                </Box>
              )}
        </Box>
        <Box
          sx={{
            display: 'grid',
            gap: '16px',
            gridTemplateColumns: '1fr 1fr',
            margin: '16px 0',
          }}
        >
          <Box
            display="flex"
            textAlign="left"
            width="100%"
            flexDirection="column"
          >
            <Box className={classes.title} sx={{ marginBottom: '8px' }}>
              {t('IDS_MT_TEXT_INFO_CONTACT')}
            </Box>
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: 'fit-content(100%) fit-content(100%)',
                gap: '8px 32px',
              }}
            >
              <Box className={classes.information}>
                {`${t('IDS_MT_TEXT_CONTACT_NAME_SIMPLE')}:`}
              </Box>
              <Box className={classes.information}>
                {contact?.name.toUpperCase()}
              </Box>
              <Box className={classes.information}>
                {`${t('IDS_MT_TEXT_PHONE_NUMBER')}:`}
              </Box>
              <Box className={classes.information}>{contact?.phone}</Box>
              <Box className={classes.information}>
                {`${t('IDS_MT_TEXT_EMAIL')}:`}
              </Box>
              <Box className={classes.information}>{contact?.email}</Box>
            </Box>
          </Box>

          <Box
            display="flex"
            textAlign="left"
            width="100%"
            flexDirection="column"
          >
            <Box
              className={classes.row}
              sx={{ gap: '8px', marginBottom: '8px' }}
            >
              <Box className={clsx(classes.title)}>
                {t('IDS_MT_TEXT_INFO_CUSTOMER_FLIGHT')}
              </Box>
              <Box
                className={classes.information}
                sx={{ color: theme.palette.gray.grayDark7 }}
              >
                {` (${numPassenger || 0} ${t('IDS_MT_TEXT_SINGLE_CUSTOMER')})`}
              </Box>
            </Box>
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: 'fit-content(100%) fit-content(100%)',
                gap: '8px 32px',
              }}
            >
              {passengers.length &&
                passengers.map((item, index) => {
                  return (
                    <>
                      <Box className={classes.information}>
                        {`${
                          item?.ageCategory ===
                          CUSTOMER_TYPE_TEXT.ADULT.toUpperCase()
                            ? t(
                                `${
                                  GENDER_LIST.find(
                                    (el) => el.id === item?.gender
                                  )?.label
                                }`
                              )
                            : t(
                                `${
                                  AGE_CATEGORY.find(
                                    (el) => el.id === item?.ageCategory
                                  )?.label
                                }`
                              )
                        }:`}
                      </Box>
                      <Box className={classes.information}>
                        {item?.lastName.toUpperCase()}{' '}
                        {item?.firstName.toUpperCase()}
                      </Box>
                    </>
                  );
                })}
            </Box>
          </Box>
        </Box>
        <div className={classes.divider} />
        <Box className={classes.ticketItem}>
          <WayFlightItemDetail
            item={orderDetail?.tickets[0]}
            airlineInfo={airLinesInfo?.find(
              (ele) =>
                ele.code ===
                orderDetail?.tickets[0]?.flightDetails[0]?.operatingAirline
            )}
            orderDetail={orderDetail}
            flightDetail={flightDetails[0]}
            airPortsInfo={airPortsInfo}
          />
          {isTwoWay && (
            <>
              <Box mt={2} className={classes.divider} />
              <Box mt={2}>
                <WayFlightItemDetail
                  item={orderDetail?.tickets[1]}
                  airlineInfo={airLinesInfo?.find(
                    (ele) =>
                      ele.code ===
                      orderDetail?.tickets[1]?.flightDetails[0]
                        ?.operatingAirline
                  )}
                  orderDetail={orderDetail}
                  flightDetail={flightDetails[1]}
                  airPortsInfo={airPortsInfo}
                />
              </Box>
            </>
          )}
        </Box>
        <div className={classes.divider} />{' '}
      </Box>
      <Box className={classes.wrapBorder}>
        {Array(26)
          .fill(0)
          .map((el, index) => (
            <Box key={index} className={classes.circle} />
          ))}
      </Box>
      {!!linkWebsite && (
        <Box
          sx={{
            margin: '0 auto',
            marginTop: '12px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '200px',
          }}
        >
          <Link
            href={linkWebsite}
            style={{ margin: '0 auto', textDecoration: 'none' }}
          >
            <ButtonBase
              sx={{
                background: theme.palette.secondary.main,
                borderRadius: '8px',
                fontWeight: '600',
                fontSize: '14px',
                lineHeight: '17px',
                textAlign: 'center',
                color: '#FFFFFF',
                padding: '12px 16px',
                marginBottom: '24px',
              }}
            >
              {t('IDS_MT_TEXT_NOT_FOUND_403_DES_3')}
            </ButtonBase>
          </Link>
        </Box>
      )}
    </Box>
  );
};

export default Booking;
