import { CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import moment from 'moment';
import { formatMoneyCurrency } from 'utils/helpersDataHotel';
import FlightBankTransfer from 'modules/desktop/flight/payment/orderDetail/FlightBankTransfer';
import {
  GREEN,
  NEUTRAL_100,
  ORANGE_300,
  RED,
  WHITE,
  RED_500,
} from 'assets/theme/colors';
import useTransactionBooking from 'modules/hooks/useTransactionBooking';
import {
  IconBookingFlightFail,
  IconBookingFlightSuccess,
  IconPaymentPendingHotel,
} from 'public/icons';
import { getMultilingualTime } from 'utils/helpers';
import { useTranslation } from 'react-i18next';
import { LIST_SCREEN_STATUS, IS_DINOGO } from 'utils/constants';
import { C_DATE_TIME_FORMAT } from 'utils/moment';

const useStyles = makeStyles((theme) => ({
  boxStatus: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  wrapContainer: {
    position: 'relative',
    padding: '32px 0 0',
  },
  divider: {
    borderBottom: `1px solid ${theme.palette.gray.grayLight22}`,
    width: '100%',
    padding: '10px 0px',
  },
  container: {
    margin: 'auto',
    maxWidth: 630,
    height: 'auto',
    padding: 24,
    background: WHITE,
    borderRadius: 8,
  },
  wrapBorder: {
    display: 'flex',
    justifyContent: 'space-around',
    margin: 'auto',
    maxWidth: 630,
  },
  circle: {
    width: 16,
    height: 16,
    transform: 'rotate(-45deg)',
    borderRadius: '50%',
    borderLeftColor: 'transparent',
    borderBottomColor: 'transparent',
    background: NEUTRAL_100,
    marginTop: -8,
  },
  mainStatus: {
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '19px',
    textAlign: 'center',
    color: theme.palette.black.black3,
    marginTop: '12px',
    marginBottom: '8px',
  },
  desc: {
    fontSize: '14px',
    lineHeight: '22px',
    textAlign: 'center',
    color: theme.palette.black.black3,
  },
  order: {
    fontSize: '14px',
    lineHeight: '22px',
    color: theme.palette.GREEN,
    marginTop: 11,
    borderRadius: 4,
    padding: '0px 6px',
    border: '1px solid rgba(72, 187, 120, 1)',
  },
  paymentMethod: {
    fontWeight: '600',
    padding: '16px 0px 4px 0px',
  },
  transfer: {
    paddingBottom: '16px',
  },
  totalPrice: {
    marginTop: 10,
    display: 'flex',
    justifyContent: 'space-between',
    fontWeight: '600',
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
}));

const Booking = () => {
  const classes = useStyles();
  const { screenStatus, hotelBooking: orderDetail } = useTransactionBooking();
  const { t } = useTranslation();
  const expiredTime = moment(
    orderDetail?.transferInfo?.expiredTime,
    C_DATE_TIME_FORMAT
  );
  const locale = localStorage.getItem('i18nextLng') || 'vi';
  const getScreenStatus = () => {
    switch (screenStatus) {
      case LIST_SCREEN_STATUS.SUCCESS:
        return (
          <>
            <IconBookingFlightSuccess />
            <Box
              sx={{ marginTop: '12px', marginBottom: '8px' }}
              className={classes.mainStatus}
            >
              {t('IDS_MT_TRANSACTION_SUCCESS')}
            </Box>
            <Box className={classes.desc}>
              {IS_DINOGO
                ? t('IDS_MT_TRANSACTION_SUCCESS_CONTENT_DINOGO')
                : t('IDS_MT_TRANSACTION_SUCCESS_CONTENT')}
            </Box>
          </>
        );
      case LIST_SCREEN_STATUS.FAILED:
        return (
          <>
            <IconBookingFlightFail />
            <Box
              sx={{ marginTop: '12px', marginBottom: '8px' }}
              className={classes.mainStatus}
            >
              {t('IDS_MT_TRANSACTION_FAIL')}
            </Box>
            <Box className={classes.desc}>
              {t('IDS_MT_TRANSACTION_FAIL_CONTENT')}
            </Box>
          </>
        );
      case LIST_SCREEN_STATUS.UNDEFINED:
        return (
          <>
            <IconPaymentPendingHotel />
            <Box
              sx={{ marginTop: '12px', marginBottom: '8px' }}
              className={classes.mainStatus}
            >
              {t('IDS_MT_TEXT_PENDING_PAYMENT')}
            </Box>
            <Box className={classes.desc}>
              {t('IDS_TEXT_FLIGHT_BOOKING_BANK_TRANSFER_2')}&nbsp;
              <span style={{ color: RED_500, fontWeight: '600' }}>
                {getMultilingualTime({ locale, expiredTime })}
              </span>
            </Box>
            <Box className={classes.order}>
              <span style={{ color: GREEN }}>
                {t('IDS_TEXT_FLIGHT_ORDER_CODE')}:&nbsp;
                <span style={{ fontWeight: '600' }}>
                  {orderDetail?.transactionInfo?.bookingCode}
                </span>
              </span>
            </Box>
          </>
        );
      default:
        return <CircularProgress />;
    }
  };

  return (
    <Box className={classes.wrapContainer}>
      <Box
        className={classes.container}
        style={{
          borderTop: `4px solid ${
            screenStatus === LIST_SCREEN_STATUS.SUCCESS
              ? GREEN
              : screenStatus === LIST_SCREEN_STATUS.FAILED
              ? RED
              : ORANGE_300
          }`,
        }}
      >
        <div className={classes.body}>
          <Box className={classes.boxStatus}>{getScreenStatus()}</Box>
        </div>
        {moment().isBefore(expiredTime) && (
          <>
            <FlightBankTransfer
              bankTransferInfo={orderDetail?.transferInfo}
              bankTransferOptions={orderDetail?.transferOptions}
            />
            {/* <Box className={classes.paymentMethod}>
              {t('IDS_MT_TEXT_PAYMENT_MENTHODS')}
            </Box>
            <Box className={classes.transfer}>
              {t('IDS_MT_TEXT_PAYMENT_METHOD_1')}
              <span style={{ color:  ORANGE_300}}>{orderDetail?.transactionInfo?.status}</span>
            </Box> */}
            <Box className={classes.divider} />
            <Box className={classes.totalPrice}>
              <div>{t('IDS_MT_TEXT_TOTAL_PRICE')}</div>
              <div>{`${formatMoneyCurrency(
                orderDetail?.transferInfo?.totalAmount
              )}`}</div>
            </Box>
          </>
        )}
      </Box>
      <Box className={classes.wrapBorder}>
        {Array(26)
          .fill(0)
          .map((el, index) => (
            <Box key={index} className={classes.circle} />
          ))}
      </Box>
    </Box>
  );
};

export default Booking;
