import moment from 'moment';
import cookie from 'js-cookie';
import { isEmpty } from 'utils/helpers';

export const CURRENCY_PAY = 'currency_pay';

export const menuCurrency = [
  {
    name: 'Australian Dollar',
    code: 'AUD',
    symbol: 'AU$',
  },
  {
    name: 'Singapore Dollar',
    code: 'SGD',
    symbol: 'S$',
  },
  {
    name: 'Euro',
    code: 'EUR',
    symbol: '€',
  },
  {
    name: 'Vietnam Dong',
    code: 'VND',
    symbol: 'đ',
  },
  {
    name: 'US Dollar',
    code: 'USD',
    symbol: '$',
  },
];

export const getLocation = (address = {}) => {
  if (!isEmpty(address.interestingPlace)) {
    return `${address.interestingPlace.name}${`, ${
      !isEmpty(address.districtName)
        ? address.districtName
        : address.provinceName
    }`}`;
  }
  if (!isEmpty(address?.streetName)) {
    return `${address.streetName}${`, ${
      !isEmpty(address.districtName)
        ? address.districtName
        : address.provinceName
    }`}`;
  }
  return `${
    !isEmpty(address.districtName)
      ? `${address.districtName}`
      : address.provinceName
  }`;
};

export const getMessageHotelReviews = (score, trans) => {
  if (score >= 4.5) return trans.IDS_MT_REVIEW_CORE_45;
  if (score >= 4) return trans.IDS_MT_REVIEW_CORE_40;
  if (score >= 3.5) return trans.IDS_MT_REVIEW_CORE_35;
  if (score >= 2.5) return trans.IDS_MT_REVIEW_CORE_25;
  return trans.IDS_MT_REVIEW_CORE_00;
};

export const filterTagsNormal = (tags = []) => {
  let firstTag = tags.filter(
    (tag) =>
      tag?.name?.toUpperCase() !== 'Best of the Best'?.trim()?.toUpperCase() &&
      tag?.name?.toUpperCase() !== "Travellers' Choice"?.trim()?.toUpperCase()
  );
  return firstTag;
};

export const filterTagsBest = (tags = []) => {
  return tags.filter(
    (tag) =>
      tag?.name?.toUpperCase() === 'Best of the Best'?.trim()?.toUpperCase() ||
      tag?.name?.toUpperCase() === "Travellers' Choice"?.trim()?.toUpperCase()
  );
};

export const formatMoney = (
  amount = 0,
  decimalLength = 0,
  decimal = ',',
  thousands = '.'
) => {
  try {
    let decimalCount = Math.abs(decimalLength);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? '-' : '';

    const i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
    ).toString();
    const j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : '')
    );
  } catch (e) {
    throw e;
  }
};

export const CURRENCY_CODE = 'currency_code';
export const formatMoneyCurrency = (
  amount = 0,
  currencyCode = 'VND',
  isCeil = false,
  isDefaultParams = false
) => {
  try {
    currencyCode = isDefaultParams
      ? currencyCode
      : cookie.get(CURRENCY_CODE) || 'VND';
    const currencyItem = menuCurrency.find((el) => el.code === currencyCode);

    let currencyPay = cookie.get(CURRENCY_PAY);
    if (!isEmpty(currencyPay)) {
      amount = amount * (1 / currencyPay);
    }
    if (isCeil || currencyCode === 'VND') {
      amount = Math.ceil(amount);
    } else {
      amount = Math.ceil(amount * 100) / 100;
    }

    const decimal = currencyCode === 'VND' ? ',' : '.';
    const thousands = currencyCode === 'VND' ? '.' : ',';
    const decimalLength = 2;

    let decimalCount = Math.abs(decimalLength);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? '-' : '';

    const i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
    ).toString();
    const j = i.length > 3 ? i.length % 3 : 0;

    const decimalTemp = Math.abs(amount - i)
      .toFixed(decimalCount)
      .slice(2);
    return (
      (currencyCode !== 'VND' ? `${currencyItem.symbol}` : '') +
      negativeSign +
      (j ? i.substr(0, j) + thousands : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
      (decimalCount && decimalTemp !== '00' ? decimal + decimalTemp : '') +
      (currencyCode === 'VND' ? ` ${currencyItem.symbol}` : '')
    );
  } catch (e) {
    return '';
    // throw e;
  }
};

export const getMemberDeal = (promotionInfo = {}, price = 0) => {
  let result = '10%';
  if (!isEmpty(promotionInfo)) {
    result = `${promotionInfo.discountPercentage}%`;
  }
  return result;
};

//// adapter for /v3/favorites/hotels view Hotel Item Listing
export const adapterHotelFavorite = (listItem = []) => {
  let result = [];
  listItem.forEach((el, index) => {
    if (!isEmpty(el)) {
      result.push({
        ...el,
        image: !isEmpty(el?.image) ? el.image : el?.thumbnail?.image,
        ratingLevel: !isEmpty(el?.ratingLevel)
          ? el?.ratingLevel
          : el?.rating?.hotel?.rating,
        numberOfReviews: !isEmpty(el?.numberOfReviews)
          ? el?.numberOfReviews
          : el?.rating?.hotel?.count,
      });
    }
  });
  return result;
};

export const isShowTopSale = (lastBookedTime = null, specialOffer = {}) => {
  let result = 'NONE'; // "NONE", "HOT_SALE", "TOP_PRICE"
  if (!isEmpty(lastBookedTime)) {
    if (moment().diff(moment(lastBookedTime)) <= 4 * 60 * 60 * 1000) {
      result = 'HOT_SALE';
      return result;
    }
  }
  if (!isEmpty(specialOffer)) {
    result = 'TOP_PRICE';
    return result;
  }

  return result;
};

export const updateImages = (hotelDetail = {}) => {
  let result = hotelDetail.images || [];
  if (!isEmpty(hotelDetail.videoUrl)) {
    result = [
      {
        type: 'video',
        videoUrl: hotelDetail.videoUrl,
      },
      ...result,
    ];
  }
  return result;
};

export const getPromotionFlashSale = (rooms = []) => {
  let result = {};
  rooms?.forEach((room) => {
    room?.rates?.forEach((rate) => {
      const promotionTemp = rate?.promotions?.find(
        (el) => el.changeBookingPrice
      );
      if (!isEmpty(promotionTemp)) {
        result = promotionTemp;
        return false;
      }
    });
    if (!isEmpty(result)) {
      return false;
    }
  });
  return result;
};

export const updateTimeCount = (listTime = [], validFrom, validTo) => {
  let result = { validFrom: validFrom, validTo: validTo };

  const findTime = listTime.find((el) => el.to > moment().valueOf());
  if (!isEmpty(findTime)) {
    result = {
      ...result,
      validFrom: findTime.from,
      validTo: findTime.to,
    };
  }
  return result;
};

export const getNameFreeBreakfast = (buyingSignals = []) => {
  let result = 'Bữa sáng miễn phí';
  let count = 0;
  if (!isEmpty(buyingSignals)) {
    buyingSignals.forEach((el) => {
      if (el.name?.toLowerCase()?.includes('miễn phí bữa trưa')) {
        result = 'Miễn phí bữa sáng và bữa trưa';
        count = count + 1;
      } else if (el.name?.toLowerCase()?.includes('miễn phí bữa tối')) {
        result = 'Miễn phí bữa sáng và bữa tối';
        count = count + 1;
      }
    });
    if (count === 2) {
      result = 'Miễn phí 3 bữa ăn: Sáng, Trưa và Tối';
    }
  }
  return result;
};

export const filterBuyingSignals = (
  freeBreakfast = true,
  buyingSignals = []
) => {
  let result = buyingSignals;
  if (freeBreakfast) {
    result = result.filter(
      (el) =>
        (el.groupCode === 'PROMOTIONS' || el.groupCode === 'AMENITIES') &&
        !el.name?.toLowerCase()?.includes('miễn phí bữa trưa') &&
        !el.name?.toLowerCase()?.includes('miễn phí bữa tối')
    );
  }
  return result;
};

export const getDiscountPromotion = (basePromotion = {}) => {
  if (basePromotion.valueType === 'percent') {
    return basePromotion.percent;
  } else {
    return (
      (basePromotion.price / (basePromotion.price + basePromotion.value)) *
      100
    )?.toFixed(0);
  }
};

export const isShowExportInvoice = (bookingDetail = {}) => {
  return (
    bookingDetail.paymentStatus === 'success' &&
    bookingDetail.bookingStatus === 'success' &&
    bookingDetail.invoiceInfo.invoiceType === 'not_marketplace' &&
    isEmpty(bookingDetail.invoiceInfo.taxNumber)
  );
};

export const roundUp = (num, precision) => {
  precision = Math.pow(10, precision);
  return Math.ceil(num * precision) / precision;
};

export const replaceBetween = (
  strFull = '',
  start = 0,
  end = 0,
  subReplace = ''
) => {
  return strFull.substring(0, start) + subReplace + strFull.substring(end);
};
export const getPriceMemberDeal = (basePrice) => {
  const price = basePrice;
  let result = formatMoney(price);
  if (price >= 1000000) {
    //1.000.000 -> 1.xxx.000
    let indexOffset = result.length - 7; // 2
    result = replaceBetween(result, indexOffset, indexOffset + 3, 'xxx');
  } else if (price >= 100000) {
    // 100.000 -> 1xx.000
    let indexOffset = result.length - 6; // 1
    result = replaceBetween(result, indexOffset, indexOffset + 2, 'xx');
  } else if (price >= 10000) {
    // 10.000 -> 1x.000
    let indexOffset = result.length - 5; // 1
    result = replaceBetween(result, indexOffset, indexOffset + 1, 'x');
  } else {
    // 9.000 -> x.000
    let indexOffset = result.length - 5; // 0
    result = replaceBetween(result, indexOffset, indexOffset + 1, 'x');
  }
  return `${result}đ`;
};

export const getAbsoluteMonths = (momentDate) => {
  const months = Number(momentDate.format('MM'));
  const years = Number(momentDate.format('YYYY'));
  return months + years * 12;
};

export const getMonthDifference = (startDate, endDate) => {
  const startMonths = getAbsoluteMonths(startDate);
  const endMonths = !isEmpty(endDate)
    ? getAbsoluteMonths(endDate)
    : startMonths;
  return endMonths - startMonths;
};
export const getInfoPolicy = (item = {}) => {
  const { refundAmount, price } = item;
  let resultText = '';
  if (refundAmount === 0) {
    resultText = 'Bạn sẽ không được hoàn tiền nếu hủy phòng ';
  } else if (refundAmount === price) {
    resultText = 'Chúng tôi miễn phí hoàn hủy nếu hủy phòng';
  } else {
    resultText = `Bạn sẽ được hoàn ${refundAmount.formatMoney()} VNĐ nếu hủy phòng`;
  }
  return resultText;
};

const getTime = (time) => {
  if (time) {
    const temp = time.split(':');
    temp.pop();
    return temp.join(':');
  }
};

export const getContentPolicy = (item = {}) => {
  const { cancellationType, percent, dateFrom, dateTo } = item;
  const dateToText = !isEmpty(dateTo) ? ` ${dateTo.split(' ')[0]}` : '';
  const dateFromText = !isEmpty(dateFrom) ? ` ${dateFrom.split(' ')[0]}` : '';
  let resultText = '';
  switch (cancellationType) {
    case 'amount': {
      if (isEmpty(dateFrom)) {
        resultText = `${getInfoPolicy(item)} trước ${getTime(
          dateTo?.split(' ')[1]
        )} ngày ${dateToText}`;
      } else if (isEmpty(dateTo)) {
        resultText = `${getInfoPolicy(item)} từ ${getTime(
          dateFrom?.split(' ')[1]
        )} ngày ${dateFromText} `;
      } else {
        resultText = `${getInfoPolicy(item)} từ ${getTime(
          dateFrom?.split(' ')[1]
        )} ngày ${dateFromText} đến trước ${getTime(
          dateTo?.split(' ')[1]
        )} ngày ${dateToText}`;
      }
      break;
    }
    case 'percent':
      if (dateFrom === null) {
        resultText = `Bạn sẽ không được hoàn tiền nếu hủy phòng từ ${
          dateTo.split(' ')[1]
        } ngày ${dateToText}`;
      } else if (dateTo === null) {
        resultText = `Bạn sẽ được hoàn ${percent} % nếu hủy phòng trước  ${
          dateFrom.split(' ')[1]
        } ngày ${dateFromText} `;
      } else {
        resultText = `Bạn sẽ được hoàn ${percent} %  nếu hủy phòng từ  ${
          dateTo.split(' ')[1]
        } ngày ${dateToText} ${
          dateFrom.split(' ')[1]
        } đến trước  ngày ${dateFromText}`;
      }
      break;
    default:
      break;
  }
  return resultText;
};

export const getNamePolicesRoom = (policies = []) => {
  let textPolicies = '';
  policies.forEach((el, idx) => {
    textPolicies += `${
      idx !== 0 ? `${idx === policies.length - 1 ? ' & ' : ', '}` : ''
    }${el.shortDescription}`;
  });
  return textPolicies;
};

export const fakePriceVinperl = (listItem = [], type = 'rateType') => {
  let result = [];
  listItem.forEach((el) => {
    if (
      el.agencyId === 24 &&
      isEmpty(el.basePromotionInfo) &&
      !el.requestPrice
    ) {
      const percent =
        el[type] === 'hard_block'
          ? 0.4
          : el[type] === 'normal_promotion'
          ? 0.29
          : 0.2;
      const discountFixed = Math.ceil(el.basePrice * percent) + 224000;
      const priceBeforePromotion = el.basePrice + discountFixed;
      const basePromotionInfo = {
        discountFixed,
        priceBeforePromotion,
        discountPercentage: roundUp(
          (discountFixed / priceBeforePromotion) * 100,
          0
        ),
      };
      result.push({
        ...el,
        basePromotionInfo,
      });
    } else {
      result.push(el);
    }
  });
  return result;
};

export const updateDataPriceInfoHotel = (rateItem = {}) => {
  let result = {};
  if (!isEmpty(rateItem?.basePromotionInfo)) {
    result = {
      price: rateItem.basePrice,
      beforPrice: rateItem.basePromotionInfo.priceBeforePromotion,
      discount: rateItem.basePromotionInfo.discountPercentage,
    };
  }
  const promotion = !isEmpty(rateItem?.promotions)
    ? rateItem.promotions[0]
    : {};
  if (!isEmpty(promotion)) {
    const basePromotion = promotion.basePromotion || {};
    if (!isEmpty(basePromotion)) {
      result = {
        price: basePromotion.price,
        beforPrice: !isEmpty(rateItem?.basePromotionInfo?.priceBeforePromotion)
          ? rateItem?.basePromotionInfo?.priceBeforePromotion
          : rateItem.basePrice,
        discount: !isEmpty(rateItem?.basePromotionInfo?.priceBeforePromotion)
          ? Math.round(
              ((rateItem?.basePromotionInfo?.priceBeforePromotion -
                basePromotion.price) /
                rateItem?.basePromotionInfo?.priceBeforePromotion) *
                100
            )
          : basePromotion.percent,
      };
    }
  }
  return result;
};

export const quickFilterRoomRate = (rooms = [], filters = {}) => {
  let result = [];
  rooms.forEach((el) => {
    let rateTemps = [];
    const rates = el.rates || [];
    rates.forEach((item) => {
      let flat = 0;
      const numFilterItem = Object.values(filters).filter((el) => el).length;
      // check policy
      if (filters.policy && (item.freeCancellation || item.refundable)) {
        flat += 1;
      }
      // check breakFast
      if (filters.breakFast && item.freeBreakfast) {
        flat += 1;
      }
      // check confirmNow
      if (filters.confirmNow && !item.requestPrice) {
        flat += 1;
      }
      // check bed
      if (filters.bed) {
        const bedGroupTemp = item.bedGroups.find((bedGroup) => {
          const bedTypeTemp = bedGroup?.bedTypes?.find(
            (bedType) =>
              bedType.size?.toLowerCase()?.includes('twin') &&
              bedType.numberOfBed >= 2
          );
          if (!isEmpty(bedTypeTemp)) {
            return true;
          } else {
            return false;
          }
        });
        if (!isEmpty(bedGroupTemp)) {
          flat += 1;
        }
      }
      // check invoice
      if (
        filters.invoice &&
        item?.invoiceInfo?.invoiceType === 'not_marketplace'
      ) {
        flat += 1;
      }
      if (numFilterItem === flat) {
        rateTemps.push(item);
      }
    });
    if (!isEmpty(rateTemps)) {
      result.push({
        ...el,
        rates: rateTemps,
      });
    }
  });
  return result;
};

export const isValidHttpUrl = (string) => {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
};

export const updateImagesHomestay = (hotelDetail = {}, images = []) => {
  let result = [];
  images.forEach((el) => {
    result.push({
      image: {
        origin: el,
      },
      src: el,
    });
  });
  if (!isEmpty(hotelDetail.videoUrl)) {
    result = [
      {
        type: 'video',
        videoUrl: hotelDetail.videoUrl,
      },
      ...result,
    ];
  }
  return result;
};

export const createDownloadFile = async (imageSrc, nameOfDownload) => {
  const response = await fetch(imageSrc);
  const blobImage = await response.blob();
  const href = URL.createObjectURL(blobImage);
  const anchorElement = document.createElement('a');
  anchorElement.href = href;
  anchorElement.download = nameOfDownload;
  document.body.appendChild(anchorElement);
  anchorElement.click();
  document.body.removeChild(anchorElement);
  window.URL.revokeObjectURL(href);
};
