import { Button, Menu, MenuItem } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import React, { useEffect } from 'react';
import i18n from 'utils/i18n';

const LanguageSelector = styled(Button)({
  color: '#1A202C',
  marginTop: 8,
  marginRight: 8,
  textTransform: 'none',
});

const LIST_LANG = [
  {
    name: 'Tiếng Việt',
    value: 'vi',
    linkPhoto:
      'https://storage.googleapis.com/tripi-assets/mytour/icons/icon_lang_vi.png',
  },
  {
    name: 'English',
    value: 'en',
    linkPhoto:
      'https://storage.googleapis.com/tripi-assets/mytour/icons/icon_lang_en.png',
  },
];

export default function LanguageSwitcher({ languageCode }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [language, setLanguage] = React.useState(LIST_LANG[0]);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLanguageSelect = (event, index) => {
    setLanguage(LIST_LANG[index]);
    handleMenuClose();
  };

  const lang = !languageCode
    ? 'vi'
    : languageCode !== 'vi' && languageCode !== 'en'
    ? 'en'
    : languageCode;

  useEffect(() => {
    i18n.changeLanguage(lang);
    setLanguage(LIST_LANG.find((ele) => ele?.value === lang));
  }, [lang]);

  useEffect(() => {
    i18n.changeLanguage(language?.value);
  }, [language]);

  return (
    <div>
      <LanguageSelector
        sx={{ border: '1px solid #1A202C' }}
        variant="outlined"
        onClick={handleMenuOpen}
      >
        <Box display="flex">
          <img
            src={language?.linkPhoto}
            style={{
              width: 20,
              height: 20,
              marginRight: 4,
            }}
            alt=""
          />
          {language?.name}
        </Box>
      </LanguageSelector>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {LIST_LANG.map((ele, index) => (
          <MenuItem
            key={index}
            onClick={(event) => handleLanguageSelect(event, index)}
            selected={LIST_LANG[index]?.value === language?.value}
          >
            <img
              src={ele?.linkPhoto}
              style={{
                width: 20,
                height: 20,
                marginRight: 4,
              }}
              alt=""
            />
            {ele?.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
