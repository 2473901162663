import { Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: '#212121',
  },
  tooltip: {
    backgroundColor: '#212121',
  },
}));
const BootstrapTooltip = (props) => {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
};

export default BootstrapTooltip;
