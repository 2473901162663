import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { WHITE } from 'assets/theme/colors';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    paddingBottom: 4,
    fontSize: '14px',
    lineHeight: '17px',
    color: theme.palette.black.black3,
    marginBottom: 4,
  },
  title: {
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '21px',
    color: theme.palette.black.black3,
  },
  container: {
    width: '100%',
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    backgroundColor: WHITE,
  },
  infoRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '24px',
  },
}));

const OrderExportInvoice = ({ invoice = {} }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const contents = [
    {
      title: t('IDS_TEXT_PLACEHOLDER_SEARCH_TAX_CODE'),
      value: invoice?.taxNumber,
    },
    {
      title: t('IDS_TEXT_PLACEHOLDER_INVOICE_NAME'),
      value: invoice?.companyName,
    },
    {
      title: t('IDS_TEXT_PLACEHOLDER_INVOICE_ADDRESS'),
      value: invoice?.companyAddress,
    },
    {
      title: t('IDS_MT_TEXT_CUSTOMER_NAME'),
      value: invoice?.recipientName,
    },
    {
      title: t('IDS_TEXT_PLACEHOLDER_INVOICE_RECEIVER'),
      value: invoice?.recipientAddress,
    },
    {
      title: t('IDS_MT_TEXT_EMAIL'),
      value: invoice?.recipientEmail,
    },
  ].filter((content) => content.value);

  return (
    <Box className={classes.container}>
      <Box className={classes.title}>{t('IDS_MT_TEXT_INFO_INVOICE')}</Box>
      {contents.map((content) => (
        <Box className={classes.infoRow} key={content.title}>
          <Box className={classes.content} sx={{ flexShrink: 0 }}>
            {content.title}
          </Box>
          <Box className={classes.content}>{content.value}</Box>
        </Box>
      ))}
    </Box>
  );
};

export default OrderExportInvoice;
