import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { IconTrain, IconTrainPink, IconLocationPink } from 'public/icons';
import moment from 'moment';
import { BLACK_90 } from 'assets/theme/colors';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  trainLogo: {
    width: 32,
    height: 32,
  },
  trainName: {
    color: BLACK_90,
    whiteSpace: 'nowrap',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '17px',
  },
  title: {
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '20px',
    color: theme.palette.black.black3,
  },
  container: {
    display: 'grid',
    gridTemplateColumns: '5fr 1fr 11fr',
    gap: '0 8px',
    padding: 16,
  },
  bold: {
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '19px',
    color: theme.palette.black.black3,
  },
  grayText: {
    fontSize: '14px',
    lineHeight: '17px',
    textAlign: 'right',
    color: theme.palette.gray.grayDark7,
  },
  center: {
    placeSelf: 'center',
    display: 'grid',
    placeItems: 'center',
    height: '100%',
  },
  textAlignRight: {
    textAlign: 'right',
  },
  verticalDivider: {
    height: '100%',
    borderRight: `1px solid ${theme.palette.secondary.main}`,
  },
}));

const VerticalDivider = () => {
  const classes = useStyles();
  return <Box className={classes.verticalDivider} />;
};

const WayTrainItem = ({ trainTicket, containerStyle }) => {
  const classes = useStyles();
  return (
    <Box className={classes.container} sx={containerStyle}>
      {/*1*/}
      <Box className={clsx(classes.bold, classes.textAlignRight)}>
        {moment(trainTicket?.departureDate).format('HH:mm')}
      </Box>
      <Box className={classes.center}>
        <IconTrainPink />
      </Box>
      <Box className={classes.title}>{trainTicket?.departureStationName}</Box>

      {/*2*/}
      <Box
        className={clsx(classes.grayText, classes.textAlignRight)}
        sx={{ padding: '4px 0' }}
      >
        {moment(trainTicket?.departureDate).format('DD [thg] MM')}
      </Box>
      <Box className={classes.center}>
        <VerticalDivider />
      </Box>
      <Box
        className={classes.grayText}
        sx={{ padding: '4px 0', textAlign: 'left' }}
      >
        {trainTicket?.departureStationCode}
      </Box>

      {/*3*/}
      <Box
        className={clsx(classes.grayText, classes.textAlignRight)}
        sx={{ padding: '8px 0', alignSelf: 'center' }}
      >
        {trainTicket?.duration}
      </Box>
      <Box className={classes.center}>
        <VerticalDivider />
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '12px',
          padding: '8px 0',
          alignSelf: 'center',
        }}
      >
        <IconTrain className={classes.trainLogo} />
        <Box className={classes.trainName}>{trainTicket?.tripCode}</Box>
      </Box>

      {/*4*/}
      <Box className={clsx(classes.bold, classes.textAlignRight)}>
        {moment(trainTicket?.arriveDate).format('HH:mm')}
      </Box>
      <Box className={classes.center}>
        <IconLocationPink />
      </Box>
      <Box className={classes.title}>{trainTicket?.arrivedStationName}</Box>
      {/*5*/}
      <Box
        className={clsx(classes.grayText, classes.textAlignRight)}
        sx={{ padding: '4px 0' }}
      >
        {moment(trainTicket?.arriveDate).format('DD [thg] MM')}
      </Box>
      <Box className={classes.center}></Box>
      <Box
        className={classes.grayText}
        sx={{ padding: '4px 0', textAlign: 'left' }}
      >
        {trainTicket?.arrivedStationCode}
      </Box>
    </Box>
  );
};
export default WayTrainItem;
