/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */

import { Box, LinearProgress } from '@mui/material';
import { useTheme } from '@mui/styles';
import { checkingPaymentStatus } from 'api/flight';
import { getPhoneCardBooking } from 'api/phoneCard';
import i18n from 'utils/i18n';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import CardModule from './module/CardModule';
import MobileTopupModule from './module/MobileTopupModule';
import BookingFail from './status/BookingFail';
import BookingSucess from './status/BookingSuccess';
import BookingUndefined from './status/BookingUndefined';

const { t } = i18n;

const getModuleInfo = (module, phoneCard) => {
  switch (module) {
    case 'card':
      return {
        successText: t('IDS_MT_PHONE_CARD_SUCCESS'),
        component: <CardModule phoneCard={phoneCard} />,
      };
    case 'mobile_topup':
      return {
        successText: t('IDS_MT_TOP_UP_SUCCESS'),
        component: <MobileTopupModule phoneCard={phoneCard} />,
      };
    default:
      return null;
  }
};

const Booking = () => {
  const theme = useTheme();
  const { bookingId } = useParams();
  const router = useLocation();

  const [phoneCard, setPhoneCard] = useState();

  useEffect(() => {
    const fetchTrainTicket = async () => {
      const { data: paymentStatus } = await checkingPaymentStatus({
        gatewayParams: router?.search?.substring(1) || '',
        transactionId: bookingId,
      });
      const { data } = await getPhoneCardBooking({
        id: paymentStatus?.data?.transactionInfo?.hashBookingId,
      });
      setPhoneCard(data?.data);
    };
    bookingId && fetchTrainTicket();
  }, []);

  if (!phoneCard) return <LinearProgress />;

  const moduleInfo = getModuleInfo(phoneCard?.module, phoneCard);

  if (!moduleInfo) return null;

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.secondary.main,
        minHeight: '100vh',
        padding: '16px',
      }}
    >
      {phoneCard?.paymentStatus === 'success' ? (
        <>
          <BookingSucess successText={moduleInfo?.successText} />
          {moduleInfo?.component}
        </>
      ) : phoneCard?.paymentStatus === 'awaiting-payment' ? (
        <BookingUndefined />
      ) : (
        <BookingFail />
      )}
    </Box>
  );
};

export default Booking;
