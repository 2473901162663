/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */

import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { WHITE } from 'assets/theme/colors';
import { useTranslation } from 'react-i18next';
import { formatMoneyCurrency } from 'utils/helpersDataHotel';
import PhoneCardInfo from '../components/PhoneCardInfo';

const useStyles = makeStyles((theme) => ({
  divider: {
    borderBottom: `1px solid ${theme.palette.gray.grayLight22}`,
    width: '100%',
  },
  container: {
    margin: 'auto',
    maxWidth: 630,
    boxSizing: 'border-box',
    background: WHITE,
    borderRadius: '0px 0px 20px 20px',
    padding: 16,
    paddingBottom: 12,
  },
  phoneOperatorContainer: {
    display: 'flex',
    gap: 12,
    alignContent: 'center',
    paddingBottom: 16,
  },
  phoneOperatorImg: {
    width: 48,
    height: 48,
    borderRadius: 8,
    border: `1px solid ${theme.palette.gray.grayLight23}`,
    objectFit: 'contain',
  },
  operatorInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
  },
  operator: {
    fontSize: 14,
    lineHeight: '16px',
    color: theme.palette.black.black3,
  },
  cardsValue: {
    display: 'flex',
    marginTop: 6,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  cardValue: {
    fontSize: 18,
    lineHeight: '20px',
    color: theme.palette.black.black3,
    fontWeight: 600,
    display: 'flex',
  },
  moneyMark: {
    fontSize: 12,
    lineHeight: '14px',
    color: theme.palette.black.black3,
    fontWeight: 600,
    marginLeft: 2,
    marginBottom: 2,
  },
}));

const CardModule = (props) => {
  const { phoneCard } = props;

  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box className={classes.phoneOperatorContainer}>
        <img
          className={classes.phoneOperatorImg}
          src={phoneCard?.provider?.logoUrl}
          alt=""
        />
        <Box className={classes.operatorInfo}>
          <Box className={classes.operator}>{`${t('IDS_MT_NETWORK_OPERATOR')} ${
            phoneCard?.provider?.name
          }`}</Box>
          <Box className={classes.cardsValue}>
            <Box className={classes.cardValue}>
              {`${formatMoneyCurrency(phoneCard?.value)}`}
            </Box>
            {!!phoneCard?.cardsInfo?.length && (
              <Box
                className={classes.quanlity}
              >{`x${phoneCard?.cardsInfo?.length}`}</Box>
            )}
          </Box>
        </Box>
      </Box>
      <Box className={classes.divider} />
      {phoneCard?.cardsInfo?.map((card) => (
        <PhoneCardInfo
          phoneCard={card}
          networkOperator={phoneCard?.provider}
          value={phoneCard?.value}
          key={card?.serial}
        />
      ))}
    </Box>
  );
};

export default CardModule;
