import { useTheme } from '@mui/material/styles';
import CryptoJS from 'crypto-js';
import moment from 'moment';
import 'moment-timezone';
import 'moment/locale/vi';
import i18n from './i18n';
import DeviceDetector from 'ua-parser-js';
import * as constants from 'utils/constants';
import {
  flightPaymentMethodCode,
  flightPaymentStatus,
  HASH_KEY_DEV,
  HASH_KEY_PROD,
} from 'utils/constants';

const has = Object.prototype.hasOwnProperty;

export const isDiff = (A, B) => JSON.stringify(A) !== JSON.stringify(B);

export const isEmpty = (prop) => {
  return (
    prop === null ||
    prop === undefined ||
    (has.call(prop, 'length') && prop.length === 0) ||
    (prop.constructor === Object && Object.keys(prop).length === 0)
  );
};

export const areEqualItemRoom = (prevProps, nextProps) => {
  return (
    JSON.stringify(prevProps.item) === JSON.stringify(nextProps.item) &&
    JSON.stringify(prevProps.hotelDetail) ===
      JSON.stringify(nextProps.hotelDetail)
  );
};
export const getMultilingualTime = ({ locale = 'vi', expiredTime }) =>
  locale === 'vi' ? (
    <>
      <span>
        {expiredTime.format('HH:mm')},&nbsp;
        {expiredTime
          .locale('vi_VN')
          .format('ddd')
          .replace('T', 'Thứ ')
          .replace('CN', 'Chủ Nhật')}
        ,&nbsp;ngày&nbsp;{expiredTime.format('DD/MM/YYYY')}
      </span>
    </>
  ) : (
    <> {expiredTime.format('HH:mm, ddd, DD/MM/YYYY')}</>
  );
export const checkIsMobile = (userAgent = '') => {
  const device = DeviceDetector(userAgent);
  if (
    device.device.type &&
    (device.device.type === 'mobile' || device.device.type === 'tablet')
  ) {
    return true;
  }
  return false;
};

export const getDeviceInfo = (userAgent = '') => {
  if (!(typeof window === 'undefined')) {
    const device = DeviceDetector(navigator.userAgent);
    if (device.device.type && device.device.type === 'mobile') {
      return 'Mobile-Web';
    } else {
      return 'PC-Web';
    }
  } else {
    return 'Server-web';
  }
};

export const getPlatform = (userAgent = '') => {
  if (!(typeof window === 'undefined')) {
    const device = DeviceDetector(navigator.userAgent);
    if (device.device.type && device.device.type === 'mobile') {
      return 'mobile_web';
    } else {
      return 'website';
    }
  } else {
    return 'website';
  }
};

export const checkWebview = () => {
  const standalone = window.navigator.standalone,
    userAgent = window.navigator.userAgent.toLowerCase(),
    safari = /safari/.test(userAgent),
    ios = /iphone|ipod|ipad/.test(userAgent);

  if (ios) {
    if (!standalone && safari) {
      return 'safari';
    } else if (!standalone && !safari) {
      return 'ios_webview';
    }
  } else {
    if (userAgent.includes('wv')) {
      return 'android_webview';
    } else {
      return 'chrome';
    }
  }
};

export const getAppHash = () => {
  let timeStamp = new Date().getTime();
  timeStamp = timeStamp / 1000 - ((timeStamp / 1000) % 300);
  let str = `${timeStamp}:${
    constants.TEST || constants.DEV ? HASH_KEY_DEV : HASH_KEY_PROD
  }`;
  const hash = CryptoJS.SHA256(str);
  const hashStr = CryptoJS.enc.Base64.stringify(hash);
  return hashStr;
};

export function getExpiredTimeMillisecond(millisecond) {
  const second = millisecond / 1000;
  const curTimeStamp = moment().unix();
  if (second < curTimeStamp) {
    return '0m';
  } else {
    const diffSecond = second - curTimeStamp;
    return secondToString(diffSecond);
  }
}

export const resetDataAfterBookTicket = (dataStorage, name, totalGuest) => {
  let result = [];
  if (!isEmpty(dataStorage)) {
    result = {
      ...result,
      genderContact: 'M',
      nameContact: '',
      phoneNumberContact: '',
      emailContact: '',
      checkValidContactMail: false,
      checkedSame: false,
    };
    for (var index = 0; index < totalGuest; index++) {
      result = {
        ...result,
        [`name_${index}`]: '',
        [`gender_${index}`]: 'M',
        [`outbound_baggage_${index}`]: undefined,
        [`inbound_baggage_${index}`]: undefined,
        [`passport_${index}`]: '',
        [`passport_expired_${index}`]: '',
        [`passport_residence_${index}`]: '',
        [`passport_country_${index}`]: '',
        [`insurance_${index}`]: '',
        [`dob_${index}`]: '',
      };
    }

    localStorage.setItem(name, JSON.stringify(result));
  }
};

export function secondToString(second) {
  let result = '';
  const minutes = Math.floor(second / 60);
  if (minutes < 60) {
    return minutes > 1 ? `${minutes}m` : `1m`;
  } else if (minutes >= 60 && minutes <= 1440) {
    const hours = Math.floor(minutes / 60);
    const surPlus = minutes % 60;
    let surPlusStr = '';
    if (surPlus > 0) {
      surPlusStr = secondToString(surPlus * 60);
    }
    result = hours > 1 ? `${hours}h` : '1h';
    if (surPlusStr) {
      result = `${result} ${surPlusStr}`;
    }
  } else {
    const day = Math.floor(minutes / 1440);
    const surPlus = minutes % 1440;
    let surPlusStr = '';
    if (surPlus > 0) {
      surPlusStr = secondToString(surPlus * 60);
    }
    result = day > 1 ? `${day} ngày` : '1 ngày';
    if (surPlusStr) {
      result = `${result} ${surPlusStr}`;
    }
  }
  return result;
}

export const formatDetailPriceTicketBookingData = (orderDetail) => {
  let detailPriceTicket = {};
  if (!isEmpty(orderDetail)) {
    let totalInboundBagMoney = 0;
    let totalOutboundBagMoney = 0;
    if (orderDetail?.guests) {
      orderDetail?.guests.forEach((guest, idx) => {
        totalInboundBagMoney += guest?.inboundBaggage?.price;
        totalOutboundBagMoney += guest?.outboundBaggage?.price;
      });
    }

    const ticketOutBound = orderDetail.outbound;
    const ticketInBound = orderDetail.inbound;

    detailPriceTicket = {
      outbound: {
        baggageMoney: totalOutboundBagMoney,
        total: ticketOutBound?.price,
      },
      inbound: ticketInBound
        ? {
            baggageMoney: totalInboundBagMoney,
            total: ticketInBound?.price,
          }
        : null,
      insurance: orderDetail?.insuranceAmount || '',
      voucherCode: orderDetail?.promotionCode || '',
      voucherMoney: orderDetail?.discount || 0,
      totalPayment: orderDetail.finalPrice,
    };
  }
  return detailPriceTicket;
};

export const getInvoiceStatus = (
  paymentStatus = flightPaymentStatus.FAIL,
  invoiceStatus = ''
) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const theme = useTheme();
  if (paymentStatus === flightPaymentStatus.SUCCESS) {
    switch (invoiceStatus) {
      case 'handling':
        return {
          text: 'Đang xử lý',
          textColor: theme.palette.gray.grayDark7,
        };
      case 'completed':
        return {
          text: 'Đã xử lý',
          textColor: theme.palette.green.greenLight7,
        };
      default:
        return {
          text: 'Chờ xử lý',
          textColor: theme.palette.blue.blueLight8,
        };
    }
  } else {
    return {
      text: 'Chưa thanh toán',
      textColor: theme.palette.blue.blueLight8,
    };
  }
};

export const getPaymentProperties = (status, isCheckSuccess, t = i18n.t) => {
  if (isCheckSuccess) {
    return {
      title: t('IDS_TEXT_ORDER_PAYMENT_STATUS_SUCCESS'),
      color: '#48BB78',
      bgColor: '#EDF8F1',
      type: flightPaymentStatus.SUCCESS,
    };
  }
  switch (status) {
    case 'WAITING_PAYMENT':
      return {
        title: t('IDS_TEXT_ORDER_PAYMENT_STATUS_WAITING_PAYMENT'),
        color: '#ED8936',
        bgColor: '#FDF3EB',
        type: flightPaymentStatus.WAITING_PAYMENT,
      };
    case 'HOLDING':
      return {
        title: t('IDS_TEXT_ORDER_PAYMENT_STATUS_HOLDING'),
        color: '#48BB78',
        bgColor: '#EDF8F1',
        type: flightPaymentStatus.HOLDING,
      };
    case 'IN_PROGRESS':
      return {
        title: t('IDS_TEXT_ORDER_PAYMENT_STATUS_IN_PROGRESS'),
        color: '#ED8936',
        bgColor: '#FDF3EB',
        type: flightPaymentStatus.IN_PROGRESS,
      };
    case 'SUCCESS':
      return {
        title: t('IDS_TEXT_ORDER_PAYMENT_STATUS_SUCCESS'),
        color: '#48BB78',
        bgColor: '#EDF8F1',
        type: flightPaymentStatus.SUCCESS,
      };
    case 'FAILED':
      return {
        title: t('IDS_TEXT_ORDER_PAYMENT_STATUS_FAIL'),
        color: '#E53E3E',
        bgColor: '#FCECEC',
        type: flightPaymentStatus.FAIL,
      };
    case 'REFUNDED':
      return {
        title: t('IDS_TEXT_ORDER_PAYMENT_STATUS_REFUNDED'),
        color: '#48BB78',
        bgColor: '#EDF8F1',
        type: flightPaymentStatus.REFUNDED,
      };
    default:
      return {
        title: t('IDS_TEXT_FLIGHT_BOOKING_UNDEFINED_SHORT'),
        color: '#ED8936',
        bgColor: '#FDF3EB',
        type: flightPaymentStatus.PENDING,
      };
  }
};

export const getPaymentStatusBookingFlight = (
  status,
  paymentMethodCode,
  expiredTime,
  isCheckSuccess = false,
  t = i18n.t
) => {
  if (isCheckSuccess) {
    return {
      title: t('IDS_TEXT_ORDER_PAYMENT_STATUS_SUCCESS'),
      color: '#48BB78',
      bgColor: '#EDF8F1',
      type: flightPaymentStatus.SUCCESS,
    };
  }

  if (
    (paymentMethodCode === flightPaymentMethodCode.BANK_TRANSFER ||
      paymentMethodCode === flightPaymentMethodCode.BANK_TRANSFER_2) &&
    status === flightPaymentStatus.HOLDING
  ) {
    return {
      title: t('IDS_TEXT_ORDER_PAYMENT_STATUS_WAITING_PAYMENT'),
      color: '#ED8936',
      bgColor: '#FDF3EB',
      type: flightPaymentStatus.PENDING,
    };
  }

  if (
    (status === flightPaymentStatus.HOLDING &&
      expiredTime / 1000 <= moment().unix()) ||
    status === flightPaymentStatus.CANCELLING_HOLDING
  ) {
    return {
      title: t('IDS_TEXT_EXPIRED_HOLDING'),
      color: '#718096',
      bgColor: '#F1F2F4',
      type: flightPaymentStatus.CANCELLING_HOLDING,
    };
  }

  switch (status) {
    case flightPaymentStatus.SUCCESS:
    case flightPaymentStatus.COMPLETED:
    case flightPaymentStatus.PAYMENT_SUCCESS:
      return {
        title: t('IDS_TEXT_ORDER_PAYMENT_STATUS_SUCCESS'),
        color: '#48BB78',
        bgColor: '#EDF8F1',
        type: flightPaymentStatus.SUCCESS,
      };
    case flightPaymentStatus.FAIL:
      return {
        title: t('IDS_TEXT_ORDER_PAYMENT_STATUS_FAIL'),
        color: '#E53E3E',
        bgColor: '#FCECEC',
        type: flightPaymentStatus.FAIL,
      };
    case flightPaymentStatus.PENDING:
    case flightPaymentStatus.AWAITING_PAYMENT:
    case flightPaymentStatus.WAITING_PAYMENT:
      return {
        title: t('IDS_TEXT_ORDER_PAYMENT_STATUS_WAITING_PAYMENT'),
        color: '#ED8936',
        bgColor: '#FDF3EB',
        type: flightPaymentStatus.PENDING,
      };
    case flightPaymentStatus.CANCELLED_BY_PROVIDER:
      return {
        title: t('IDS_TEXT_ORDER_PAYMENT_STATUS_CANCELLED'),
        color: '#718096',
        bgColor: '#F1F2F4',
        type: flightPaymentStatus.CANCELLED_BY_PROVIDER,
      };
    case flightPaymentStatus.REFUNDED_POSTED:
    case flightPaymentStatus.REFUNDED:
      return {
        title: t('IDS_TEXT_ORDER_PAYMENT_STATUS_REFUNDED'),
        color: '#6260DF',
        bgColor: '#EFEFFC',
        type: flightPaymentStatus.REFUNDED,
      };
    case flightPaymentStatus.DISPUTED:
      return {
        title: t('IDS_TEXT_ORDER_PAYMENT_STATUS_DISPUTED'),
        color: '#718096',
        bgColor: '#F1F2F4',
        type: flightPaymentStatus.DISPUTED,
      };
    case flightPaymentStatus.HOLDING:
      return {
        title: t('IDS_TEXT_ORDER_PAYMENT_STATUS_HOLDING'),
        color: '#ED8936',
        bgColor: '#FDF3EB',
        type: flightPaymentStatus.HOLDING,
      };
    default:
      return {
        title: t('IDS_TEXT_ORDER_PAYMENT_STATUS_SUCCESS'),
        color: '#48BB78',
        bgColor: '#EDF8F1',
        type: flightPaymentStatus.SUCCESS,
      };
  }
};

export function strToMins(t) {
  var s = t.split(':');
  return Number(s[0]) * 60 + Number(s[1]);
}

export function minsToStr(t) {
  return Math.trunc(t / 60) + ':' + ('00' + (t % 60)).slice(-2);
}

export const formatMoney = function (
  decimalLength = 0,
  decimal = ',',
  thousands = '.'
) {
  let amount = this;
  try {
    let decimalCount = Math.abs(decimalLength);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? '-' : '';

    const i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
    ).toString();
    const j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : '')
    );
  } catch (e) {
    throw e;
  }
};

export const filterTagsBest = (tags = []) => {
  return tags.filter(
    (tag) =>
      tag?.name?.toUpperCase() === 'Best of the Best'?.trim()?.toUpperCase() ||
      tag?.name?.toUpperCase() === "Travellers' Choice"?.trim()?.toUpperCase()
  );
};

export const filterTagsNormal = (tags = []) => {
  let firstTag = tags.filter(
    (tag) =>
      tag?.name?.toUpperCase() !== 'Best of the Best'?.trim()?.toUpperCase() &&
      tag?.name?.toUpperCase() !== "Travellers' Choice"?.trim()?.toUpperCase()
  );
  return firstTag;
};

export const getLocation = (address = {}) => {
  if (!isEmpty(address.interestingPlace)) {
    return `${address.interestingPlace.name}${`, ${
      !isEmpty(address.districtName)
        ? address.districtName
        : address.provinceName
    }`}`;
  } else {
    return `${
      !isEmpty(address.districtName)
        ? `${address.districtName}`
        : address.provinceName
    }`;
  }
};

export const formatDate = {
  firstYear: 'YYYY-MM-DD',
  firstDay: 'DD-MM-YYYY',
  dayAndMonth: 'DD/MM',
  placeholder: 'dd/mm/yyyy',
};

export const paramsDefaultHotel = () => ({
  checkIn: moment().tz('Asia/Ho_Chi_Minh').format(formatDate.firstDay),
  checkOut: moment()
    .tz('Asia/Ho_Chi_Minh')
    .add(1, 'days')
    .format(formatDate.firstDay),
  adults: 2,
  rooms: 1,
  children: 0,
});

export const checkStarHomeStay = (item = '') => {
  if (!isEmpty(item)) {
    return (
      item.code === 'villa' ||
      item.code === 'apartment' ||
      item.code === 'mansion' ||
      item.code === 'camp' ||
      item.code === 'ecostay' ||
      item.code === 'capsule' ||
      item.code === 'campaign' ||
      item.code === 'homestay' ||
      item.code === 'bungalow-nh'
    );
  }
};

export const stringSlug = (string = '') => {
  const separator = '-';
  const includeDot = false;
  let text = string;
  if (!text) text = '';
  text = text.toString().toLowerCase().trim();
  const sets = [
    { to: 'a', from: '[ÀÁÂÃÄÅÆĀĂĄẠẢẤẦẨẪẬẮẰẲẴẶ]' },
    { to: 'c', from: '[ÇĆĈČ]' },
    { to: 'd', from: '[ÐĎĐÞ]' },
    { to: 'e', from: '[ÈÉÊËĒĔĖĘĚẸẺẼẾỀỂỄỆ]' },
    { to: 'g', from: '[ĜĞĢǴ]' },
    { to: 'h', from: '[ĤḦ]' },
    { to: 'i', from: '[ÌÍÎÏĨĪĮİỈỊ]' },
    { to: 'j', from: '[Ĵ]' },
    { to: 'ij', from: '[Ĳ]' },
    { to: 'k', from: '[Ķ]' },
    { to: 'l', from: '[ĹĻĽŁ]' },
    { to: 'm', from: '[Ḿ]' },
    { to: 'n', from: '[ÑŃŅŇ]' },
    { to: 'o', from: '[ÒÓÔÕÖØŌŎŐỌỎỐỒỔỖỘỚỜỞỠỢǪǬƠ]' },
    { to: 'oe', from: '[Œ]' },
    { to: 'p', from: '[ṕ]' },
    { to: 'r', from: '[ŔŖŘ]' },
    { to: 's', from: '[ßŚŜŞŠ]' },
    { to: 't', from: '[ŢŤ]' },
    { to: 'u', from: '[ÙÚÛÜŨŪŬŮŰŲỤỦỨỪỬỮỰƯ]' },
    { to: 'w', from: '[ẂŴẀẄ]' },
    { to: 'x', from: '[ẍ]' },
    { to: 'y', from: '[ÝŶŸỲỴỶỸ]' },
    { to: 'z', from: '[ŹŻŽ]' },
  ];

  if (includeDot) sets.push({ to: '-', from: "[·/_.,:;']" });
  else sets.push({ to: '-', from: "[·/_,:;']" });

  sets.forEach((set) => {
    text = text.replace(new RegExp(set.from, 'gi'), set.to);
  });

  text = text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w-]+/g, '') // Remove all non-word chars
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text

  if (typeof separator !== 'undefined' && separator !== '-') {
    text = text.replace(/-/g, separator);
  }

  return text ? text : 'a';
};
