/* eslint-disable no-nested-ternary */
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import { WHITE } from 'assets/theme/colors';
import { IconPaymentPendingHotel } from 'public/icons';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  boxStatus: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  wrapContainer: {
    padding: '32px 0 0',
    position: 'relative',
  },
  container: {
    margin: 'auto',
    maxWidth: 630,
    height: 'auto',
    padding: '24px 0',
    background: WHITE,
    borderRadius: '20px 20px 0 0',
  },
  mainStatus: {
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '19px',
    textAlign: 'center',
    color: theme.palette.black.black3,
    marginTop: '12px',
    marginBottom: '8px',
  },
  desc: {
    fontSize: '10px',
    lineHeight: '22px',
    textAlign: 'center',
    color: theme.palette.black.black3,
    padding: '0 16px',
  },
}));

const BookingUndefined = () => {
  const classes = useStyles();
  const { t } = useTranslation(0);
  return (
    <Box className={classes.wrapContainer}>
      <Box className={classes.container}>
        <Box className={classes.boxStatus}>
          <IconPaymentPendingHotel />
          <Box className={classes.mainStatus}>
            {t('IDS_TEXT_UNDEFINED_TRANSACTION')}
          </Box>
          <Box className={classes.desc}>
            {t('IDS_TEXT_FLIGHT_BOOKING_RESULT_DESC_UNDEFINED')}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default BookingUndefined;
