import { ThemeProvider } from '@mui/material/styles';
import { StylesProvider } from '@mui/styles';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './i18n';
import './index.css';
import { MUI_THEME } from './setupTheme.js';

ReactDOM.render(
  <React.Fragment>
    <StylesProvider injectFirst>
      <ThemeProvider theme={MUI_THEME}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ThemeProvider>
    </StylesProvider>
  </React.Fragment>,
  document.getElementById('root')
);
