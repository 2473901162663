import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import { WHITE } from 'assets/theme/colors';
import { useTranslation } from 'react-i18next';
import { CUSTOMER_TYPE_TEXT, GENDER_LIST } from 'utils/constants';

const useStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    textAlign: 'center',
    paddingBottom: 4,
    fontSize: '14px',
    lineHeight: '17px',
    color: theme.palette.black.black3,
    marginBottom: 4,
  },
  title: {
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '21px',
    color: theme.palette.black.black3,
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
  },
  container: {
    width: '100%',
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    backgroundColor: WHITE,
  },
  infoRow: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
  },
}));

const OrderCustomerInfo = ({ guests = {}, isFromPayment = false }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  // let adultNo = 0;
  let childrenNo = 0;
  let infantNo = 0;
  return (
    <Box className={classes.container}>
      <Box className={classes.title}>
        {t('IDS_MT_TEXT_INFO_CUSTOMER_FLIGHT')}
      </Box>
      {guests.map((item, index) => {
        return (
          <Box className={classes.infoRow} key={index}>
            <Box className={classes.content}>
              {item.ageCategory.toLowerCase() === CUSTOMER_TYPE_TEXT.ADULT &&
                `${t(
                  `${GENDER_LIST.find((el) => el.id === item?.gender)?.label}`
                )}:`}
              {item.ageCategory.toLowerCase() === CUSTOMER_TYPE_TEXT.CHILDREN &&
                `${t('IDS_MT_TEXT_CHILDREN')} ${++childrenNo}:`}
              {item.ageCategory.toLowerCase() === CUSTOMER_TYPE_TEXT.INFANT &&
                `${t('IDS_MT_TEXT_BABY')} ${++infantNo}:`}
            </Box>
            <Box className={classes.content}>
              {item?.lastName.toUpperCase()} {item?.firstName.toUpperCase()}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default OrderCustomerInfo;
