/* eslint-disable no-useless-computed-key */
import axios from 'axios';
import Qs from 'qs';
import cookie from 'js-cookie';

import * as constants from 'utils/constants';
import { isEmpty, getAppHash, getDeviceInfo, getPlatform } from './helpers';
import { IS_MYTOUR } from 'utils/constants';

const request = axios.create();

let isAlreadyFetchingAccessToken = false;
let subscribers = [];

const ISSERVER = typeof window === 'undefined';
let deviceId = null;
if (!ISSERVER) {
  const key = `${
    getPlatform() === 'website'
      ? process.env.NEXT_PUBLIC_APP_ID
      : process.env.NEXT_PUBLIC_APP_ID_MOBILE
  }_v2021-05-03`;
  deviceId = `${new Date().valueOf()}-${Math.random()}`;
  const value = localStorage.getItem(key);
  if (value === null) {
    localStorage.setItem(key, deviceId);
    cookie.set(constants.DEVICE_ID_IN_SERVER, deviceId);
  } else {
    deviceId = value;
    // save device id to cookies
    if (isEmpty(cookie.get(constants.DEVICE_ID_IN_SERVER))) {
      cookie.set(constants.DEVICE_ID_IN_SERVER, value);
    }
  }
}

// function onAccessTokenFetched(access_token) {
//   subscribers = subscribers.map((callback) => callback(access_token));
//   subscribers = [];
// }

function addSubscriber(callback) {
  subscribers.push(callback);
}

request.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => Promise.reject(error)
);

request.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const originalRequest = error.config;
    if (
      error.response &&
      error.response.status === 401 &&
      isEmpty(error.response.data)
    ) {
      const refreshToken = cookie.get(constants.REFRESH_TOKEN);
      if (
        refreshToken &&
        !originalRequest._retry &&
        error.config.url.indexOf('/public-api/v1/login') === -1
      ) {
        originalRequest._retry = true;
        if (!isAlreadyFetchingAccessToken) {
          isAlreadyFetchingAccessToken = true;
          // const dataDTO = {
          //   refresh_token: refreshToken,
          //   grant_type: 'refresh_token',
          // };
          // login(dataDTO)
          //   .then(({ data }) => {
          //     isAlreadyFetchingAccessToken = false;
          //     // update token to cookie
          //     cookie.set(constants.TOKEN, data.access_token);
          //     cookie.set(constants.REFRESH_TOKEN, data.refresh_token);
          //     onAccessTokenFetched(data.access_token);
          //   })
          //   .catch(() => {
          //     subscribers = [];
          //     isAlreadyFetchingAccessToken = false;
          //     cookie.remove(constants.TOKEN);
          //     cookie.remove(constants.REFRESH_TOKEN);
          //   });
        }
        const retryOriginalRequest = new Promise((resolve) => {
          addSubscriber((access_token) => {
            originalRequest.headers.Authorization = `Bearer ${access_token}`;
            resolve(axios(originalRequest));
          });
        });
        return retryOriginalRequest;
      } else {
        subscribers = [];
        isAlreadyFetchingAccessToken = false;
        cookie.remove(constants.TOKEN);
        cookie.remove(constants.REFRESH_TOKEN);
      }
    } else {
      return Promise.reject(error.response || { data: {} });
    }
  }
);
const getUrlByEnv = (dev, stage, prod) => {
  if (constants.DEV || constants.TEST) {
    return dev;
  }
  if (constants.STAGING) {
    return stage;
  }
  return prod;
};

export const getBaseUrl = (severVice = '') => {
  switch (severVice) {
    case constants.PAYMENT_STATUS:
      return getUrlByEnv(
        'https://gate.dev.tripi.vn',
        'https://gate.stage.tripi.vn',
        'https://gate.mytour.vn'
      );
    case constants.GATE:
      return getUrlByEnv(
        'https://gate.dev.tripi.vn',
        'https://gate.stage.tripi.vn',
        'https://gate.tripi.vn'
      );
    case constants.CHECKOUT_PAGE:
      return getUrlByEnv(
        'https://checkout-dev.vntravelgroup.vn',
        'https://checkout-stage.vntravelgroup.vn',
        'https://checkout.vntravelgroup.vn'
      );
    case constants.BACKEND:
      return getUrlByEnv(
        'https://backend.dev.tripi.vn',
        'https://stageapi.tripi.vn/',
        'https://tourapi.tripi.vn/'
      );
    default:
      return getUrlByEnv(
        constants.BASE_URL,
        'https://backend.stage.tripi.vn',
        'https://coreapi.tripi.vn'
      );
  }
};
const api = (options = {}) => {
  if (!options.noAuthentication) {
    if (!isEmpty(cookie.get(constants.TOKEN))) {
      options.headers = {
        ...options.headers,
        login_token: `${cookie.get(constants.TOKEN)}`,
      };
    }
  }
  options.headers = {
    ...options.headers,
    deviceId: !isEmpty(deviceId)
      ? deviceId
      : options?.headers?.deviceId ||
        `${new Date().valueOf()}-${Math.random()}`,
    deviceInfo: getDeviceInfo(),
    ['accept-language']: 'vi-VN',
  };

  if (
    options.severVice === constants.HOTEL_SERVICE ||
    options.severVice === constants.PAYMENT_SERVICE ||
    options.severVice === constants.PROMOTION_SERVICE ||
    options.severVice === constants.AUTH_SERVICE ||
    options.severVice === constants.ACCOUNT_SERVICE ||
    options.severVice === constants.AMS_SERVICE ||
    options.severVice === constants.HMS_PREMIUM_SERVICE ||
    options.severVice === constants.FLIGHT_SERVICE ||
    options.severVice === constants.CUSTOMER_ENGAGEMENT ||
    options.severVice === constants.DOMAIN_GATE ||
    options.severVice === constants.PAYMENT_STATUS ||
    options.severVice === constants.GATE
  ) {
    options.headers = {
      ...options.headers,
      lang: 'vi',
      platform: getPlatform(),
      appId: constants.APP_ID,
      appHash: getAppHash(),
      version: constants.VERSION,
      caId: IS_MYTOUR ? 17 : 9999,
    };
    const isSever = typeof window === 'undefined';
    if (!isSever) {
      const lastAffiliate = JSON.parse(
        localStorage.getItem(constants.LAST_AFFILIATE)
      );
      if (!isEmpty(lastAffiliate)) {
        options.headers = {
          ...options.headers,
          aff_cid: lastAffiliate.aff_cid,
        };
      }
    }
  }
  if (options.severVice === constants.MY_TOUR_SERVICE) {
    options.headers = {
      ...options.headers,
      ['X-Auth-UserId']: 'G8BKFJLD8HG6GUH',
      // ["X-Auth-UserId"]: "admin123",
      // ["Mytour-OS"]: "Android",
      ['X-App-Mytour']: 'mytour',
      // ["User-Agent"]: navigator.userAgent,
    };
    delete options.headers.caId;
    delete options.headers.deviceId;
    delete options.headers.deviceInfo;
    delete options.headers.login_token;
  }

  return request({
    baseURL: getBaseUrl(options.severVice),
    ...options,
    paramsSerializer: (params) =>
      Qs.stringify(params, { arrayFormat: 'repeat' }),
    headers: {
      ...options.headers,
    },
  });
};

export default api;
