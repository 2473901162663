import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { BLACK_90 } from 'assets/theme/colors';
import moment from 'moment';
import {
  IconAdultFlight,
  IconBabyFlight,
  IconChildrenFlight,
  IconClose,
} from 'public/icons';
import { useTranslation } from 'react-i18next';
import { CUSTOMER_TYPE_TEXT, genderLabel } from 'utils/constants';
import { DATE_FORMAT_BACK_END, MONTH_YEAR } from 'utils/moment';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: 8,
    top: 8,
  },
  dialogTitle: {
    margin: 0,
    padding: 24,
  },
  dialogContent: {
    padding: '0 24px',
  },
  inputStyle: {
    '& > input': {
      padding: 0,
    },
  },
  contentText: {
    color: BLACK_90,
    marginBottom: 20,
  },
  textName: {
    marginLeft: 12,
    wordWrap: 'break-word',
    maxWidth: '260px',
    lineHeight: '17px',
  },
}));

const CustomerDetailModalDesktop = ({
  open = false,
  handleClose = () => {},
  guests,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Dialog
      Dialog
      onClose={handleClose(false)}
      open={open}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle
        onClose={handleClose(false)}
        disableTypography
        classes={{ root: classes.dialogTitle }}
      >
        <Typography variant="h6">
          {t('IDS_MT_TEXT_INFO_CUSTOMER_FLIGHT')}
        </Typography>
        <IconButton
          onClick={handleClose(false)}
          aria-label="close"
          className={classes.closeButton}
        >
          <IconClose />
        </IconButton>
      </DialogTitle>
      <DialogContent classes={{ root: classes.dialogContent }}>
        <Box display="flex" flexWrap="wrap">
          {guests.length &&
            guests.map((item, index) => {
              return (
                <Box
                  display="flex"
                  flexDirection="column"
                  key={index}
                  width="50%"
                  pr="60px"
                  pb="24px"
                >
                  <Box display="flex" alignItems="flex-end">
                    {item.ageCategory === CUSTOMER_TYPE_TEXT.ADULT ? (
                      <IconAdultFlight />
                    ) : item.ageCategory === CUSTOMER_TYPE_TEXT.CHILDREN ? (
                      <IconChildrenFlight />
                    ) : item.ageCategory === CUSTOMER_TYPE_TEXT.INFANT ? (
                      <IconBabyFlight />
                    ) : null}
                    <Typography
                      variant="subtitle2"
                      className={classes.textName}
                    >
                      {item?.fullName.toUpperCase()}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    pl={3}
                    pt={6 / 8}
                  >
                    <Typography
                      variant="caption"
                      style={{ lineHeight: '17px' }}
                    >
                      {t('IDS_MT_TEXT_GENDER')}
                    </Typography>
                    <Typography
                      variant="caption"
                      style={{ lineHeight: '17px' }}
                    >
                      {genderLabel[item.gender]}
                    </Typography>
                  </Box>
                  {item?.dob && (
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      pl={3}
                      pt={6 / 8}
                    >
                      <Typography
                        variant="caption"
                        style={{ lineHeight: '17px' }}
                      >
                        {t('IDS_MT_TEXT_DATE_OF_BIRTH')}
                      </Typography>
                      <Typography
                        variant="caption"
                        style={{ lineHeight: '17px' }}
                      >
                        {moment(item.dob, DATE_FORMAT_BACK_END).format('L')}
                      </Typography>
                    </Box>
                  )}
                  {item?.passport && (
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      pl={3}
                      pt={6 / 8}
                    >
                      <Typography
                        variant="caption"
                        style={{ lineHeight: '17px' }}
                      >
                        {t('IDS_TEXT_PASSPORT_NUMBER')}
                      </Typography>
                      <Typography
                        variant="caption"
                        style={{ lineHeight: '17px' }}
                      >
                        {item.passport}
                      </Typography>
                    </Box>
                  )}
                  {item?.nationality && (
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      pl={3}
                      pt={6 / 8}
                    >
                      <Typography
                        variant="caption"
                        style={{ lineHeight: '17px' }}
                      >
                        {t('IDS_TEXT_PASSPORT_RESIDENCE')}
                      </Typography>
                      <Typography
                        variant="caption"
                        style={{ lineHeight: '17px' }}
                      >
                        {item.nationality}
                      </Typography>
                    </Box>
                  )}
                  {item?.passportCountry && (
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      pl={3}
                      pt={6 / 8}
                    >
                      <Typography
                        variant="caption"
                        style={{ lineHeight: '17px' }}
                      >
                        {t('IDS_TEXT_PASSPORT_COUNTRY')}
                      </Typography>
                      <Typography
                        variant="caption"
                        style={{ lineHeight: '17px' }}
                      >
                        {item.passportCountry}
                      </Typography>
                    </Box>
                  )}
                  {item?.passportExpiry && (
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      pl={3}
                      pt={6 / 8}
                    >
                      <Typography
                        variant="caption"
                        style={{ lineHeight: '17px' }}
                      >
                        {t('IDS_TEXT_PASSPORT_EXPIRED')}
                      </Typography>
                      <Typography
                        variant="caption"
                        style={{ lineHeight: '17px' }}
                      >
                        {item.passportExpiry &&
                          moment(
                            item.passportExpiry,
                            DATE_FORMAT_BACK_END
                          ).format(MONTH_YEAR)}
                      </Typography>
                    </Box>
                  )}
                </Box>
              );
            })}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default CustomerDetailModalDesktop;
