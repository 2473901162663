import {
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextareaAutosize,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import { makeStyles } from '@mui/styles';
import useHotelConfirmTA from 'modules/hooks/useHotelConfirmTA';
import { IconMytour, IconRadioActive } from 'public/icons';
import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DetailBooking from './components/DetailBooking';
import HeaderMytour from './components/HeaderMytour';

const useStyles = makeStyles((theme) => ({
  cardStt: {
    marginLeft: 48,
    height: 52,
    width: 216,
    color: '#FF3366',
    background: '#FFFFFF',
    textTransform: 'none',
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid #FF3366',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    fontWeight: 500,
    fontSize: 14,
    cursor: 'pointer',
  },
}));

const HotelConfirmTAMessages = () => {
  const classes = useStyles();
  const { briefBooking, confirmEmptyRoom, cancelEmptyRoom } =
    useHotelConfirmTA();
  const { t } = useTranslation();

  const [confirmStt, setConfirmStt] = useState(true);
  const [dataConfirm, setDataConfirm] = useState({
    reason: '',
    expiredTime: '30',
  });

  const sendRequestBooking = () => {
    if (confirmStt) {
      confirmEmptyRoom(dataConfirm);
    } else {
      cancelEmptyRoom(dataConfirm);
    }
  };

  return (
    <React.Fragment>
      <Container maxWidth="lg">
        <Box sx={{ minHeight: '100vh', padding: '24px', color: '#0B0C0D' }}>
          <Box display="flex" justifyContent="space-between">
            <IconMytour />
            <HeaderMytour />
          </Box>
          <Divider
            style={{ backgroundColor: '#FF3366', height: 2, marginTop: 8 }}
          />
          <Box
            p={4}
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <Typography variant="h4">{t('IDS_TEXT_CONFIRM_EMPTY')}</Typography>{' '}
            <Box width="100%">
              <Box display="flex" alignItems="center">
                <Typography variant="body2">
                  {t('IDS_MT_TEXT_HOTEL_HI')}
                </Typography>
                &nbsp;
                <Typography variant="subtitle2">
                  {briefBooking?.hotelName},
                </Typography>
              </Box>
              <p>
                <Typography variant="body2">
                  {t('IDS_MT_TEXT_NOTI_HOTEL')}
                </Typography>
              </p>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body2">
                  {t('IDS_MT_TEXT_HOTEL_ACCESS')}
                </Typography>
                <Link
                  href={briefBooking?.bookingDetailUrl}
                  target="_blank"
                  rel="noopener"
                >
                  <Typography
                    variant="body2"
                    style={{ marginLeft: 4, color: '#004EBC' }}
                  >
                    {t('IDS_MT_TEXT_ACCESS_WEB')}
                  </Typography>
                </Link>
              </div>
            </Box>
            <DetailBooking briefBooking={briefBooking} />
            <Box mt={4}>
              <Typography variant="h6" style={{ fontWeight: 700 }}>
                {t('IDS_TEXT_CONFIRM_EMPTY')}
                <span
                  style={{
                    color: '#00B6F3',
                  }}
                >
                  {' '}
                  {briefBooking?.roomNightText}{' '}
                  <span
                    style={{
                      color: '#000000',
                    }}
                  >
                    {briefBooking?.numRoomNightText}
                  </span>
                </span>
              </Typography>
            </Box>
            <Box mt={3} display="flex">
              <Box
                className={classes.cardStt}
                onClick={() => {
                  setConfirmStt(true);
                }}
              >
                {confirmStt && (
                  <IconRadioActive
                    className="svgFillAll"
                    fill="#FF3366"
                    stroke="#FFFFFF"
                    style={{ marginRight: 4 }}
                  />
                )}
                {t('IDS_MT_TEXT_CONFIRM_EMPTY_ROOM')}
              </Box>
              <Box
                className={classes.cardStt}
                onClick={() => {
                  setConfirmStt(false);
                }}
              >
                {!confirmStt && (
                  <IconRadioActive
                    className="svgFillAll"
                    fill="#FF3366"
                    stroke="#FFFFFF"
                    style={{ marginRight: 4 }}
                  />
                )}
                {t('IDS_MT_TEXT_EMPTY_ROOM')}
              </Box>
            </Box>
            <Box my={4}>
              <Typography
                style={{
                  color: '#8C9497',
                }}
                variant="subtitle2"
              >
                {!confirmStt
                  ? 'Mytour có thể biết thông tin số phòng trống/ đêm nghỉ khách sạn có thể đáp ứng?'
                  : 'Quý khách sạn có thể giữ phòng trong thời gian chờ đợi khách hàng có thể thanh toán cho khách hàng?'}
              </Typography>
              {confirmStt && (
                <FormControl style={{ width: '100%', alignItems: 'center' }}>
                  <RadioGroup
                    row
                    onClick={(e) => {
                      setDataConfirm({
                        ...dataConfirm,
                        expiredTime: e.target.value,
                      });
                    }}
                    value={dataConfirm?.expiredTime}
                  >
                    <FormControlLabel
                      value="15"
                      control={<Radio color="secondary" />}
                      label="15 phút"
                    />
                    <FormControlLabel
                      value="30"
                      control={<Radio color="secondary" />}
                      label="30 phút"
                    />
                    <FormControlLabel
                      value="60"
                      control={<Radio color="secondary" />}
                      label="1 tiếng"
                    />
                  </RadioGroup>
                </FormControl>
              )}
            </Box>
            {!confirmStt && (
              <TextareaAutosize
                aria-label="minimum height"
                minRows={6}
                placeholder="Text"
                style={{
                  width: '100%',
                  border: '1px solid #E0E0E0',
                  borderRadius: 12,
                  padding: 8,
                }}
                onChange={(e) => {
                  setDataConfirm({
                    ...dataConfirm,
                    reason: e.target.value,
                  });
                }}
              />
            )}
            <Button
              variant="contained"
              style={{
                color: '#FFFFFF',
                background: '#FF3366',
                textTransform: 'none',
                marginTop: 16,
                width: 168,
              }}
              onClick={sendRequestBooking}
            >
              {t('IDS_TEXT_SEND_INFO')}
            </Button>
            <Box mt={4} width="100%">
              <Divider
                style={{ backgroundColor: '#D9DBDC', height: 1, width: '100%' }}
              />
              <Box mt={1}>
                <Typography variant="body2">
                  {t('IDS_MT_TEXT_BEST_REGARDS')}
                </Typography>
                <Typography variant="body2">
                  {t('IDS_MT_TEXT_MT_TEAM')}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </React.Fragment>
  );
};
export default HotelConfirmTAMessages;
