export const TIME_FORMAT = 'HH:mm:ss';
export const HOUR_MINUTE = 'HH:mm';
export const DATE_FORMAT = 'DD/MM/YYYY';
export const DATE_FORMAT_BACK_END = 'DD-MM-YYYY';
export const C_DATE_FORMAT = 'YYYY-MM-DD';
export const C_DATE_TIME_FORMAT = `${C_DATE_FORMAT} ${TIME_FORMAT}`;
export const DATE_TIME_FORMAT = `${DATE_FORMAT_BACK_END} ${TIME_FORMAT}`;
export const DATE_TIME_FORMAT_BREAK = `${DATE_FORMAT}[\n]${TIME_FORMAT}`;
export const DATE_FORMAT_ALL = `${DATE_FORMAT} ${TIME_FORMAT}`;
export const DATE_TIME_ISOLATE_FORMAT = `${DATE_FORMAT_BACK_END}, ${TIME_FORMAT}`;
export const DATE_FORMAT_ALL_ISOLATE = `${DATE_FORMAT}, ${TIME_FORMAT}`;
export const DATE_TIME_FORMAT_NO_S = `${DATE_FORMAT_BACK_END} ${HOUR_MINUTE}`;
export const MONTH_YEAR = 'MM/YY';
export const DATE_MONTH = 'DD/MM';
export const FULL_TIME_FORMAT = 'HH:mm, ddd, DD/MM/YYYY';
