import { Box } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import { checkStarHomeStay, isEmpty } from 'utils/helpers';

import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import BootstrapTooltip from '../BootstrapTooltip';
import Vote from './Vote';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapTypeHotel: {
    background: theme.palette.secondary.main,
    borderRadius: 4,
    padding: '0 4px',
  },
  iconMPlus: {
    width: 63,
    height: 20,
  },
  categoryName: {
    padding: '2px 4px',
    fontSize: 12,
    lineHeight: '14px',
    color: theme.palette.secondary.main,
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: 4,
    marginLeft: 6,
    whiteSpace: 'nowrap',
  },
  categoryNameOverlay: {
    width: 100,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  wrapMyPlus: {
    marginLeft: 6,
  },
}));

// done translated
const RankingHotel = ({
  hotelCategory,
  hotelStar = 0,
  wrapRating = '',
  styleMyPlus = '',
  myPlus = false,
  isOverlay = false,
}) => {
  const { t: trans } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Box className={clsx(classes.container, wrapRating)}>
      {/* star */}
      <BootstrapTooltip
        title={
          checkStarHomeStay(hotelCategory)
            ? trans('IDS_MT_TEXT_STAR_PINK_DESC')
            : trans('IDS_MT_TEXT_STAR_YELLOW_DESC')
        }
        placement="top"
      >
        <Box>
          <Vote
            maxValue={hotelStar}
            value={hotelStar}
            colorStar={
              checkStarHomeStay(hotelCategory)
                ? theme.palette.secondary.main
                : theme.palette.yellow.yellowLight3
            }
          />
        </Box>
      </BootstrapTooltip>
      {/* MyPlus */}
      {myPlus && (
        <Box
          className={`${classes.wrapTypeHotel} ${
            hotelStar ? clsx(classes.wrapMyPlus, styleMyPlus) : 0
          }`}
        >
          <img
            src={`https://storage.googleapis.com/tripi-assets/mytour/icons/icon_mplus_app_test.svg`}
            className={classes.iconMPlus}
            alt=""
          />
        </Box>
      )}
      {/* category */}
      {!isEmpty(hotelCategory) &&
        hotelCategory?.code?.trim()?.toUpperCase() !==
          'hotel'.trim().toUpperCase() && (
          <BootstrapTooltip title={hotelCategory.name} placement="top">
            <Box
              className={`${classes.categoryName} ${
                isOverlay && myPlus ? classes.categoryNameOverlay : ''
              }`}
              component="span"
            >
              {hotelCategory.name}
            </Box>
          </BootstrapTooltip>
        )}
    </Box>
  );
};

export default React.memo(RankingHotel);
