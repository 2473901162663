import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import Grow from '@mui/material/Grow';
import { makeStyles } from '@mui/styles';
import { GREY_100, WHITE } from 'assets/theme/colors';
import clsx from 'clsx';
import WayFlightItemDetail from 'modules/desktop/flight/payment/orderDetail/WayFlightItemDetail';
import { IconClose } from 'public/icons';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { flightPaymentMethodCode } from 'utils/constants';
import { getPaymentProperties, isEmpty } from 'utils/helpers';
import Ancillaries from './Ancillaries';
import OrderContact from './OrderContact';
import OrderCustomerInfo from './OrderCustomerInfo';
import OrderExportInvoice from './OrderExportInvoice';
import OrderInfo from './OrderInfo';
// import OrderPriceDetail from './OrderPriceDetail';
import OrderInsurance from './OrderInsurance';
import OrderPaymentInfo from './OrderPaymentInfo';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow ref={ref} {...props} timeout={300} />;
});

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 8,
    backgroundColor: WHITE,
  },
  backToOrder: {
    color: GREY_100,
    cursor: 'pointer',
  },
  imgOrder: {
    backgroundColor: theme.palette.gray.grayLight26,
    borderRadius: 8,
    padding: 24,
  },
  titleTicket: {
    padding: '4px 6px',
    borderRadius: 4,
    color: WHITE,
    backgroundColor: theme.palette.blue.blueLight8,
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '20px',
  },
  ticketDetail: {
    width: '100%',
  },
  lineBox: {
    display: 'flex',
    width: '100%',
  },
  lineGrid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '24px',
  },
  divider: {
    borderBottom: `1px solid ${theme.palette.gray.grayLight22}`,
    width: '100%',
  },
  closeButton: {
    position: 'absolute',
    right: 0,
  },
  dialogTitle: {
    margin: 0,
    padding: '0px 24px 0 24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    fontWeight: '600',
    fontSize: '20px',
    lineHeight: '24px',
    color: theme.palette.black.black3,
  },
  dialogContent: {
    padding: 8,
  },
}));

const OrderDetail = ({
  open = false,
  handleClose = () => {},
  orderDetail = {},
  airLinesInfo = {},
  airPortsInfo,
}) => {
  const classes = useStyles();

  const [paymentStatus, setPaymentStatus] = useState({});

  const flightDetails = orderDetail?.tickets?.map((p) => p?.flightDetails);
  const { t } = useTranslation();

  const actionGetPaymentStatus = useCallback(() => {
    const checkSuccess =
      orderDetail?.paymentMethodCode === flightPaymentMethodCode.ATM ||
      orderDetail?.paymentMethodCode === flightPaymentMethodCode.CREDIT ||
      orderDetail?.paymentMethodCode === flightPaymentMethodCode.VISA_MASTER ||
      orderDetail?.paymentMethodCode === flightPaymentMethodCode.QR_CODE;
    const dataStatus = getPaymentProperties(
      orderDetail?.booking?.bookingStatus,
      checkSuccess,
      t
    );
    setPaymentStatus(dataStatus);
  }, [orderDetail?.booking?.bookingStatus, orderDetail?.paymentMethodCode, t]);

  useEffect(() => {
    actionGetPaymentStatus();
  }, [actionGetPaymentStatus, orderDetail]);

  const isTwoWay = orderDetail?.tickets?.length === 2;

  return (
    <Dialog
      Dialog
      onClose={handleClose(false)}
      open={open}
      maxWidth="lg"
      fullWidth
      TransitionComponent={Transition}
    >
      <DialogTitle
        onClose={handleClose(false)}
        disableTypography
        classes={{ root: classes.dialogTitle }}
      >
        <Box className={classes.title}>{t('IDS_TEXT_ORDER_DETAIL')}</Box>
        <IconButton
          onClick={handleClose(false)}
          aria-label="close"
          className={classes.closeButton}
        >
          <IconClose />
        </IconButton>
      </DialogTitle>
      <DialogContent classes={{ root: classes.dialogContent }}>
        <Box className={classes.wrapper}>
          <OrderInfo orderDetail={orderDetail} paymentStatus={paymentStatus} />
          <Box sx={{ display: 'flex', marginTop: '24px', alignItems: 'start' }}>
            <Box className={classes.imgOrder}>
              <img
                src="https://storage.googleapis.com/public-tripi/tripi-feed/img/455910RrmtiA/order_flight.png"
                alt=""
                style={{ width: 86, height: 54, objectFit: 'cover' }}
              />
            </Box>
            <Box ml={2} className={classes.lineBox} flexDirection="column">
              <Box className={classes.lineGrid} mb={3}>
                <Box className={clsx(classes.ticketDetail)}>
                  {isTwoWay && (
                    <Box mb={12 / 8} display="flex">
                      <Box className={classes.titleTicket}>
                        {t('IDS_MT_TEXT_DEPARTURE').toUpperCase()}
                      </Box>
                    </Box>
                  )}
                  <WayFlightItemDetail
                    item={orderDetail?.tickets[0]}
                    airlineInfo={airLinesInfo?.find(
                      (ele) =>
                        ele.code ===
                        orderDetail?.tickets[0]?.flightDetails[0]
                          ?.operatingAirline
                    )}
                    orderDetail={orderDetail}
                    flightDetail={flightDetails[0]}
                    airPortsInfo={airPortsInfo}
                  />
                </Box>
                {isTwoWay && (
                  <Box className={clsx(classes.ticketDetail)}>
                    <Box mb={12 / 8} display="flex">
                      <Box className={classes.titleTicket}>
                        {t('IDS_MT_TEXT_RETURN').toUpperCase()}
                      </Box>
                    </Box>
                    <WayFlightItemDetail
                      item={orderDetail?.tickets[1]}
                      airlineInfo={airLinesInfo?.find(
                        (ele) =>
                          ele.code ===
                          orderDetail?.tickets[1]?.flightDetails[0]
                            ?.operatingAirline
                      )}
                      orderDetail={orderDetail}
                      flightDetail={flightDetails[1]}
                      airPortsInfo={airPortsInfo}
                    />
                  </Box>
                )}
              </Box>
              <Box className={classes.divider} />
              <Box className={classes.lineBox} mt={3}>
                <OrderContact item={orderDetail?.contact} />
              </Box>
              <Box className={classes.lineBox} mt={3}>
                <OrderCustomerInfo
                  guests={orderDetail.passengers}
                  isFromPayment={true}
                />
              </Box>
              {!isEmpty(orderDetail?.invoice) && (
                <Box className={classes.lineBox} mt={3} flexDirection="column">
                  <OrderExportInvoice invoice={orderDetail.invoice} />
                </Box>
              )}
              <Box className={classes.lineBox} mt={3}>
                <OrderPaymentInfo
                  payment={orderDetail?.payment}
                  paymentStatus={paymentStatus}
                />
              </Box>
              {!!orderDetail?.insurance && (
                <Box className={classes.lineBox} mt={3}>
                  <OrderInsurance insurance={orderDetail?.insurance} />
                </Box>
              )}
              {!!orderDetail?.ancillaries?.length && (
                <Box mt={3}>
                  <Ancillaries
                    ancillaries={orderDetail?.ancillaries}
                    passengers={orderDetail?.passengers}
                    tickets={orderDetail?.tickets}
                  />
                </Box>
              )}
              {/* <Box className={classes.lineBox} mt={3}>
                <OrderPriceDetail orderDetail={orderDetail} />
              </Box> */}
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default OrderDetail;
