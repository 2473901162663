import { Box } from '@mui/material';
import { NEUTRAL_100 } from 'assets/theme/colors';
import LoadingIcon from 'modules/common/LoadingIcon';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { MENU } from 'utils/routes';

const MainLayout = (props) => {
  return (
    <Box sx={{ backgroundColor: NEUTRAL_100, minHeight: '100vh' }}>
      <React.Suspense fallback={<LoadingIcon />}>
        <Switch>
          {MENU.map((item) => {
            return (
              <Route
                exact
                path={item.route}
                component={item.mobileComponent}
                key={item.route}
              />
            );
          })}
        </Switch>
      </React.Suspense>
    </Box>
  );
};

export default MainLayout;
