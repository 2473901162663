import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { IconPlaneBlue, IconLocationBlue } from 'public/icons';
import { DATE_FORMAT } from 'utils/moment';
import moment from 'moment';
import { BLACK_90 } from 'assets/theme/colors';
import clsx from 'clsx';
import { strToMins } from 'utils/helpers';

const useStyles = makeStyles((theme) => ({
  airlineLogo: {
    width: 32,
    height: 32,
  },
  iconDot: {
    margin: 6,
  },
  airlineName: {
    color: BLACK_90,
    whiteSpace: 'nowrap',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '17px',
  },
  flightCode: {
    color: BLACK_90,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '14px',
    lineHeight: '17px',
  },
  warpAirBrand: {
    display: 'flex',
    alignItems: 'center',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    color: BLACK_90,
  },
  title: {
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '20px',
    color: theme.palette.black.black3,
  },
  container: {
    display: 'grid',
    gridTemplateColumns: '4fr 1fr 12fr',
    gap: '0 8px',
  },
  bold: {
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '19px',
    color: theme.palette.black.black3,
  },
  grayText: {
    fontSize: '14px',
    lineHeight: '17px',
    textAlign: 'right',
    color: theme.palette.gray.grayDark7,
  },
  center: {
    placeSelf: 'center',
    display: 'grid',
    placeItems: 'center',
    height: '100%',
  },
  textAlignRight: {
    textAlign: 'right',
  },
  verticalDivider: {
    height: '100%',
    borderRight: `1px solid ${theme.palette.blue.blueLight8}`,
  },
}));

const VerticalDivider = () => {
  const classes = useStyles();
  return <Box className={classes.verticalDivider} />;
};

const WayFlightItemDetail = ({
  item = {},
  airlineInfo = {},
  flightDetail = [],
  airPortsInfo = [],
  containerStyle = {},
}) => {
  const classes = useStyles();
  return (
    <Box className={classes.container} sx={containerStyle}>
      {/*1*/}
      <Box className={clsx(classes.bold, classes.textAlignRight)}>
        {item?.departureTime}
      </Box>
      <Box className={classes.center}>
        <IconPlaneBlue />
      </Box>
      <Box className={classes.title}>{`${
        airPortsInfo?.find((ele) => ele?.code === item?.departureAirport)?.name
      } (${item?.departureAirport})`}</Box>

      {/*2*/}
      <Box
        className={clsx(classes.grayText, classes.textAlignRight)}
        sx={{ padding: '4px 0' }}
      >
        {moment(item?.departureDate, DATE_FORMAT).format('DD [thg] MM')}
      </Box>
      <Box className={classes.center}>
        <VerticalDivider />
      </Box>
      <Box sx={{ padding: '4px 0' }}></Box>

      {/*3*/}
      <Box
        className={clsx(classes.grayText, classes.textAlignRight)}
        sx={{ padding: '8px 0' }}
      >
        {(() => {
          const diffTime = moment(item?.arrivalDate, DATE_FORMAT)
            .add(strToMins(item?.arrivalTime || '00:00'), 'minutes')
            .diff(
              moment(item?.departureDate, DATE_FORMAT).add(
                strToMins(item?.departureTime || '00:00'),
                'minutes'
              )
            );
          const duration = moment.duration(diffTime);
          return `${duration.hours()}h${duration.minutes() % 60}'`;
        })()}
      </Box>
      <Box className={classes.center}>
        <VerticalDivider />
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '12px',
          padding: '8px 0',
        }}
      >
        <img src={airlineInfo.logo} className={classes.airlineLogo} alt="" />
        <Box sx={{ display: 'flex', gap: '4px', flexDirection: 'column' }}>
          <Box className={classes.airlineName}>{airlineInfo.name}</Box>
          <Box className={classes.warpAirBrand}>
            <Box
              variant="caption"
              style={{ lineHeight: '17px' }}
              className={classes.flightCode}
            >
              {flightDetail?.map((p) => p?.flightCode).join('-')}
            </Box>
            {/* <IconDot className={classes.iconDot} /> */}
          </Box>
        </Box>
      </Box>

      {/*4*/}
      <Box className={clsx(classes.bold, classes.textAlignRight)}>
        {item?.arrivalTime}
      </Box>
      <Box className={classes.center}>
        <IconLocationBlue />
      </Box>
      <Box className={classes.title}>{`${
        airPortsInfo?.find((ele) => ele?.code === item?.arrivalAirport)?.name
      } (${item?.arrivalAirport})`}</Box>
      {/*5*/}
      <Box
        className={clsx(classes.grayText, classes.textAlignRight)}
        sx={{ padding: '4px 0' }}
      >
        {moment(item?.arrivalDate, DATE_FORMAT).format('DD [thg] MM')}
      </Box>
      <Box className={classes.center}></Box>
      <Box sx={{ padding: '4px 0' }}></Box>
    </Box>
  );
};
export default WayFlightItemDetail;
