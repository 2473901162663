import FlightBooking from 'modules/desktop/flight/Booking';
import MobileFlightBooking from 'modules/mobile/flight/Booking';
import MobileTrainBooking from 'modules/mobile/train/Booking';
import MobilePhoneCardBooking from 'modules/mobile/phoneCard/Booking';
import TransactionBooking from 'modules/desktop/transaction/Booking';
import MobileTransactionBooking from 'modules/mobile/transaction/Booking';
import HotelConfirmTA from 'modules/desktop/hotel/HotelConfirmTA';
import MobileHotelConfirmTA from 'modules/mobile/hotel/MobileHotelConfirmTA';
import HotelConfirmTAMessages from 'modules/desktop/hotel/HotelConfirmTAMessages';
import MobileHotelConfirmTAMessages from './../modules/mobile/hotel/MobileHotelConfirmTAMessages';
import HotelConfirmRequestBooking from 'modules/desktop/hotel/HotelConfirmRequestBooking';

export const routes = {
  HOME: '/',
  NOT_FOUND: '/not-found',
  FLIGHT_BOOKING_CHECKOUT: '/payment/flight/:bookingId',
  TRAIN_BOOKING_CHECKOUT: '/payment/train/:bookingId',
  PHONE_CARD_BOOKING_CHECKOUT: '/payment/phoneCard/:bookingId',
  TRANSACTION_CHECKOUT: '/payment/transaction/:bookingId',
  HOTEL_CONFIRM_TA_EMAIL: '/hotel/confirm_ta_email/:bookingHash',
  HOTEL_CONFIRM_TA_MESSAGES: '/hotel/confirm_ta_messages/:bookingHash',
  HOTEL_CONFIRM_REQUEST_BOOKING: '/hotel/confirm-request-booking/:status',
};

export const MENU = [
  {
    name: 'FLIGHT_BOOKING',
    route: routes.FLIGHT_BOOKING_CHECKOUT,
    component: FlightBooking,
    mobileComponent: MobileFlightBooking,
  },
  {
    name: 'TRAIN_BOOKING',
    route: routes.TRAIN_BOOKING_CHECKOUT,
    component: MobileTrainBooking,
    mobileComponent: MobileTrainBooking,
  },
  {
    name: 'PHONE_CARD_BOOKING',
    route: routes.PHONE_CARD_BOOKING_CHECKOUT,
    component: MobilePhoneCardBooking,
    mobileComponent: MobilePhoneCardBooking,
  },
  {
    name: 'TRANSACTION',
    route: routes.TRANSACTION_CHECKOUT,
    component: TransactionBooking,
    mobileComponent: MobileTransactionBooking,
  },
  {
    name: 'CONFIRM_TA',
    route: routes.HOTEL_CONFIRM_TA_EMAIL,
    component: HotelConfirmTA,
    mobileComponent: MobileHotelConfirmTA,
  },
  {
    name: 'CONFIRM_TA_MESSAGES',
    route: routes.HOTEL_CONFIRM_TA_MESSAGES,
    component: HotelConfirmTAMessages,
    mobileComponent: MobileHotelConfirmTAMessages,
  },
  {
    name: 'HOTEL_CONFIRM_REQUEST_BOOKING',
    route: routes.HOTEL_CONFIRM_REQUEST_BOOKING,
    component: HotelConfirmRequestBooking,
    mobileComponent: HotelConfirmRequestBooking,
  },
];
