import { createTheme } from '@mui/material/styles';

export const BLACK = '#000000';
export const BLUE = '#276ef1';
export const GREEN = '#00ad50';
export const GREY = '#212121';
export const GREY_400 = '#BDBDBD';
export const GREY_900 = '#212121';
export const PRIMARY = '#000000';
export const SECONDARY = '#000000';
export const WHITE = '#ffffff';
export const ORANGE = '#ff6a39';
export const PINK_MT = '#FF3366';
// ThemedStyleSheet.registerTheme({
//   reactDates: {
//     color: {
//       selected: {
//         backgroundColor: PRIMARY,
//         backgroundColor_active: PRIMARY,
//         backgroundColor_hover: PRIMARY,
//         borderColor: PRIMARY,
//         borderColor_active: PRIMARY,
//         borderColor_hover: PRIMARY,
//       },
//       selectedSpan: {
//         backgroundColor: fade(PRIMARY, 0.2),
//         backgroundColor_active: fade(PRIMARY, 0.2),
//         backgroundColor_hover: fade(PRIMARY, 0.2),
//         borderColor: fade(PRIMARY, 0.2),
//         borderColor_active: fade(PRIMARY, 0.2),
//         borderColor_hover: fade(PRIMARY, 0.2),
//       },
//       hoveredSpan: {
//         backgroundColor: fade(PRIMARY, 0.2),
//         backgroundColor_active: fade(PRIMARY, 0.2),
//         backgroundColor_hover: fade(PRIMARY, 0.2),
//         borderColor: fade(PRIMARY, 0.2),
//         borderColor_active: fade(PRIMARY, 0.2),
//         borderColor_hover: fade(PRIMARY, 0.2),
//       },
//     },
//   },
// });

export const THEME = {
  primary: PRIMARY,
  secondary: SECONDARY,
};

export const MUI_THEME = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        text: {
          textTransform: 'unset',
          fontWeight: 400,
        },
      },
    },
  },
  palette: {
    white: {
      main: '#ffffff',
      white1: '#fdfdfd',
      white2: '#fefefe',
    },
    black: {
      main: '#000000',
      black1: '#1d1d16',
      black2: '#333333',
      black3: '#1a202c',
    },
    gray: {
      grayDark1: '#16161d',
      grayDark2: '#354052',
      grayDark3: '#555555',
      grayDark4: '#aaaaaa',
      grayDark5: '#cccccc',
      grayDark6: '#f6f6f8',
      grayDark7: '#718096',
      grayDark8: '#4A5568',
      grayLight1: '#7f7f83',
      grayLight2: '#666666',
      grayLight3: '#6a6a6a',
      grayLight4: '#ededed',
      grayLight5: '#f9f9f9',
      grayLight6: '#888888',
      grayLight7: '#fffafa',
      grayLight8: '#eeeeee',
      grayLight9: '#d8d8d8',
      grayLight10: '#f0f7ff',
      grayLight11: '#f9f9fb',
      grayLight12: '#fef1f1',
      grayLight13: '#f7f7f7',
      grayLight14: '#fafcff',
      grayLight15: '#e1e1e1',
      grayLight16: '#f2f5f8',
      grayLight17: '#e6e6e6',
      grayLight18: '#979797',
      grayLight19: '#757575',
      grayLight20: '#2ebcf0',
      grayLight21: '#b5bcc8',
      grayLight22: '#EDF2F7',
      grayLight23: '#E2E8F0',
      grayLight24: '#A0AEC0',
      grayLight25: '#cbd5e0',
      grayLight26: '#F7FAFC',
      grayLight27: '#F1F2F4',
      grayLight28: '#C4C4C4',
      grayLight29: '#ecf1f6',
    },
    red: {
      redDark1: '#d70f0f',
      redDark2: '#de0262',
      redDark3: '#e01702',
      redDark4: '#f0282b',
      redDark5: '#E5322B',
      redLight1: '#e13955',
      redLight2: '#bb0253',
      redLight3: '#fff0f0',
      redLight4: '#feecec',
      redLight5: '#E53E3E',
      redLight6: '#FCECEC',
    },
    blue: {
      blueDark1: '#542aa8',
      blueDark2: '#0f60bd',
      blueDark3: '#0070f3',
      blueDark4: '#002957',
      blueDark5: '#0090c4',
      blueLight1: '#00adef',
      blueLight2: '#0070ef',
      blueLight3: '#2e8af1',
      blueLight4: '#0066da',
      blueLight5: '#24c2ff',
      blueLight6: '#bfddfe',
      blueLight7: '#1778f2',
      blueLight8: '#00B6F3',
      blueLight9: '#E5F8FE',
      blueLight10: '#CCF0FD',
      blueLight11: '#F3FCFF',
      blueLight12: '#007FF3',
      blueLight13: '#F2FBFE',
      blueLight14: '#F4F8FA',
      blueLight15: '#EBF9FE',
      blue1: '#42b8f3',
    },
    green: {
      greenDark1: '#4caf50',
      greenLight1: '#139029',
      greenLight2: '#18b934',
      greenLight3: '#f1fdf4',
      greenLight4: '#d4e8ff',
      greenLight5: '#d2f9d9',
      greenLight6: '#64AF53',
      greenLight7: '#48BB78',
      greenLight8: '#EDF8F1',
    },
    yellow: {
      yellowDark1: '#c7711a',
      yellowLight1: '#e4892d',
      yellowLight2: '#fcf2e8',
      yellowLight3: '#FFBC39',
    },
    orange: {
      main: '#ED8936',
      orangeLight1: '#FEEBC8',
      orangeLight2: '#FDF3EB',
    },
    pink: {
      main: '#FF1284',
      pink1: '#FFE7F3',
      pink2: '#FFF3F9',
    },
    purple: {
      main: '#6260DF',
      purpleLight1: '#EFEFFC',
      purpleLight2: '#8B5CF6',
      purpleLight3: '#F9F7FF',
    },
    brown: {
      brownLight1: '#D59E2A',
    },
    primary: {
      main: '#00B6F3',
    },
    secondary: {
      main: '#FF3366',
      background: '#FFD6E0',
      background1: 'rgba(255, 51, 102, 0.1)',
    },
    error: {
      main: '#E5322B',
    },
    background: {
      default: '#fff',
    },
  },
});
