import { Box, SwipeableDrawer } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatMoneyCurrency } from 'utils/helpersDataHotel';

const useStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    textAlign: 'center',
    fontSize: '14px',
    lineHeight: '17px',
    color: theme.palette.black.black3,
  },
  title: {
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '21px',
    color: theme.palette.black.black3,
  },
  passengerAncillariesContainer: {
    '&:not(:last-child)': {
      marginBottom: '16px',
      borderBottom: `1px solid ${theme.palette.gray.grayLight22}`,
    },
  },
  passengerName: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '17px',
    textTransform: 'uppercase',
    color: theme.palette.black.black3,
  },
  ancillaryContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 2fr',
    margin: '12px 0',
  },
  text: {
    fontSize: '14px',
    lineHeight: '16px',
    color: theme.palette.black.black3,
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
  },
  foodImg: {
    width: 48,
    height: 48,
    objectFit: 'cover',
    borderRadius: 8,
    marginRight: '8px',
  },
  ticketBtnSelected: {},
  ticketBtn: {
    background: theme.palette.gray.grayLight22,
    borderRadius: '8px',
    fontSize: '16px',
    lineHeight: '20px',
    textAlign: 'center',
    color: theme.palette.black.black3,
    border: 'none',
    padding: '8px 16px',
    cursor: 'pointer',
    transition: 'all 0.3s',
    '&$ticketBtnSelected': {
      background: theme.palette.secondary.main,
      color: '#FFFFFF',
    },
  },
  drawer: {
    zIndex: '2000 !important',
  },
  paper: {
    borderRadius: '16px 16px 0px 0px',
    padding: '0 16px',
  },
  swipe: {
    backgroundColor: '#E2E8F0',
    borderRadius: 100,
    width: '42px',
    height: '5px',
    margin: '0 auto',
    marginTop: 8,
  },
  drawerTitle: {
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '19px',
    color: '#1A202C',
    marginTop: '12px',
    paddingBottom: 12,
    width: '100%',
    borderBottom: `1px solid ${theme.palette.gray.grayLight22}`,
    marginBottom: 16,
  },
}));

const PassengerAncillaries = ({
  ancillaries = [],
  passenger = {},
  ticketId,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const passengerAncillaries = ancillaries.filter(
    (item) =>
      item?.guestId === passenger?.id &&
      (!item?.ticketId || item?.ticketId === ticketId)
  );

  if (!passengerAncillaries.length) return null;

  const getItemAncillary = (ancillary) => {
    switch (ancillary.type) {
      case 'MEAL':
        return (
          <>
            <Box className={classes.text}>
              {t(`IDS_ANCILLARY_${ancillary.type}`)}
            </Box>
            <Box className={classes.row} sx={{ alignItems: 'start' }}>
              <img src={ancillary?.images} alt="" className={classes.foodImg} />
              <Box>
                <Box className={classes.text} sx={{ marginBottom: '2px' }}>
                  {ancillary.title}
                </Box>
                <Box className={classes.row}>
                  <Box className={classes.text}>x{ancillary.quantity}</Box>
                  <Box sx={{ color: (theme) => theme.palette.grey[500] }}>
                    •
                  </Box>
                  <Box className={classes.text}>
                    {`${formatMoneyCurrency(ancillary.totalAmount)}`}
                  </Box>
                </Box>
              </Box>
            </Box>
          </>
        );
      case 'SEAT':
      case 'BAGGAGE':
      case 'COVID_TEST':
      default:
        return (
          <>
            <Box className={classes.text}>
              {t(`IDS_ANCILLARY_${ancillary.type}`)}
            </Box>
            <Box className={classes.row}>
              <Box className={classes.text}>{ancillary.title}</Box>
              <Box sx={{ color: (theme) => theme.palette.grey[500] }}>•</Box>
              <Box className={classes.text}>
                {`${formatMoneyCurrency(ancillary.totalAmount)}`}
              </Box>
            </Box>
          </>
        );
    }
  };

  return (
    <Box className={classes.passengerAncillariesContainer}>
      <Box
        className={classes.passengerName}
      >{`${passenger?.lastName} ${passenger?.firstName}`}</Box>
      <Box>
        {passengerAncillaries.map((item) => (
          <Box
            key={`${item.title}${item.guestId}`}
            className={classes.ancillaryContainer}
          >
            {getItemAncillary(item)}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

const AncillariesDetailDrawer = ({
  ancillaries = [],
  open,
  onClose = () => {},
  passengers = [],
  tickets = [],
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [ticketId, setTicketId] = useState(tickets?.[0]?.id);

  return (
    <SwipeableDrawer
      disableSwipeToOpen
      anchor="bottom"
      open={open}
      onClose={onClose}
      classes={{ root: classes.drawer, paper: classes.paper }}
    >
      <Box className={classes.swipe} />
      <Box className={classes.drawerTitle}>
        {t('IDS_TEXT_ORDER_ANCILLARIES')}
      </Box>
      {tickets?.length > 1 && (
        <Box className={classes.row} mb={2}>
          {tickets?.map((ticket) => (
            <button
              key={ticket.id}
              onClick={() => setTicketId(ticket.id)}
              className={clsx(
                ticket.id === ticketId && classes.ticketBtnSelected,
                classes.ticketBtn
              )}
            >{`${ticket.departureAirport}-${ticket.arrivalAirport}`}</button>
          ))}
        </Box>
      )}
      {passengers?.map((passenger) => (
        <PassengerAncillaries
          key={passenger?.id}
          passenger={passenger}
          ancillaries={ancillaries}
          ticketId={ticketId}
        />
      ))}
    </SwipeableDrawer>
  );
};

export default AncillariesDetailDrawer;
