import LoadingIcon from 'modules/common/LoadingIcon';
import ProtectedRoute from 'modules/common/ProtectedRoute';
import DesktopMainLayout from 'modules/desktop/layout/MainLayout';
import MobileMainLayout from 'modules/mobile/layout/MainLayout';
import React, { useEffect } from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { Redirect, Switch, useLocation } from 'react-router-dom';
import { Flip, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { GTM_DOMAIN, GTM_ID } from 'utils/gtm';
import { getPlatform } from 'utils/helpers';
import i18n from 'utils/i18n';
import { routes } from 'utils/routes';
import './App.scss';
import { IS_DINOGO } from 'utils/constants';

const App = (props) => {
  const platform = getPlatform();
  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.innerHTML = `
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        '${GTM_DOMAIN}/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer', '${GTM_ID}');
    `;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const lang = IS_DINOGO ? 'en' : params.get('lang') || 'vi';

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang]);

  useEffect(() => {
    document.title = IS_DINOGO ? 'Dinogo' : 'VNTravel Group';
  }, []);
  
  const isWebsite = platform === 'website';

  return (
    <>
      <ToastContainer
        autoClose={1500}
        transition={Flip}
        position={isWebsite ? 'top-right' : 'top-center'}
      />
      <React.Suspense fallback={<LoadingIcon />}>
        <Switch>
          <Redirect exact from="/" to={routes.HOME} />
          {/* <Route exact path={routes.NOT_FOUND} component={NotFound} /> */}
          <ProtectedRoute
            path="*"
            component={isWebsite ? DesktopMainLayout : MobileMainLayout}
          />
        </Switch>
      </React.Suspense>
    </>
  );
};

export default App;
