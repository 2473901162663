import { Box, ButtonBase, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { GREY_300, WHITE } from 'assets/theme/colors';
import clsx from 'clsx';
import { IcCopyCrypto } from 'public/icons';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { isEmpty } from 'utils/helpers';
import { formatMoneyCurrency } from 'utils/helpersDataHotel';

const useStyles = makeStyles((theme) => ({
  boxCode: {
    background: WHITE,
    borderRadius: 20,
    display: 'flex',
    justifyContent: 'space-around',
    textAlign: 'center',
    alignItems: 'center',
    padding: '24px 0',
  },
  copy: {
    display: 'flex',
    color: 'rgba(255, 51, 102, 1) !important',
    alginItems: 'center',
    fontSize: '14px',
    lineHeight: '17px',
    gap: '4px',
  },
  branchTextWrap: {
    whiteSpace: "break-spaces",
    fontWeight: '600',
    display: "flex",
    alignItems: "center",
  },
  branchTextAmount: {
    color: "rgba(255, 51, 102, 1)",
    fontSize: 16,
  },
  iconBank: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  bankItem: {
    borderRadius: 4,
    border: `1px solid ${GREY_300}`,
    height: 24,
    width: 48,
    backgroundColor: WHITE,
  },
  wrapBank: {
    display: 'flex',
    // alignItems: 'center',
    justifyContent: 'space-between',
    whiteSpace: 'nowrap',
    fontSize: 14,
    lineHeight: '17px',
    marginTop: 12,
  },
  bankText: {
    fontWeight: 600,
    maxWidth: 220,
  },
  divider: {
    borderBottom: `1px solid ${theme.palette.gray.grayLight23}`,
    width: '100%',
    marginTop: 12,
  },
  flightBankTransferContainer: {
    background: '#F7FAFC',
    padding: 12,
    marginTop: '16px',
    marginBottom: 0,
    border: '1px dashed #CBD5E0',
    borderRadius: 8,
  },
  title: {
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '17px',
    color: theme.palette.black.black3,
  },
  info: {
    fontSize: '14px',
    lineHeight: '17px',
    color: theme.palette.black.black3,
  },
  rightInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    gap: '4px',
  },
  imgOrder: {},
  bank: {
    display: 'flex',
    justifyContent: 'flex-end',
    alginItems: 'center',
  },
  infoBank: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 600,
    maxWidth: 161,
    fontSize: '14px',
    lineHeight: '17px',
    color: theme.palette.black.black3,
  },
  qrCode: {
    display: 'flex',
    justifyContent: 'center',
    padding: '12px 0px',
  },
  payAddressText: {
    whiteSpace: 'break-spaces',
    wordBreak: 'break-all',
    width: '88%',
  },
}));

const FlightCryptoTransfer = ({ paymentStatusDetail = {} }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleCoppyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    toast.success(t('IDS_COPY_SUCCESS'));
  };

  useEffect(() => {
    const qrElement = document.getElementById('qrcode');
    if (
      qrElement &&
      qrElement.children.length === 0 &&
      !isEmpty(paymentStatusDetail?.cryptoTransferInfo)
    ) {
      new window.QRCode(qrElement, {
        text:
          paymentStatusDetail?.cryptoTransferInfo?.payAddress || 'No Address',
        width: 167,
        height: 167,
      });
    }
  }, [paymentStatusDetail]);

  return (
    <Box className={classes.flightBankTransferContainer}>
      <Box className={classes.title}>{t('IDS_TEXT_PAYMENT_INFO')}</Box>
      <Box
        display="flex"
        justifyContent="space-between"
        mt={12 / 8}
        alignItems="center"
      >
        <Box className={classes.info}>{t('IDS_MT_TEXT_TOTAL')}</Box>
        <Box className={classes.rightInfo}>
          <Box className={clsx(classes.info, classes.bankText)}>
            {`${formatMoneyCurrency(
              paymentStatusDetail?.transactionInfo?.amount
            )}`}
          </Box>
        </Box>
      </Box>
      <Box className={classes.divider} />
      <Box
        display="flex"
        justifyContent="space-between"
        mt={12 / 8}
        alignItems="center"
      >
        <Box className={classes.info}>{t('IDS_TEXT_NETWORK')}</Box>
        <Box className={classes.rightInfo}>
          <Box className={clsx(classes.info, classes.bankText)}>
            {paymentStatusDetail?.cryptoTransferInfo?.network}
          </Box>
        </Box>
      </Box>
      <Box className={classes.divider} />
      <Box
        display="flex"
        justifyContent="space-between"
        mt={12 / 8}
        alignItems="center"
      >
        <Box className={classes.info}>
          <Box style={{ display: 'flex', flexDirection: 'column' }}>
            <div>{t('IDS_MT_TEXT_AMOUNT_MONEY')}</div>
            <div style={{ height: 5 }} />
            {!isEmpty(paymentStatusDetail?.cryptoTransferInfo) && (
              <div className={classes.branchTextWrap}>
                <img
                  src={paymentStatusDetail?.cryptoTransferInfo?.tokenLogo}
                  alt="tokenLogo"
                  width={16}
                  height={16}
                />
                <span className={classes.branchTextAmount}>
                  &nbsp;{paymentStatusDetail?.cryptoTransferInfo?.payAmount}
                </span>
                <span>
                  &nbsp;{paymentStatusDetail?.cryptoTransferInfo?.token}
                </span>
              </div>
            )}
          </Box>
        </Box>
        <Box className={classes.rightInfo}>
          <ButtonBase
            className={classes.copy}
            onClick={() => {
              handleCoppyToClipboard(
                paymentStatusDetail?.cryptoTransferInfo?.payAmount
              );
            }}
          >
            <IcCopyCrypto />
            {t('IDS_MT_TEXT_INFO_COPY')}
          </ButtonBase>{' '}
        </Box>
      </Box>
      <Box className={classes.divider} />
      <Box
        display="flex"
        justifyContent="space-between"
        mt={12 / 8}
        alignItems="center"
      >
        <Box className={classes.info}>
          <Box style={{ display: 'flex', flexDirection: 'column' }}>
            <div>{t('IDS_TEXT_WALLET_ADDRESS')}</div>
            <div style={{ height: 5 }} />
            {!isEmpty(paymentStatusDetail?.cryptoTransferInfo) && (
              <Typography
                variant="subtitle2"
                className={classes.payAddressText}
              >
                {paymentStatusDetail?.cryptoTransferInfo?.payAddress}
              </Typography>
            )}
          </Box>
        </Box>
        <Box className={classes.rightInfo}>
          <ButtonBase
            className={classes.copy}
            onClick={() => {
              handleCoppyToClipboard(
                paymentStatusDetail?.cryptoTransferInfo?.payAddress
              );
            }}
          >
            <IcCopyCrypto />
            {t('IDS_MT_TEXT_INFO_COPY')}
          </ButtonBase>
        </Box>
      </Box>
      <div className={classes.info} style={{ padding: '12px 0px' }}>
        {t('IDS_TEXT_OR_SCAN_THE_QR_CODE')}:
      </div>
      <div id="qrcode" className={classes.qrCode} />
    </Box>
  );
};

export default FlightCryptoTransfer;
