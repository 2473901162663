import React from 'react';
import { Route } from 'react-router';
import { withRouter } from 'react-router-dom';



const ProtectedRoute = (props) => {
  const { ...restProps } = props;
  // if (localStorage.getItem(TOKEN) && localStorage.getItem(CA_ID)) {
  return <Route {...restProps} />;
  // } else {
  //   localStorage.clear();
  //   return <Redirect to={{ pathname: routes.LOGIN }} />;
  // }
};

export default withRouter(ProtectedRoute);
