import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import { WHITE } from 'assets/theme/colors';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    backgroundColor: WHITE,
  },
  content: {
    display: 'flex',
    textAlign: 'center',
    paddingBottom: 4,
    fontSize: '14px',
    lineHeight: '17px',
    color: theme.palette.black.black3,
    marginBottom: 4,
  },
  contentBold: {
    fontWeight: 600,
  },
  title: {
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '21px',
    color: theme.palette.black.black3,
  },
  infoRow: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
  },
}));

const OrderContact = ({ item }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Box className={classes.container}>
      <Box className={classes.title}>{t('IDS_MT_TEXT_INFO_CONTACT')}</Box>
      <Box className={classes.infoRow}>
        <Box className={classes.content}>
          {t('IDS_MT_TEXT_CONTACT_NAME_SIMPLE')}:
        </Box>
        <Box className={clsx(classes.contentBold, classes.content)}>
          {item?.name?.toUpperCase()}
        </Box>
      </Box>
      <Box className={classes.infoRow}>
        <Box className={classes.content}>{t('IDS_MT_TEXT_PHONE_NUMBER')}:</Box>
        <Box className={clsx(classes.contentBold, classes.content)}>
          {item?.phone ? item.phone : null}
        </Box>
      </Box>
      <Box className={classes.infoRow}>
        <Box className={classes.content}>{t('IDS_MT_TEXT_EMAIL')}:</Box>
        <Box className={clsx(classes.contentBold, classes.content)}>
          {item?.email}
        </Box>
      </Box>
    </Box>
  );
};
export default OrderContact;
