import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import { GREEN, WHITE } from 'assets/theme/colors';
import LoadingIcon from 'modules/common/LoadingIcon';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  boxStatus: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  wrapContainer: {
    padding: '32px 0 0',
    position: 'relative',
  },
  container: {
    margin: 'auto',
    maxWidth: 630,
    height: 'auto',
    padding: '24px 0',
    background: WHITE,
    borderRadius: '20px 20px 0 0',
    borderTop: 'solid 4px',
    borderTopColor: GREEN,
  },
  desc: {
    fontSize: '14px',
    lineHeight: '22px',
    textAlign: 'center',
    padding: '0 16px',
    color: theme.palette.black.black3,
  },
}));

const BookingPending = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Box className={classes.wrapContainer}>
      <Box className={classes.container}>
        <Box className={classes.boxStatus}>
          <LoadingIcon />
          <Box className={classes.desc}>
            {t('IDS_TEXT_TRANSACTION_IN_PROGRESS')}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default BookingPending;
