/* eslint-disable no-nested-ternary */
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import { GREEN, RED, WHITE } from 'assets/theme/colors';
import { IconBookingFlightFail } from 'public/icons';
import { useTranslation } from 'react-i18next';
import { paymentTypes } from 'utils/constants';
import { isEmpty } from 'utils/helpers';

const useStyles = makeStyles((theme) => ({
  boxStatus: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  wrapContainer: {
    padding: '32px 0 0',
    position: 'relative',
  },
  container: {
    margin: 'auto',
    maxWidth: 630,
    height: 'auto',
    padding: '24px 0',
    background: WHITE,
    borderRadius: '20px 20px 0 0',
    borderTop: 'solid 4px',
  },
  mainStatus: {
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '19px',
    textAlign: 'center',
    color: theme.palette.black.black3,
    marginTop: '12px',
    marginBottom: '8px',
  },
  desc: {
    fontSize: '14px',
    lineHeight: '22px',
    textAlign: 'center',
    color: theme.palette.black.black3,
  },
}));

const BookingFail = ({ orderDetail = {} }) => {
  const paymentType = paymentTypes.FAIL;
  const classes = useStyles();
  const { t } = useTranslation();
  if (isEmpty(orderDetail)) return <div />;

  return (
    <Box className={classes.wrapContainer}>
      <Box
        className={classes.container}
        style={{
          borderTopColor: paymentType === paymentTypes.FAIL ? RED : GREEN,
        }}
      >
        <Box className={classes.boxStatus}>
          {paymentType === paymentTypes.FAIL ? (
            <>
              <IconBookingFlightFail />
              <Box className={classes.mainStatus}>{t('IDS_TEXT_FAIL')}</Box>
              <Box className={classes.desc}>
                {t('IDS_TEXT_FLIGHT_BOOKING_RESULT_DESC_FAIL')}
              </Box>
            </>
          ) : paymentType === paymentTypes.SUCCESS ? (
            <></>
          ) : paymentType === paymentTypes.SUCCESS ? (
            <></>
          ) : (
            <></>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default BookingFail;
