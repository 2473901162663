import { Box, Typography } from '@mui/material';
import Link from '@mui/material/Link';
import clsx from 'clsx';
import moment from 'moment';

import { IconMytourPoint, IconSightSeeing } from 'public/icons';
import { useTranslation } from 'react-i18next';
import { listIcons, prefixUrlIcon, TEST } from 'utils/constants';

import {
  filterTagsBest,
  filterTagsNormal,
  getLocation,
  isEmpty,
  stringSlug,
} from 'utils/helpers';
import { getMessageHotelReviews } from 'utils/helpersDataHotel';

import RankingHotel from './RankingHotelNew';

import NumberFormat from 'react-number-format';
import useStyles from './HotelNewCardStyles';

// done translated
const HotelNewCard = ({
  item = {},
  paramsInit = {},
  isCrossSale = false,
  isHomeStay = false,
}) => {
  const { t: trans } = useTranslation();
  const classes = useStyles();

  const pushEventGtmCrossSale = () => {
    if (isCrossSale) {
      // push gtm log
      // const informationUser = systemReducer?.informationUser || {};
      // let dataGtm = {
      //   ...gtm.getCrossSaleHotelToFlightTicket(informationUser),
      // };
      // gtm.addEventGtm(
      //   listEventFlight.cross_sale_flight_view_hotel_content,
      //   dataGtm
      // );
    }
  };

  if (isEmpty(paramsInit)) return <div />;

  const getLinkInfo = (paramsUrl) => {
    const baseUrl = TEST ? 'https://alpha.mytour.vn' : 'https://mytour.vn';
    return `${baseUrl}/khach-san/${item?.id}-${stringSlug(
      item?.name
    )}.html?checkIn=${paramsUrl?.checkIn}&checkOut=${
      paramsUrl?.checkOut
    }&adults=2&rooms=1&children=0`;
  };

  return (
    <Link
      href={getLinkInfo(paramsInit)}
      target="_blank"
      className={classes.linkHotelDetail}
      onClick={pushEventGtmCrossSale}
    >
      <Box className={classes.container}>
        <Box className={classes.topItem}>
          {/* thumbnail */}
          {!isEmpty(item?.thumbnail?.image?.origin) ? (
            <img
              src={item?.thumbnail?.image?.origin}
              className={classes.imageHotel}
              borderRadiusProp="8px 8px 0px 0px"
              alt={`Ảnh ${item?.name}`}
              title={`Ảnh ${item?.name}`}
            />
          ) : (
            <Box
              className={clsx(classes.imageHotel, classes.backgroundDefault)}
            />
          )}
          {/* discount */}
          {!isEmpty(item?.basePromotionInfo?.discountPercentage) &&
            !item.hiddenPrice &&
            item?.basePromotionInfo?.discountPercentage > 0 && (
              <Box className={classes.discountPercent}>
                <Box component="span" className={classes.discountPercentNumber}>
                  -{item?.basePromotionInfo.discountPercentage}
                  <Box component="span" fontSize={12}>
                    %
                  </Box>
                </Box>
              </Box>
            )}
          {/* tags Best*/}
          {!isEmpty(item.tags) &&
            // eslint-disable-next-line array-callback-return
            filterTagsBest(item.tags).map((el, index) => {
              if (index > 0) return null;
              if (
                el?.name?.trim()?.toUpperCase() ===
                'Best of the Best'?.trim()?.toUpperCase()
              ) {
                return (
                  <img
                    src={`${prefixUrlIcon}${listIcons.IconTagTheBest}`}
                    className={classes.tagSpecial}
                    alt=""
                  />
                );
              }
              if (
                el?.name.trim().toUpperCase() ===
                "Travellers' Choice".trim().toUpperCase()
              ) {
                return (
                  <img
                    src={`${prefixUrlIcon}${listIcons.IconTagTravellers}`}
                    className={classes.tagSpecial}
                    alt=""
                  />
                );
              }
            })}
          {/* tags Normal*/}
          <Box className={classes.wrapTags}>
            {!isEmpty(item.tags) &&
              filterTagsNormal(item.tags).map((el, index) => {
                if (index > 0) return null;
                return (
                  <Box className={classes.tags} key={index} title={el.name}>
                    {el.name}
                  </Box>
                );
              })}
            {!isEmpty(item.lastBookedTime) && //isShowStatus để check title là : Bán chạy, quan tâm, tìm kiếm nhiều
              moment().diff(moment(item.lastBookedTime)) <= 7200000 && (
                <Box
                  className={classes.wrapFlagTopHotel}
                  style={{ marginLeft: !isEmpty(item.tags) ? 5 : 0 }}
                >
                  {trans.IDS_MT_TEXT_TOP_SALE}
                </Box>
              )}
          </Box>
        </Box>
        <Box className={classes.bottomItem}>
          <Box>
            {/* name */}
            <Box className={classes.hotelName}>{item?.name}</Box>
            {/* star */}
            {!isEmpty(item.starNumber) && (
              <RankingHotel
                hotelStar={item.starNumber}
                wrapRating={classes.wrapRating}
                hotelCategory={item.category}
                styleMyPlus={classes.styleMyPlus}
                myPlus={item.myPlus}
                isOverlay
              />
            )}
            {/* location */}
            <Box className={classes.location}>
              <span style={{ marginRight: '4px' }}>
                <IconSightSeeing />
              </span>
              <Box component="span" className={classes.textLocation}>
                {getLocation(item?.address)}
              </Box>
            </Box>
            {/* rateLevel */}
            {!isEmpty(item?.rating?.hotel?.rating) && (
              <Box className={classes.wrapPoint}>
                <Box className={classes.point}>
                  <IconMytourPoint style={{ marginRight: 3 }} />
                  {2 * item?.rating?.hotel?.rating === 10
                    ? 10
                    : (2 * item?.rating?.hotel?.rating).toFixed(1)}
                </Box>
                <Box component="span" className={classes.review}>
                  {getMessageHotelReviews(item?.rating?.hotel?.rating, trans)}
                </Box>
                {item?.rating?.hotel?.count > 0 && (
                  <Box component="span" className={classes.rateLevel}>
                    ({item?.rating?.hotel?.count}
                    {trans('IDS_MT_TEXT_VOTE_VALUE')})
                  </Box>
                )}
              </Box>
            )}
            {/* ShowLastBook */}
            {/* {!isEmpty(item.lastBookedTime) &&
              moment().diff(moment(item.lastBookedTime)) <= 86400000 * 5 && (
                <Box className={classes.lastBooking}>
                  {trans.IDS_MT_TEXT_BOOKING_LAST_TIME}
                  <Box ml={2 / 8} component="span">
                    {moment(item.lastBookedTime / 5).from(
                      moment(moment().valueOf() / 5)
                    )}
                  </Box>
                </Box>
              )} */}
          </Box>
          {/* price */}
          <Box className={classes.wrapDiscountPriceFavorite}>
            {item.hiddenPrice ? (
              <>
                <Box display="flex" alignItems="center">
                  <Box component="span" className={classes.loginText}>
                    {trans.IDS_MT_TEXT_LOGIN_ACTION}
                  </Box>
                  <Typography
                    variant="subtitle1"
                    color="secondary"
                    style={{ marginLeft: 5 }}
                    component="span"
                  >
                    {`${trans.IDS_MT_TEXT_DISCOUNT_UPPERCASE} ${
                      !isEmpty(item?.basePromotionInfo)
                        ? `${item?.basePromotionInfo?.discountPercentage}%`
                        : '10%'
                    }`}
                  </Typography>
                </Box>
                <Box className={classes.discountMember} component="span">
                  {trans.IDS_MT_TEXT_DISCOUNT_MEMBER}
                </Box>
              </>
            ) : (
              <>
                {!isEmpty(item?.basePromotionInfo?.priceBeforePromotion) && (
                  <Box className={classes.discountSubPriceFavorite}>
                    <NumberFormat
                      value={item?.basePromotionInfo?.priceBeforePromotion}
                      displayType={'text'}
                      suffix={'đ'}
                      thousandSeparator={'.'}
                      decimalSeparator={','}
                    />
                  </Box>
                )}
                {!isEmpty(item?.basePrice) && (
                  <Box className={classes.discountPriceFavorite}>
                    <NumberFormat
                      value={item?.basePrice}
                      displayType={'text'}
                      suffix={'đ'}
                      thousandSeparator={'.'}
                      decimalSeparator={','}
                    />
                  </Box>
                )}
              </>
            )}
          </Box>
        </Box>
      </Box>
    </Link>
  );
};

export default HotelNewCard;
