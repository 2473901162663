/* eslint-disable no-nested-ternary */
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import { ORANGE, ORANGE_100, WHITE } from 'assets/theme/colors';
import LoadingIcon from 'modules/common/LoadingIcon';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  boxStatus: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  boxHolder: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: ORANGE_100,
    color: ORANGE,
    padding: '4px 8px',
    borderRadius: 4,
    marginBottom: 8,
  },
  container: {
    margin: 'auto',
    maxWidth: 630,
    height: 'auto',
    padding: '24px 0',
    background: WHITE,
    borderRadius: '20px 20px 0 0',
  },
  desc: {
    padding: '0 16px',
    fontSize: '14px',
    lineHeight: '22px',
    textAlign: 'center',
    color: theme.palette.black.black3,
  },
}));

const BookingPending = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box className={classes.container}>
      <Box className={classes.boxStatus}>
        <LoadingIcon style={{ height: 120 }} />
        <Box className={classes.desc}>
          {t('IDS_PENDING_TRANSACTION_WARNING')}
        </Box>
      </Box>
    </Box>
  );
};

export default BookingPending;
