import { Button, Divider, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import useHotelConfirmTA from 'modules/hooks/useHotelConfirmTA';
import { IconMytour } from 'public/icons';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import DetailBooking from './components/DetailBooking';
import HeaderMytour from './components/HeaderMytour';

const HotelConfirmTA = () => {
  const { briefBooking, confirmEmptyRoom, cancelEmptyRoom } =
    useHotelConfirmTA();
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Container maxWidth="lg">
        <Box sx={{ minHeight: '100vh', padding: '24px', color: '#0B0C0D' }}>
          <Box display="flex" justifyContent="space-between">
            <IconMytour />
            <HeaderMytour />
          </Box>
          <Divider
            style={{ backgroundColor: '#FF3366', height: 2, marginTop: 8 }}
          />
          <Box
            p={4}
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <Typography variant="h4">
              {t('IDS_MT_TEXT_CONFIRM_EMPTY_ROOM')}
            </Typography>
            <Box width="100%">
              <Box display="flex" alignItems="center">
                <Typography variant="body2">
                  {t('IDS_MT_TEXT_HOTEL_HI')}
                </Typography>
                &nbsp;
                <Typography variant="subtitle2">
                  {briefBooking?.hotelName},
                </Typography>
              </Box>
              <p>
                <Typography variant="body2">
                  {t('IDS_MT_TEXT_NOTI_HOTEL')}
                </Typography>
              </p>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body2">
                  {t('IDS_MT_TEXT_HOTEL_ACCESS')}
                </Typography>
                <Link
                  href={briefBooking?.bookingDetailUrl}
                  target="_blank"
                  rel="noopener"
                >
                  <Typography
                    variant="body2"
                    style={{ marginLeft: 4, color: '#004EBC' }}
                  >
                    {t('IDS_MT_TEXT_ACCESS_WEB')}
                  </Typography>
                </Link>
              </div>
            </Box>
            <DetailBooking briefBooking={briefBooking} />
            <Box mt={4}>
              <Typography variant="h6" style={{ fontWeight: 700 }}>
                {t('IDS_MT_TEXT_CONFIRM_EMPTY_ROOM')}
                <span
                  style={{
                    color: '#00B6F3',
                  }}
                >
                  {' '}
                  {briefBooking?.roomNightText}{' '}
                  <span
                    style={{
                      color: '#000000',
                    }}
                  >
                    {briefBooking?.numRoomNightText}
                  </span>
                </span>
              </Typography>
            </Box>
            <Box mt={5}>
              <Button
                variant="outlined"
                style={{
                  marginLeft: 48,
                  color: '#FF3366',
                  background: '#FFFFFF',
                  textTransform: 'none',
                }}
                onClick={cancelEmptyRoom}
              >
                {t('IDS_MT_TEXT_EMPTY_ROOM')}
              </Button>
              <Button
                variant="contained"
                style={{
                  marginLeft: 48,
                  color: '#FFFFFF',
                  background: '#FF3366',
                  textTransform: 'none',
                }}
                onClick={confirmEmptyRoom}
              >
                {t('IDS_MT_TEXT_CONFIRM_EMPTY_ROOM')}
              </Button>
            </Box>
            <Box mt={4} width="100%">
              <Divider
                style={{ backgroundColor: '#D9DBDC', height: 1, width: '100%' }}
              />
              <Box mt={1}>
                <Typography variant="body2">
                  {t('IDS_MT_TEXT_BEST_REGARDS')}
                </Typography>
                <Typography variant="body2">
                  {t('IDS_MT_TEXT_MT_TEAM')}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </React.Fragment>
  );
};
export default HotelConfirmTA;
