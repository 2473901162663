/* eslint-disable no-nested-ternary */
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import { WHITE } from 'assets/theme/colors';
import { IconBookingFlightSuccess } from 'public/icons';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  boxStatus: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  container: {
    margin: 'auto',
    maxWidth: 630,
    height: 'auto',
    padding: '24px 0 4px 0',
    background: WHITE,
    borderRadius: '20px 20px 0px 0px ',
  },
  mainStatus: {
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '19px',
    textAlign: 'center',
    color: theme.palette.black.black3,
    marginTop: '12px',
    marginBottom: '8px',
  },
}));

const BookingSucess = (props) => {
  const { successText = '' } = props;
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box className={classes.boxStatus}>
        <IconBookingFlightSuccess />
        <Box className={classes.mainStatus}>{successText}</Box>
      </Box>
    </Box>
  );
};

export default BookingSucess;
