import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { IconEmail, IconSupportCall } from 'public/icons';

const HeaderMytour = () => {
  return (
    <Box display="flex" flexDirection="column" alignItems='flex-end'>
      <Box display="flex">
        <IconSupportCall />
        <Typography style={{ marginLeft: 4, color: '#FF3366' }}>
          1900 2083
        </Typography>
      </Box>
      <Box display="flex">
        <IconEmail />
        <Typography style={{ marginLeft: 4, color: '#FF3366' }}>
          hotelsupport@mytour.vn
        </Typography>
      </Box>
    </Box>
  );
};
export default HeaderMytour;
