/* eslint-disable no-nested-ternary */
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import { RED, WHITE } from 'assets/theme/colors';
import { IconBookingFlightFail } from 'public/icons';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  boxStatus: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  wrapContainer: {
    padding: '32px 0 0',
    position: 'relative',
  },
  container: {
    margin: 'auto',
    maxWidth: 630,
    height: 'auto',
    padding: 24,
    background: WHITE,
    borderRadius: '8px 8px 0 0',
    borderTop: 'solid 4px',
    borderTopColor: RED,
  },
  mainStatus: {
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '19px',
    textAlign: 'center',
    color: theme.palette.black.black3,
    marginTop: '12px',
    marginBottom: '8px',
  },
  desc: {
    fontSize: '14px',
    lineHeight: '22px',
    textAlign: 'center',
    color: theme.palette.black.black3,
  },
  customerMessage: {
    fontSize: '16px',
    lineHeight: '22px',
    textAlign: 'center',
    padding: '8px 12px',
    marginTop: '8px',
    borderRadius: '8px',
    color: WHITE,
    backgroundColor: RED,
  },
}));

const BookingFail = ({ orderDetail }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box className={classes.wrapContainer}>
      <Box className={classes.container}>
        <Box className={classes.boxStatus}>
          <IconBookingFlightFail />
          <Box className={classes.mainStatus}>{t('IDS_TEXT_FAIL')}</Box>
          <Box className={classes.desc}>
            {t('IDS_TEXT_FLIGHT_BOOKING_RESULT_DESC_FAIL')}
          </Box>
          {!!orderDetail?.booking?.customerMessage && (
            <Box className={classes.customerMessage}>
              {orderDetail?.booking?.customerMessage}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default BookingFail;
