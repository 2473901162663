/* eslint-disable no-nested-ternary */
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { WHITE } from 'assets/theme/colors';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import {
  IS_MYTOUR,
  flightPaymentStatus,
  flyxBookingStatus,
} from 'utils/constants';
import { DATE_FORMAT_ALL_ISOLATE } from 'utils/moment';

const useStyles = makeStyles((theme) => ({
  boxName: {
    fontSize: '14px',
    lineHeight: '17px',
    color: theme.palette.black.black3,
  },
  orderInfo: {
    background: '#F7FAFC',
    border: '1px dashed #CBD5E0',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '2px',
    padding: '12px 0',
  },
  container: {
    padding: 16,
    backgroundColor: WHITE,
  },
  pinkCode: {
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '21px',
    color: theme.palette.secondary.main,
  },
  blueCode: {
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '21px',
    color: theme.palette.primary.main,
  },
  infoRow: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
  },
  content: {
    display: 'flex',
    textAlign: 'center',
    paddingBottom: 4,
    fontSize: '14px',
    lineHeight: '17px',
    color: theme.palette.black.black3,
    marginBottom: 4,
  },
}));

const OrderInfo = ({ orderDetail = {}, paymentStatus = {} }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const isTwoWay = orderDetail?.tickets?.length === 2;
  const bookingCode = orderDetail?.booking?.bookingCode;
  const orderStatus = orderDetail?.booking?.bookingStatus;
  const outBoundReservationCode = orderDetail?.tickets[0]?.reservationCode;
  const inBoundReservationCode = isTwoWay
    ? orderDetail?.tickets[1]?.reservationCode
    : null;
  // const paymentExpiredTime = orderDetail?.booking?.paymentExpiredTime;

  const hideReservationCode = !(
    IS_MYTOUR &&
    orderDetail?.booking?.bookingStatus === flyxBookingStatus.HOLDING
  );

  return (
    <Box className={classes.container}>
      <Box className={classes.infoRow}>
        <Box className={classes.content}>{t('IDS_MT_TEXT_DATE_BOOKING')}</Box>
        <Box className={classes.content}>
          {moment(orderDetail.booking?.createdTime).format(
            DATE_FORMAT_ALL_ISOLATE
          )}
        </Box>
      </Box>
      <Box
        sx={{
          display: !hideReservationCode ? 'block !important' : 'grib',
          gap: '16px',
          gridTemplateColumns: '1fr 1fr',
        }}
      >
        <Box className={classes.orderInfo}>
          <Box className={classes.boxName}>
            {t('IDS_TEXT_FLIGHT_ORDER_CODE')}
          </Box>
          <Box className={classes.blueCode}>{bookingCode}</Box>
        </Box>
        {hideReservationCode && (
          <Box className={classes.orderInfo}>
            {isTwoWay ? (
              <>
                <Box className={classes.boxName}>
                  {t('IDS_TEXT_FLIGHT_BOOK_CODE_OUTBOUND')}
                </Box>
                <Box className={classes.pinkCode}>
                  {outBoundReservationCode
                    ? orderStatus === flightPaymentStatus.REFUNDED ||
                      orderStatus === flightPaymentStatus.REFUNDED_POSTED
                      ? t('IDS_TEXT_ORDER_PAYMENT_STATUS_CANCELLED')
                      : outBoundReservationCode
                    : t('IDS_TEXT_ORDER_PAYMENT_STATUS_PROCESSING')}
                </Box>
                <Box className={classes.boxName} sx={{ marginTop: '4px' }}>
                  {t('IDS_TEXT_FLIGHT_BOOK_CODE_INBOUND')}
                </Box>
                <Box className={classes.pinkCode}>
                  {inBoundReservationCode
                    ? orderStatus === flightPaymentStatus.REFUNDED ||
                      orderStatus === flightPaymentStatus.REFUNDED_POSTED
                      ? t('IDS_TEXT_ORDER_PAYMENT_STATUS_CANCELLED')
                      : inBoundReservationCode
                    : t('IDS_TEXT_ORDER_PAYMENT_STATUS_PROCESSING')}
                </Box>
              </>
            ) : (
              <>
                <Box className={classes.boxName}>
                  {t('IDS_TEXT_FLIGHT_BOOK_CODE')}
                </Box>
                <Box className={classes.pinkCode}>
                  {outBoundReservationCode
                    ? orderStatus === flightPaymentStatus.REFUNDED ||
                      orderStatus === flightPaymentStatus.REFUNDED_POSTED
                      ? t('IDS_TEXT_ORDER_PAYMENT_STATUS_CANCELLED')
                      : outBoundReservationCode
                    : t('IDS_TEXT_ORDER_PAYMENT_STATUS_PROCESSING')}
                </Box>
              </>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default OrderInfo;
