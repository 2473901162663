import { makeStyles } from '@mui/styles';
import { IconStar } from "public/icons";
import Box from '@mui/material/Box';
import React from "react";

const useStyles = makeStyles((theme) => ({
  voteContainer: { display: "flex" },
  rankingHotel: { width: 14, height: 14, marginRight: 3 },
}));

// done translated
const Vote = ({ maxValue = 5, value = 0, colorStar = "#FFBC39" }) => {
  const classes = useStyles();

  return (
    <Box className={classes.voteContainer}>
      {Array(maxValue)
        .fill(0)
        .map((it, idx) => (
          <IconStar
            key={idx}
            className={`svgFillAll ${classes.rankingHotel}`}
            style={{
              stroke: idx >= value ? "#718096" : colorStar,
              fill: idx >= value ? "#718096" : colorStar,
            }}
          />
        ))}
    </Box>
  );
};

export default React.memo(Vote);
