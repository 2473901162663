/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */

import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { WHITE } from 'assets/theme/colors';
import clsx from 'clsx';
import { IconMoney } from 'public/icons';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  container: {
    margin: 'auto',
    maxWidth: 630,
    boxSizing: 'border-box',
    background: WHITE,
    borderRadius: '0px 0px 20px 20px',
    padding: 16,
    paddingBottom: 12,
  },
  line: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.palette.gray.grayLight22}`,
    padding: '12px 0',
  },
  infoText: {
    fontSize: 14,
    lineHeight: '16px',
    color: theme.palette.black.black3,
  },
  bold: {
    fontWeight: 600,
  },
  greenText: {
    color: theme.palette.green.greenLight7,
  },
  paymentMethodContainer: {},
  paymentMethodName: {
    marginTop: 4,
    borderRadius: 4,
    backgroundColor: theme.palette.gray.grayLight22,
    padding: 12,
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },
}));

const MobileTopupModule = (props) => {
  const { phoneCard } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box className={classes.container}>
      <Box className={classes.paymentMethodContainer}>
        <Box className={clsx(classes.infoText, classes.bold)}>
          {t('IDS_MT_TEXT_PAYMENT_MENTHODS')}
        </Box>
        <Box className={classes.paymentMethodName}>
          <IconMoney />
          <Box className={classes.infoText}>{phoneCard?.paymentMethod}</Box>
        </Box>
      </Box>
      <Box className={classes.line}>
        <Box className={classes.infoText}>{t('IDS_MT_DEPOSIT_AMOUNT')}</Box>
        <Box className={clsx(classes.infoText, classes.bold)}>
          {(phoneCard?.value || 0).toLocaleString('de-DE') + 'đ'}
        </Box>
      </Box>
      <Box className={classes.line}>
        <Box className={classes.infoText}>{t('IDS_MT_DISCOUNT')}</Box>
        <Box
          className={clsx(classes.infoText, classes.bold, classes.greenText)}
        >
          {(phoneCard?.discount || 0).toLocaleString('de-DE') + 'đ'}
        </Box>
      </Box>
      <Box className={classes.line}>
        <Box className={classes.infoText}>{t('IDS_MT_SURCHARGE')}</Box>
        <Box className={clsx(classes.infoText, classes.bold)}>
          {(phoneCard?.finalPrice - phoneCard?.totalPrice || 0).toLocaleString(
            'de-DE'
          ) + 'đ'}
        </Box>
      </Box>
      <Box className={classes.line}>
        <Box className={clsx(classes.infoText, classes.bold)}>
          {t('IDS_MT_TOTAL_MONEY')}
        </Box>
        <Box className={clsx(classes.infoText, classes.bold)}>
          {(phoneCard?.finalPrice || 0).toLocaleString('de-DE') + 'đ'}
        </Box>
      </Box>
    </Box>
  );
};

export default MobileTopupModule;
