import { Box, ButtonBase } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  BLUE_200,
  GREY_300,
  NEUTRAL_100,
  NEUTRAL_400,
  ORANGE,
  ORANGE_100,
  PINK_300,
  WHITE,
} from 'assets/theme/colors';
import clsx from 'clsx';
import { IconCopy } from 'public/icons';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { formatMoneyCurrency } from 'utils/helpersDataHotel';

const useStyles = makeStyles((theme) => ({
  boxTicket: {
    padding: '16px 16px 22px 16px',
    display: 'flex',
    flexDirection: 'column',
  },
  boxCode: {
    background: WHITE,
    borderRadius: 20,
    display: 'flex',
    justifyContent: 'space-around',
    textAlign: 'center',
    alignItems: 'center',
    padding: '24px 0',
  },
  boxHolder: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: ORANGE_100,
    color: ORANGE,
    padding: '4px 8px',
    borderRadius: 4,
    marginBottom: 8,
  },
  copy: {
    display: 'flex',
    color: '#00B6F3 !important',
    alginItems: 'center',
    fontSize: '14px',
    lineHeight: '17px',
    gap: '4px',
  },
  iconBank: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  bank: {
    fontSize: 14,
    lineHeight: '16px',
    fontWeight: 400,
  },
  bankItem: {
    borderRadius: 4,
    border: `1px solid ${GREY_300}`,
    height: 24,
    width: 48,
    backgroundColor: WHITE,
  },
  wrapBank: {
    display: 'flex',
    // alignItems: 'center',
    justifyContent: 'space-between',
    whiteSpace: 'nowrap',
    fontSize: 14,
    lineHeight: '17px',
    marginTop: 12,
  },
  bankText: {
    fontWeight: 600,
    maxWidth: 432,
  },
  infoBank: {
    borderBottom: `1px solid ${GREY_300}`,
    marginBottom: 12,
  },
  divider: {
    borderBottom: `1px solid ${theme.palette.gray.grayLight23}`,
    width: '100%',
  },
  wrapContainer: {
    padding: '32px 0 0',
    position: 'relative',
  },
  container: {
    margin: 'auto',
    maxWidth: 630,
    height: 'auto',
    padding: 24,
    background: WHITE,
    borderRadius: 8,
    borderTop: 'solid 4px',
  },
  wrapBorder: {
    display: 'flex',
    justifyContent: 'space-around',
    margin: 'auto',
    maxWidth: 630,
  },
  circle: {
    width: 16,
    height: 16,
    transform: 'rotate(-45deg)',
    borderRadius: '50%',
    borderLeftColor: 'transparent',
    borderBottomColor: 'transparent',
    background: GREY_300,
    marginTop: -8,
  },
  boxCodeInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: 12,
  },
  orderCode: {
    padding: '0 16px',
    borderRadius: 8,
    boxSizing: 'border-box',
    border: `1px dashed ${NEUTRAL_400}`,
    background: NEUTRAL_100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    textAlign: 'center',
    height: 84,
    width: '50%',
  },
  codeLeft: {
    color: BLUE_200,
    fontSize: 18,
    lineHeight: '22px',
  },
  codeRight: {
    fontSize: 18,
    lineHeight: '22px',
    color: PINK_300,
  },
  ticketItem: {
    width: '100%',
    padding: '16px 0',
  },
  boxDownload: {
    margin: '22px auto 30px',
    maxWidth: '630px',
    display: 'flex',
    justifyContent: 'center',
  },
  flightBankTransferContainer: {
    background: '#F7FAFC',
    border: '1px dashed #CBD5E0',
    borderRadius: '8px',
    padding: '16px 24px',
    marginTop: '16px',
    marginBottom: 0,
  },
  title: {
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '17px',
    color: theme.palette.black.black3,
  },
  info: {
    fontSize: '14px',
    lineHeight: '17px',
    color: theme.palette.black.black3,
  },
  rightInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    gap: '4px',
  },
  imgOrder: {},
  bankInfo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: '6px',
  },
}));

const FlightBankTransfer = ({
  bankTransferInfo = {},
  bankTransferOptions = [],
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const handleCoppyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    toast.success(t('IDS_COPY_SUCCESS'));
  };

  return (
    <Box className={classes.flightBankTransferContainer}>
      {bankTransferOptions?.[0]?.qrImage && (
        <Box display="flex" justifyContent="space-between" mt={12 / 8}>
          <Box className={classes.imgOrder}>
            <img
              src={bankTransferOptions?.[0]?.qrImage}
              alt=""
              style={{
                width: 214,
                height: 214,
                objectFit: 'cover',
                border: '1px solid #E2E8F0',
                borderRadius: 8,
              }}
            />
          </Box>
          <Box
            justifyContent="center"
            width="100%"
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            ml={3}
          >
            <Box fontSize="16px" fontWeight="600" width="100%">
              {t('IDS_TEXT_QR_CODE')}
            </Box>
            <Box fontSize="14px" color="#4A5568" mt={1 / 2}>
              {t('IDS_TEXT_QR_CODE_NOTE')}
            </Box>
          </Box>
        </Box>
      )}
      <Box className={classes.divider} mt={3 / 2} />
      <Box className={classes.title} mt={3 / 2}>
        {t('IDS_MT_TEXT_INFO_PAYMENT_TRANSFER')}
      </Box>
      {bankTransferOptions?.map((el, index) => (
        <Box key={`${el?.bankId}${el?.accountNumber}`}>
          <Box className={classes.wrapBank}>
            <Box className={classes.info}>
              {t('IDS_MT_TEXT_INFO_PAYMENT_TRANSFER_BANK')}
            </Box>
            <Box className={classes.bankInfo}>
              <Box className={clsx(classes.info, classes.bankText)}>
                {el?.bankName}
              </Box>
              <Box className={classes.bankItem}>
                <img src={el?.bankLogo} className={classes.iconBank} alt="" />
              </Box>
            </Box>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            mt={12 / 8}
            mb={12 / 8}
          >
            <Box className={classes.info}>
              {t('IDS_MT_TEXT_INFO_PAYMENT_TRANSFER_NUMBER_ACCOUNT')}
            </Box>
            <Box className={classes.rightInfo}>
              <Box className={clsx(classes.info, classes.bankText)}>
                {el?.accountNumber}
              </Box>
              <ButtonBase
                className={classes.copy}
                onClick={() => {
                  handleCoppyToClipboard(el?.accountNumber);
                }}
              >
                <IconCopy />
                {t('IDS_MT_TEXT_INFO_COPY')}
              </ButtonBase>
            </Box>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            mt={12 / 8}
            mb={12 / 8}
          >
            <Box className={classes.info}>{t('IDS_ACCOUNT_NAME')}</Box>
            <Box
              className={classes.info}
              sx={{ maxWidth: '60%', fontWeight: 600 }}
            >
              {el?.accountName}
            </Box>
          </Box>
        </Box>
      ))}
      <Box className={classes.divider} />
      <Box display="flex" justifyContent="space-between" mt={12 / 8}>
        <Box className={classes.info}>{t('IDS_MT_TEXT_AMOUNT_MONEY')}</Box>
        <Box className={classes.rightInfo}>
          <Box className={clsx(classes.info, classes.bankText)}>
            {`${formatMoneyCurrency(bankTransferInfo?.totalAmount)}`}
          </Box>
          <ButtonBase
            className={classes.copy}
            onClick={() => {
              handleCoppyToClipboard(
                `${formatMoneyCurrency(bankTransferInfo?.totalAmount || 0)}`
              );
            }}
          >
            <IconCopy />
            {t('IDS_MT_TEXT_INFO_COPY')}
          </ButtonBase>
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between" mt={12 / 8}>
        <Box className={classes.info}>{t('IDS_MT_TEXT_CONTENT_TRANSFER')}</Box>
        <Box className={classes.rightInfo}>
          <Box className={clsx(classes.info, classes.bankText)}>
            {bankTransferInfo?.message}
          </Box>
          <ButtonBase
            className={classes.copy}
            onClick={() => {
              handleCoppyToClipboard(bankTransferInfo?.message);
            }}
          >
            <IconCopy />
            {t('IDS_MT_TEXT_INFO_COPY')}
          </ButtonBase>
        </Box>
      </Box>
    </Box>
  );
};

export default FlightBankTransfer;
