import { Box, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { formatMoneyCurrency } from 'utils/helpersDataHotel';

const useStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    textAlign: 'center',
    paddingBottom: 4,
    fontSize: '14px',
    lineHeight: '17px',
    color: theme.palette.black.black3,
    marginBottom: 4,
  },
  contentBold: {
    fontWeight: 600,
  },
  font18: {
    fontSize: 18,
    lineHeight: '22px',
  },
  title: {
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '21px',
    color: theme.palette.black.black3,
  },
}));

const OrderPaymentInfo = ({ payment = {}, paymentStatus = {} }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Grid container sx={{ alignItems: 'baseline' }}>
      <Grid item lg={3} md={3} sm={3} xs={3}>
        <Box className={classes.title}>{t('IDS_MT_TEXT_PAYMENT_ACTION')}</Box>
      </Grid>
      <Grid item lg={3} md={3} sm={3} xs={3}>
        <Box className={classes.content}>
          {t('IDS_TEXT_ORDER_PAYMENT_TOTAL_PRICE')}:
        </Box>
        <Box className={classes.content}>
          {t('IDS_MT_TEXT_PAYMENT_MENTHODS')}:
        </Box>
      </Grid>
      <Grid item lg={6} md={6} sm={6} xs={6}>
        <Box className={classes.content}>
          {`${formatMoneyCurrency(payment?.totalAmount)}`}
        </Box>
        <Box className={classes.content}>{payment.paymentMethodName}</Box>
      </Grid>
    </Grid>
  );
};

export default OrderPaymentInfo;
