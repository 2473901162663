import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { WHITE } from 'assets/theme/colors';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { formatMoneyCurrency } from 'utils/helpersDataHotel';
import { DATE_FORMAT_BACK_END } from 'utils/moment';

const useStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    textAlign: 'center',
    paddingBottom: 4,
    fontSize: '14px',
    lineHeight: '17px',
    color: theme.palette.black.black3,
    marginBottom: 4,
  },
  title: {
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '21px',
    color: theme.palette.black.black3,
  },
  container: {
    width: '100%',
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    backgroundColor: WHITE,
  },
  infoRow: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
  },
}));

const OrderInsurance = ({ insurance = {} }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  // if (isEmpty(insuranceContact)) return null;
  return (
    <Box className={classes.container}>
      <Box className={classes.title}>{t('IDS_TEXT_ORDER_INSURANCE')}</Box>
      <Box className={classes.infoRow}>
        <Box className={classes.content}>
          {t('IDS_TEXT_ORDER_INSURANCE_PACKAGE')}:
        </Box>
        <Box className={classes.content}>{insurance?.packageName}</Box>
      </Box>
      <Box className={classes.infoRow}>
        <Box className={classes.content}>
          {t('IDS_TEXT_ORDER_INSURANCE_START')}:
        </Box>
        <Box className={classes.content}>
          {moment(insurance?.fromDate, DATE_FORMAT_BACK_END).format('L')}
        </Box>
      </Box>
      <Box className={classes.infoRow}>
        <Box className={classes.content}>
          {t('IDS_TEXT_ORDER_INSURANCE_END')}:
        </Box>
        <Box className={classes.content}>
          {moment(insurance?.toDate, DATE_FORMAT_BACK_END).format('L')}
        </Box>
      </Box>
      <Box className={classes.infoRow}>
        <Box className={classes.content}>{t('IDS_TEXT_PEOPLE_COUNT')}:</Box>
        <Box className={classes.content}>{insurance?.numPassenger}</Box>
      </Box>
      {!!insurance?.totalPrice && (
        <Box className={classes.infoRow}>
          <Box className={classes.content}>{t('IDS_TEXT_FINAL_PRICE')}:</Box>
          <Box className={classes.content}>
            {`${formatMoneyCurrency(insurance?.totalPrice)}`}
          </Box>
        </Box>
      )}
    </Box>
  );
};
OrderInsurance.propTypes = {
  insuranceContact: PropTypes.object,
  insuranceInfo: PropTypes.object,
  numberInsurance: PropTypes.number,
};
export default OrderInsurance;
