import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    paddingBottom: 4,
    fontSize: '14px',
    lineHeight: '17px',
    color: theme.palette.black.black3,
    marginBottom: 4,
  },
  contentBold: {
    fontWeight: 600,
  },
  font18: {
    fontSize: 18,
    lineHeight: '22px',
  },
  ellipsisText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '250px',
  },
  attachInvoice: {
    color: theme.palette.blue.blueLight8,
    display: 'flex',
  },
  title: {
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '21px',
    color: theme.palette.black.black3,
  },
}));

const OrderExportInvoice = ({ invoice = {} }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const renderLine = (textLeft, textRight) => {
    return (
      <Grid container>
        <Grid item lg={3} md={3} sm={3} xs={3}></Grid>
        <Grid item lg={3} md={3} sm={3} xs={3}>
          <Box className={classes.content}>{textLeft}:</Box>
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={6}>
          <Box className={classes.content}>{textRight}</Box>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <Grid container sx={{ alignItems: 'baseline' }}>
        <Grid item lg={3} md={3} sm={3} xs={3}>
          <Box className={classes.title}>{t('IDS_MT_TEXT_INFO_INVOICE')}</Box>
        </Grid>
        <Grid item lg={3} md={3} sm={3} xs={3}>
          <Box className={classes.content}>
            {t('IDS_TEXT_PLACEHOLDER_SEARCH_TAX_CODE')}:
          </Box>
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={6}>
          <Box className={classes.content}>{invoice?.taxNumber}</Box>
        </Grid>
      </Grid>
      {renderLine(t('IDS_TEXT_PLACEHOLDER_INVOICE_NAME'), invoice?.companyName)}
      {renderLine(
        t('IDS_TEXT_PLACEHOLDER_INVOICE_ADDRESS'),
        invoice?.companyAddress
      )}
      {renderLine(t('IDS_MT_TEXT_CUSTOMER_NAME'), invoice?.recipientName)}
      {renderLine(
        t('IDS_TEXT_PLACEHOLDER_INVOICE_RECEIVER'),
        invoice?.recipientAddress
      )}
      {renderLine(t('IDS_MT_TEXT_EMAIL'), invoice?.recipientEmail)}
    </>
  );
};

export default OrderExportInvoice;
