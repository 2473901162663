import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    textAlign: 'center',
    paddingBottom: 4,
    fontSize: '14px',
    lineHeight: '17px',
    color: theme.palette.black.black3,
    marginBottom: 4,
  },
  contentBold: {
    fontWeight: 600,
  },
  font18: {
    fontSize: 18,
    lineHeight: '22px',
  },
  title: {
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '21px',
    color: theme.palette.black.black3,
  },
}));

const OrderContact = ({ item }) => {
  const classes = useStyles();

  const { t } = useTranslation();

  return (
    <Grid container sx={{ alignItems: 'baseline' }}>
      <Grid item lg={3} md={3} sm={3} xs={3}>
        <Box className={classes.title}>{t('IDS_MT_TEXT_INFO_CONTACT')}</Box>
      </Grid>
      <Grid item lg={3} md={3} sm={3} xs={3}>
        <Box className={classes.content}>
          {t('IDS_MT_TEXT_CONTACT_NAME_SIMPLE')}:
        </Box>
        <Box className={classes.content}>{t('IDS_MT_TEXT_PHONE_NUMBER')}:</Box>
        <Box className={classes.content}>{t('IDS_MT_TEXT_EMAIL')}:</Box>
      </Grid>
      <Grid item lg={6} md={6} sm={6} xs={6}>
        <Box className={clsx(classes.contentBold, classes.content)}>
          {item?.name?.toUpperCase()}
        </Box>
        <Box className={clsx(classes.contentBold, classes.content)}>
          {item?.phone ? item.phone : null}
        </Box>
        <Box className={clsx(classes.contentBold, classes.content)}>
          {item?.email}
        </Box>
      </Grid>
    </Grid>
  );
};
export default OrderContact;
