import api from 'utils/api';
import * as constants from 'utils/constants';

export const searchHotTickets = (data) => {
  const option = {
    method: 'post',
    url: '/flights/v2/search-hot-tickets',
    data,
    noAuthentication: false,
    severVice: constants.FLIGHT_SERVICE,
  };
  return api(option);
};

export const searchAirports = (params) => {
  const option = {
    method: 'get',
    url: '/flights/searchAirports',
    params,
    noAuthentication: false,
    severVice: constants.FLIGHT_SERVICE,
  };
  return api(option);
};

export const searchOneWayTickets = (data) => {
  const option = {
    method: 'post',
    url: '/flights/searchOneDirectionTickets',
    data,
    noAuthentication: false,
    severVice: constants.FLIGHT_SERVICE,
  };
  return api(option);
};

export const searchRoundTripTickets = (data) => {
  const option = {
    method: 'post',
    url: '/flights/searchRoundTripTickets',
    data,
    noAuthentication: false,
    severVice: constants.FLIGHT_SERVICE,
  };
  return api(option);
};

export const getPaymentMethodData = (data) => {
  const option = {
    method: 'post',
    url: '/flight/getPaymentMethods',
    data,
    noAuthentication: false,
    severVice: constants.FLIGHT_SERVICE,
  };
  return api(option);
};

export const getGeneralInformation = (params = {}) => {
  const option = {
    method: 'get',
    params,
    url: '/flights/getGeneralInformation',
    noAuthentication: false,
    severVice: constants.FLIGHT_SERVICE,
  };
  return api(option);
};

export const getFlightBookingDetail = (params = {}) => {
  const option = {
    method: 'get',
    params,
    url: '/flyx/channels/public/bookings',
    noAuthentication: false,
    severVice: constants.PAYMENT_STATUS,
  };
  return api(option);
};

export const getTicketDetail = (data) => {
  const option = {
    method: 'post',
    url: '/flights/getTicketDetail',
    data,
    noAuthentication: false,
    severVice: constants.FLIGHT_SERVICE,
  };
  return api(option);
};

export const getVoucherListPayment = (data) => {
  const option = {
    method: 'post',
    url: '/account/getRewardsHistory',
    data,
    noAuthentication: false,
    severVice: constants.FLIGHT_SERVICE,
  };
  return api(option);
};

export const validateVoucherCode = (data) => {
  const option = {
    method: 'post',
    url: '/promotion/checkCode',
    data,
    noAuthentication: false,
    severVice: constants.FLIGHT_SERVICE,
  };
  return api(option);
};

export const getEnterpriseInfo = (data) => {
  const option = {
    method: 'post',
    url: '/utils/getEnterpriseInfo',
    data,
    noAuthentication: false,
    severVice: constants.FLIGHT_SERVICE,
  };
  return api(option);
};

export const getInsurancePackage = (data) => {
  const option = {
    method: 'post',
    url: '/insurance/getInsurancePackage',
    data,
    noAuthentication: false,
    severVice: constants.FLIGHT_SERVICE,
  };
  return api(option);
};

export const bookTicket = (data) => {
  const option = {
    method: 'post',
    url: '/flight/bookTicket',
    data,
    severVice: constants.FLIGHT_SERVICE,
  };
  return api(option);
};

export const getBookingInformation = (data) => {
  const option = {
    method: 'post',
    url: '/flight/getBookingInformation',
    data,
    noAuthentication: false,
    severVice: constants.FLIGHT_SERVICE,
  };
  return api(option);
};

export const displayPaymentResult = (data) => {
  const option = {
    method: 'post',
    url: '/smartlink/displayResult',
    data,
    noAuthentication: false,
    severVice: constants.FLIGHT_SERVICE,
  };
  return api(option);
};

export const getFlightBookings = (data) => {
  const option = {
    method: 'post',
    url: '/account/getFlightBookings',
    data,
    severVice: constants.FLIGHT_SERVICE,
  };
  return api(option);
};

export const getBankTransferInfo = (transferCode = '', params = {}) => {
  const option = {
    method: 'get',
    params,
    url: `/checkout/bankTransfer/${transferCode}`,
    noAuthentication: false,
    severVice: constants.FLIGHT_SERVICE,
  };
  return api(option);
};

export const getPaymentMethodOfHoldingBooking = (data) => {
  const option = {
    method: 'post',
    url: '/booker/getPaymentMethodOfHoldingBooking',
    data,
    noAuthentication: false,
    severVice: constants.FLIGHT_SERVICE,
  };
  return api(option);
};

export const repayForHoldingBooking = (data) => {
  const option = {
    method: 'post',
    url: '/booker/paymentForHoldingBookingV2',
    data,
    noAuthentication: false,
    severVice: constants.FLIGHT_SERVICE,
  };
  return api(option);
};

export const getAllCountries = (params) => {
  const option = {
    method: 'get',
    url: '/account/getAllCountries',
    params,
    severVice: constants.FLIGHT_SERVICE,
  };
  return api(option);
};

export const checkingPaymentStatus = (data = {}) => {
  const option = {
    method: 'post',
    url: 'payment/payments/status',
    data,
    severVice: constants.PAYMENT_STATUS,
  };
  return api(option);
};

export const checkingAgentsInfo = (data = {}) => {
  const option = {
    method: 'post',
    url: '/payment/agents/info',
    data,
    severVice: constants.PAYMENT_STATUS,
  };
  return api(option);
};

export const getAirLinesInfo = (params = {}) => {
  const option = {
    method: 'get',
    params,
    url: '/flyx/guru/airlines',
    noAuthentication: false,
    severVice: constants.PAYMENT_STATUS,
  };
  return api(option);
};

export const getAirPortsInfo = (params = {}) => {
  const option = {
    method: 'get',
    params,
    url: '/flyx/guru/airports',
    noAuthentication: false,
    severVice: constants.PAYMENT_STATUS,
  };
  return api(option);
};

export const getHotelsAvailability = (data = {}, headers = {}) => {
  const option = {
    method: 'post',
    url: '/hotels/v3/hotels/availability',
    data: {
      ...data,
      useBasePrice: true,
    },
    headers,
    severVice: constants.PAYMENT_STATUS,
  };

  return api(option);
};
