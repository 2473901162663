import { NEUTRAL_100 } from 'assets/theme/colors';
import { PageWrapper } from 'modules/common/Elements';
import LoadingIcon from 'modules/common/LoadingIcon';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { MENU } from 'utils/routes';

const MainLayout = (props) => {
  return (
    <PageWrapper style={{ background: NEUTRAL_100 }}>
      <main
        // className={classes.content}
        style={
          {
            // marginLeft: open ? SIDE_BAR_WIDTH : 73,
            // transition: 'linear 225ms',
          }
        }
      >
        <React.Suspense fallback={<LoadingIcon />}>
          <Switch>
            {MENU.map((item) => {
              return (
                <Route
                  exact
                  path={item.route}
                  component={item.component}
                  key={item.route}
                />
              );
            })}
          </Switch>
        </React.Suspense>
      </main>
    </PageWrapper>
  );
};

export default MainLayout;
