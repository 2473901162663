import { Grid, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import { GREY_100 } from 'assets/theme/colors';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CUSTOMER_TYPE_TEXT, GENDER_LIST } from 'utils/constants';
import CustomerDetailModalDesktop from './CustomerDetailModalDesktop';

const useStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    textAlign: 'center',
    paddingBottom: 4,
    fontSize: '14px',
    lineHeight: '17px',
    color: theme.palette.black.black3,
    marginBottom: 4,
  },
  contentBold: {
    fontWeight: 600,
  },
  font18: {
    fontSize: 18,
    lineHeight: '22px',
  },
  numGuest: {
    color: GREY_100,
  },
  ellipsisText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '250px',
  },
  title: {
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '21px',
    color: theme.palette.black.black3,
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
  },
}));

const OrderCustomerInfo = ({ guests = {}, isFromPayment = false }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [openViewDetail, setOpenViewDetail] = useState(false);
  const handleViewDetail = (open) => () => {
    setOpenViewDetail(open);
  };
  // let adultNo = 0;
  let childrenNo = 0;
  let infantNo = 0;
  return (
    <Grid container sx={{ alignItems: 'baseline' }}>
      <Grid item lg={3} md={3} sm={3} xs={3}>
        <Box className={classes.title}>
          {t('IDS_MT_TEXT_INFO_CUSTOMER_FLIGHT')}
        </Box>
        {!isFromPayment ? (
          <>
            <Button handleClick={handleViewDetail(true)}>
              <Typography component="span" variant="caption">
                {t('IDS_MT_TEXT_VIEW_DETAIL')}
              </Typography>
            </Button>
            <CustomerDetailModalDesktop
              open={openViewDetail}
              handleClose={handleViewDetail}
              guests={guests}
            />
          </>
        ) : null}
      </Grid>
      <Grid item lg={3} md={3} sm={3} xs={3}>
        <Box className={classes.column}>
          {guests.map((item, index) => {
            return (
              <Box className={classes.content} key={index}>
                {item.ageCategory.toLowerCase() === CUSTOMER_TYPE_TEXT.ADULT &&
                  `${t(
                    `${GENDER_LIST.find((el) => el.id === item?.gender)?.label}`
                  )}:`}
                {item.ageCategory.toLowerCase() ===
                  CUSTOMER_TYPE_TEXT.CHILDREN &&
                  `${t('IDS_MT_TEXT_CHILDREN')} ${++childrenNo}:`}
                {item.ageCategory.toLowerCase() === CUSTOMER_TYPE_TEXT.INFANT &&
                  `${t('IDS_MT_TEXT_BABY')} ${++infantNo}:`}
              </Box>
            );
          })}
        </Box>
      </Grid>
      <Grid item lg={6} md={6} sm={6} xs={6}>
        <Box className={classes.column}>
          {guests.length
            ? guests.map((item, index) => {
                return (
                  <Box className={classes.content} key={index}>
                    {item?.lastName.toUpperCase()}{' '}
                    {item?.firstName.toUpperCase()}
                  </Box>
                );
              })
            : null}
        </Box>
      </Grid>
    </Grid>
  );
};

export default OrderCustomerInfo;
