import { Button, Divider, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import useHotelConfirmTA from 'modules/hooks/useHotelConfirmTA';
import { IconMytour } from 'public/icons';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import DetailBooking from './components/DetailBooking';
import HeaderMytour from './components/HeaderMytour';

const MobileHotelConfirmTA = () => {
  const { briefBooking, confirmEmptyRoom, cancelEmptyRoom } =
    useHotelConfirmTA();
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Container maxWidth="lg">
        <Box sx={{ minHeight: '100vh', color: '#0B0C0D' }}>
          <Box display="flex" justifyContent="space-between" pt={2}>
            <IconMytour />
            <HeaderMytour />
          </Box>
          <Divider
            style={{ backgroundColor: '#FF3366', height: 1, marginTop: 16 }}
          />
          <Box
            py={2}
            display="flex"
            justifyContent="center"
            flexDirection="column"
          >
            <Typography variant="h5" style={{ fontWeight: 500 }}>
              {t('IDS_MT_TEXT_CONFIRM_EMPTY_ROOM')}
            </Typography>
            <Box width="100%" mt={2}>
              <Box display="flex" alignItems="center">
                <Typography variant="body2">
                  {t('IDS_MT_TEXT_HOTEL_HI')}
                </Typography>
                &nbsp;
                <Typography variant="subtitle2">
                  {briefBooking?.hotelName},
                </Typography>
              </Box>
              <p>
                <Typography variant="body2" style={{ marginTop: 4 }}>
                  {t('IDS_MT_TEXT_NOTI_HOTEL')}
                </Typography>
              </p>
              <div>
                <Typography variant="body2">
                  {t('IDS_MT_TEXT_HOTEL_ACCESS')}
                </Typography>
                <Link
                  href={briefBooking?.bookingDetailUrl}
                  target="_blank"
                  rel="noopener"
                >
                  <Typography variant="body2" style={{ marginLeft: 4 }}>
                    {t('IDS_MT_TEXT_ACCESS_WEB')}
                  </Typography>
                </Link>
              </div>
            </Box>
            <DetailBooking briefBooking={briefBooking} />
            <Box
              style={{
                marginTop: 16,
                display: 'flex',
                width: '100%',
                flexDirection: 'column',
              }}
            >
              <Box
                style={{
                  borderBottom: '2px solid rgba(117, 117, 117, 0.32)',
                }}
              >
                <Typography
                  variant="body2"
                  style={{
                    color: '#8C9497',
                  }}
                >
                  {t('IDS_MT_TEXT_HOTEL_STANDARD_GUEST')}
                </Typography>
                <Typography variant="body2" style={{}}>
                  {briefBooking?.totalStandardAdults}
                </Typography>
              </Box>
              <Box display="flex" my={1}>
                <Box style={{}}>
                  <Typography
                    variant="body2"
                    style={{
                      color: '#8C9497',
                    }}
                  >
                    {t('IDS_MT_TEXT_HOTEL_SUB_GUEST')}
                  </Typography>
                  <Typography variant="body2" style={{}}>
                    {briefBooking?.numberExtra}
                  </Typography>
                </Box>
                <Box
                  style={{
                    paddingLeft: 64,
                  }}
                >
                  <Typography
                    variant="body2"
                    style={{
                      color: '#8C9497',
                    }}
                  >
                    {t('IDS_MT_TEXT_HOTEL_TOTAL_GUEST')}
                  </Typography>
                  <Typography variant="body2" style={{}}>
                    {briefBooking?.totalNumberGuest}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Divider
              style={{ backgroundColor: '#D9DBDC', height: 1, width: '100%' }}
            />
            <Box m={4}>
              <Typography variant="h6" style={{ fontWeight: 700 }}>
                {t('IDS_MT_TEXT_CONFIRM_EMPTY_ROOM')}
                <span
                  style={{
                    color: '#00B6F3',
                  }}
                >
                  {' '}
                  {briefBooking?.roomNightText}{' '}
                  <span
                    style={{
                      color: '#000000',
                    }}
                  >
                    {briefBooking?.numRoomNightText}
                  </span>
                </span>
              </Typography>
            </Box>
            <Box display="flex">
              <Button
                variant="outlined"
                style={{
                  color: '#FF3366',
                  background: '#FFFFFF',
                  textTransform: 'none',
                  fontSize: 12,
                }}
                onClick={cancelEmptyRoom}
              >
                {t('IDS_MT_TEXT_EMPTY_ROOM')}
              </Button>
              <Button
                variant="contained"
                style={{
                  marginLeft: 16,
                  color: '#FFFFFF',
                  background: '#FF3366',
                  textTransform: 'none',
                  fontSize: 12,
                }}
                onClick={confirmEmptyRoom}
              >
                {t('IDS_MT_TEXT_CONFIRM_EMPTY_ROOM')}
              </Button>
            </Box>
            <Box mt={4} width="100%">
              <Divider
                style={{ backgroundColor: '#D9DBDC', height: 1, width: '100%' }}
              />
              <Box mt={1}>
                <Typography variant="body2">
                  {t('IDS_MT_TEXT_BEST_REGARDS')}
                </Typography>
                <Typography variant="body2">
                  {t('IDS_MT_TEXT_MT_TEAM')}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </React.Fragment>
  );
};
export default MobileHotelConfirmTA;
