import { checkingPaymentStatus } from 'api/flight';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import {
  LIST_SCREEN_STATUS,
  MAX_CALL_API_COUNT,
  paymentStatus,
  TIMEOUT_TIME,
} from 'utils/constants';

const useTransactionBooking = () => {
  const { bookingId } = useParams();
  const location = useLocation();
  const [screenStatus, setScreenStatus] = useState(LIST_SCREEN_STATUS.LOADING);
  const [hotelBooking, setHotelBooking] = useState();
  const fetchPaymentStatusTimeOutRef = useRef(0);

  useEffect(() => {
    let paymentFetchCount = 0;

    const handleSetTimeOutPaymentStatus = async () => {
      if (paymentFetchCount > MAX_CALL_API_COUNT) {
        setScreenStatus(LIST_SCREEN_STATUS.UNDEFINED);
      } else {
        fetchPaymentStatusTimeOutRef.current = setTimeout(
          fetchPaymentStatus,
          TIMEOUT_TIME
        );
      }
    };
    const fetchPaymentStatus = async () => {
      paymentFetchCount += 1;

      const { data: res } = await checkingPaymentStatus({
        gatewayParams: location?.search?.substring(1) || '',
        transactionId: bookingId,
      });

      if (res.code === 200) {
        const status = res.data?.transactionInfo?.status;
        setHotelBooking(res.data);
        if (status === paymentStatus.failed) {
          setScreenStatus(LIST_SCREEN_STATUS.FAILED);
        } else if (status === paymentStatus.settled) {
          setScreenStatus(LIST_SCREEN_STATUS.SUCCESS);
        } else if (
          status === paymentStatus.awaiting_payment ||
          status === paymentStatus.holding
        ) {
          handleSetTimeOutPaymentStatus();
        } else {
          setScreenStatus(LIST_SCREEN_STATUS.UNDEFINED);
        }
      }
    };

    if (bookingId) {
      fetchPaymentStatus();
    }

    return () => {
      if (fetchPaymentStatusTimeOutRef.current) {
        clearTimeout(fetchPaymentStatusTimeOutRef.current);
      }
    };
  }, [bookingId, location?.search]);

  return { screenStatus, hotelBooking };
};

export default useTransactionBooking;
