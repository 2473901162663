import * as constants from 'utils/constants';

export const { GTM_ID, GTM_DOMAIN, MKT_CODE, MKT_CORE } =
  constants.TEST || constants.DEV
    ? constants.GTM_CONFIG_DEV
    : constants.GTM_CONFIG_PROD;

export const addEventGtm = (eventName = '', data = {}) => {
  console.log(eventName, data);
  window &&
    window.dataLayer &&
    window.dataLayer.push({
      event: eventName,
      ...data,
    });
  // window && window.gtag && window.gtag("event", eventName, data);
};
