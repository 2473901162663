import api from 'utils/api';
import * as constants from 'utils/constants';

export const getTrainTicketDetail = (data) => {
  const option = {
    method: 'post',
    url: '/train/getBookingInformation',
    data,
    noAuthentication: false,
    severVice: constants.GATE,
  };
  return api(option);
};

export const getTrainTicketStatus = (data) => {
  const option = {
    method: 'post',
    url: '/smartlink/displayResult',
    data,
    noAuthentication: false,
    severVice: constants.BACKEND,
  };
  return api(option);
};
