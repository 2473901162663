import { Box, Grid, Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { BLACK_90, GRAY_DARK_7 } from 'assets/theme/colors';
import moment from 'moment';
import { IconArrowFlightOrder, IconDot } from 'public/icons';
import { useTranslation } from 'react-i18next';
import { DATE_FORMAT } from 'utils/moment';

const useStyles = makeStyles((theme) => ({
  airlineLogo: {
    width: 20,
    height: 20,
  },
  iconDot: {
    margin: 6,
  },
  airlineName: {
    color: BLACK_90,
    whiteSpace: 'nowrap',
    fontSize: '14px',
    lineHeight: '17px',
  },
  flightCode: {
    color: BLACK_90,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '14px',
    lineHeight: '17px',
  },
  warpAirBrand: {
    display: 'flex',
    alignItems: 'center',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    color: BLACK_90,
  },
  title: {
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '20px',
    color: theme.palette.black.black3,
  },
  information: {
    fontSize: '14px',
    lineHeight: '20px',
    color: theme.palette.black.black3,
  },
}));

const WayFlightItemDetail = ({
  item = {},
  airlineInfo = {},
  flightDetail = [],
  airPortsInfo = [],
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const { t } = useTranslation();

  return (
    <Box display="flex" flexDirection="column">
      <Grid container>
        <Grid item xl={5} lg={5} sm={5} md={5} xs={5}>
          <Box display="flex" justifyContent="flex-start" textAlign="start">
            <Box className={classes.title}>{`${
              airPortsInfo?.find((ele) => ele?.code === item?.departureAirport)
                ?.name
            } (${item?.departureAirport})`}</Box>
          </Box>
        </Grid>
        <Grid item xl={2} lg={2} sm={2} md={2} xs={2}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <div
              style={{
                background:
                  'linear-gradient(270deg, #A0AEC0 0%, rgba(160, 174, 192, 0) 100%)',
                width: 24,
                height: 1,
                marginRight: 10,
              }}
            ></div>
            <IconArrowFlightOrder />
            <div
              style={{
                background:
                  'linear-gradient(270deg, rgba(160, 174, 192, 0) 0%, #A0AEC0 100%)',
                width: 24,
                height: 1,
                marginLeft: 10,
              }}
            ></div>
          </Box>
        </Grid>
        <Grid item xl={5} lg={5} sm={5} md={5} xs={5}>
          <Box display="flex" justifyContent="flex-end" textAlign="end">
            <Box className={classes.title}>{`${
              airPortsInfo?.find((ele) => ele?.code === item?.arrivalAirport)
                ?.name
            } (${item?.arrivalAirport})`}</Box>
          </Box>
        </Grid>
      </Grid>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mt={4 / 8}
      >
        <Typography
          variant="caption"
          style={{ color: GRAY_DARK_7, lineHeight: '17px' }}
        >
          {item?.fromAirportName}
        </Typography>
        <Typography
          variant="caption"
          style={{ color: GRAY_DARK_7, lineHeight: '17px' }}
        >
          {item?.toAirportName}
        </Typography>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mt={4 / 8}
      >
        <Box className={classes.information}>
          {`${item?.departureTime}, ${moment(
            item?.departureDate,
            DATE_FORMAT
          ).format('DD/MM/YYYY')}`}
        </Box>
        <Box className={classes.information}>
          {`${item?.arrivalTime}, ${moment(
            item?.arrivalDate,
            DATE_FORMAT
          ).format('DD/MM/YYYY')}`}
        </Box>
      </Box>
      <Box display="flex" alignItems="center" mt={4 / 8}>
        <img src={airlineInfo.logo} className={classes.airlineLogo} alt="" />
        <Box flexDirection="column" textAlign="start" ml={1}>
          <Box className={classes.warpAirBrand}>
            <Box className={classes.airlineName}>{airlineInfo.name}</Box>
            <IconDot className={classes.iconDot} />
            <Box
              variant="caption"
              style={{ lineHeight: '17px' }}
              className={classes.flightCode}
            >
              {flightDetail?.map((p) => p?.flightCode).join('-')}
            </Box>
          </Box>
          {item?.operatingAirlineId && (
            <Box
              sx={{
                fontSize: 12,
                lineHeight: '17px',
                color: theme.palette.secondary.main,
              }}
            >
              {t('IDS_MT_TEXT_FLIGHT_PACIFIC')}12213
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};
export default WayFlightItemDetail;
