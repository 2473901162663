import { Box, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatMoneyCurrency } from 'utils/helpersDataHotel';
import AncillariesDetailDialog from './AncillariesDetailDialog';

const useStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    textAlign: 'center',
    fontSize: '14px',
    lineHeight: '17px',
    color: theme.palette.black.black3,
  },
  showDetail: {
    color: theme.palette.primary.main,
  },
  contentBold: {
    fontWeight: 600,
  },
  font18: {
    fontSize: 18,
    lineHeight: '22px',
  },
  title: {
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '21px',
    color: theme.palette.black.black3,
  },
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 2fr',
    gap: '4px 0',
  },
}));

const Ancillaries = ({ ancillaries = [], passengers = [], tickets = [] }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [openDetail, setOpenDetail] = useState(false);

  const numGuest = new Set(ancillaries.map((ancillary) => ancillary.guestId))
    .size;

  const finalPrice = ancillaries.reduce(
    (sum, ancillary) => (sum += ancillary.totalAmount),
    0
  );

  return (
    <>
      <Box className={classes.container}>
        <Box className={classes.title}>{t('IDS_TEXT_ORDER_ANCILLARIES')}</Box>
        <Box className={classes.content}>{t('IDS_TEXT_PEOPLE_COUNT')}:</Box>
        <Box className={classes.content}>{numGuest}</Box>
        <Box>
          <Button
            variant="text"
            color="primary"
            sx={{ padding: '0 4px', marginTop: '-4px' }}
            onClick={() => setOpenDetail(true)}
          >
            {t('IDS_MT_TEXT_VIEW_DETAIL')}
          </Button>
        </Box>
        <Box className={classes.content}>{t('IDS_TEXT_FINAL_PRICE')}:</Box>
        <Box className={classes.content}>
          {`${formatMoneyCurrency(finalPrice)}`}
        </Box>
      </Box>
      <AncillariesDetailDialog
        ancillaries={ancillaries}
        passengers={passengers}
        tickets={tickets}
        open={openDetail}
        onClose={() => setOpenDetail(false)}
      />
    </>
  );
};

export default Ancillaries;
