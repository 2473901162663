import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { WHITE } from 'assets/theme/colors';
import { useTranslation } from 'react-i18next';
import { formatMoneyCurrency } from 'utils/helpersDataHotel';

const useStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    textAlign: 'center',
    paddingBottom: 4,
    fontSize: '14px',
    lineHeight: '17px',
    color: theme.palette.black.black3,
    marginBottom: 4,
  },
  title: {
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '21px',
    color: theme.palette.black.black3,
  },
  container: {
    width: '100%',
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    backgroundColor: WHITE,
  },
  infoRow: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
  },
}));

const OrderPaymentInfo = ({ payment = {}, paymentStatus = {} }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Box className={classes.container}>
      <Box className={classes.title}>{t('IDS_MT_TEXT_PAYMENT_ACTION')}</Box>
      <Box className={classes.infoRow}>
        <Box className={classes.content}>
          {t('IDS_TEXT_ORDER_PAYMENT_TOTAL_PRICE')}:
        </Box>
        <Box className={classes.content}>
          {`${formatMoneyCurrency(payment?.totalAmount)}`}
        </Box>
      </Box>
      <Box className={classes.infoRow}>
        <Box className={classes.content}>
          {t('IDS_MT_TEXT_PAYMENT_MENTHODS')}:
        </Box>
        <Box className={classes.content}>{payment.paymentMethodName}</Box>
      </Box>
    </Box>
  );
};

export default OrderPaymentInfo;
