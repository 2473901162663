import {
  cancelRequestBooking,
  confirmRequestBooking,
  getHotelBriefBooking,
} from 'api/hotel';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useParams, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as gtm from 'utils/gtm';
import cookie from 'js-cookie';

import * as constants from 'utils/constants';
import moment from 'moment';

const useHotelConfirmTA = () => {
  const { bookingHash } = useParams();
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const headerUtmSource = params.get('utm_source');
  const headerUtmMedium = params.get('utm_medium');
  const headerUtmCampaign = params.get('utm_campaign');
  const eventName = 'hotel_confirmed_booking';
  let hotelConfirmedType = '';
  let headerUtm = {};

  if (location?.pathname.includes('email')) {
    hotelConfirmedType = 'Email';
  } else {
    hotelConfirmedType = 'SMS';
  }

  const [briefBooking, setBriefBooking] = useState();

  const fetchBriefBooking = async () => {
    const { data } = await getHotelBriefBooking({
      bookingHash,
    });
    setBriefBooking(data);
  };
  const handleAddHeader = () => {
    const addUtmHeader = (key, value) => {
      if (value) {
        headerUtm[key] = value;
      }
    };
    addUtmHeader('utm_source', headerUtmSource);
    addUtmHeader('utm_medium', headerUtmMedium);
    addUtmHeader('utm_campaign', headerUtmCampaign);
    return headerUtm;
  };

  useEffect(() => {
    if (!briefBooking) return;
    const dataGtm = {
      device_id: cookie.get(constants.DEVICE_ID_IN_SERVER),
      user_id: null,
      hotel_id: briefBooking?.rootHotelId,
      booking_id: briefBooking?.hotelBookingId,
      hotel_confirmed_type: hotelConfirmedType,
      hotel_name: briefBooking?.hotelName,
      create_date: moment(briefBooking?.createdDate).format(
        'HH:mm:ss, DD/MM/YYYY'
      ),
      hotel_confirmed_date: '',
      hotel_confirmed: null,
    };
    gtm.addEventGtm(eventName, dataGtm);
  }, [briefBooking, hotelConfirmedType]);

  const confirmEmptyRoom = async (dataConfirm) => {
    try {
      const headers = handleAddHeader() || {};
      const { data } = await confirmRequestBooking(
        {
          reason: dataConfirm?.reason || '',
          expiredTime: dataConfirm?.expiredTime || '30',
          bookingHash,
        },
        headers
      );
      if (data?.code === 200) {
        const dataGtm = {
          device_id: cookie.get(constants.DEVICE_ID_IN_SERVER),
          user_id: null,
          hotel_id: briefBooking?.rootHotelId,
          booking_id: briefBooking?.hotelBookingId,
          hotel_confirmed_type: hotelConfirmedType,
          hotel_name: briefBooking?.hotelName,
          create_date: moment(briefBooking?.createdDate).format(
            'HH:mm:ss, DD/MM/YYYY'
          ),
          hotel_confirmed_date: moment().format('HH:mm:ss, DD/MM/YYYY'),
          hotel_confirmed: 1,
        };
        gtm.addEventGtm(eventName, dataGtm);
        history.push('/hotel/confirm-request-booking/success');
      } else toast.error(data.message);
    } catch (error) {}
  };

  const cancelEmptyRoom = async (dataConfirm) => {
    try {
      const headers = handleAddHeader() || {};
      const { data } = await cancelRequestBooking(
        {
          reason: dataConfirm?.reason || '',
          expiredTime: dataConfirm?.expiredTime || '30',
          bookingHash,
        },
        headers
      );
      if (data?.code === 200) {
        const dataGtm = {
          device_id: cookie.get(constants.DEVICE_ID_IN_SERVER),
          user_id: null,
          hotel_id: briefBooking?.rootHotelId,
          booking_id: briefBooking?.hotelBookingId,
          hotel_confirmed_type: hotelConfirmedType,
          hotel_name: briefBooking?.hotelName,
          create_date: moment(briefBooking?.createdDate).format(
            'HH:mm:ss, DD/MM/YYYY'
          ),
          hotel_confirmed_date: moment().format('HH:mm:ss, DD/MM/YYYY'),
          hotel_confirmed: 0,
        };
        gtm.addEventGtm(eventName, dataGtm);
        history.push('/hotel/confirm-request-booking/fail');
      } else toast.error(data.message);
    } catch (error) {}
  };

  useEffect(() => {
    fetchBriefBooking();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    briefBooking,
    confirmEmptyRoom,
    cancelEmptyRoom,
  };
};

export default useHotelConfirmTA;
