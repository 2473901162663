import { Box, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { WHITE } from 'assets/theme/colors';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatMoneyCurrency } from 'utils/helpersDataHotel';
import AncillariesDetailDrawer from './AncillariesDetailDrawer';

const useStyles = makeStyles((theme) => ({
  content: {
    display: 'flex',
    textAlign: 'center',
    paddingBottom: 4,
    fontSize: '14px',
    lineHeight: '17px',
    color: theme.palette.black.black3,
    marginBottom: 4,
  },
  title: {
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '21px',
    color: theme.palette.black.black3,
  },
  container: {
    width: '100%',
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    backgroundColor: WHITE,
  },
  infoRow: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

const getTotalPriceByType = (ancillaries = [], t) => {
  const hashed = {};
  for (let i = 0; i < ancillaries.length; i++) {
    const item = ancillaries[i];
    if (!hashed[item.type]) hashed[item.type] = item.totalAmount;
    else hashed[item.type] += item.totalAmount;
  }
  return Object.entries(hashed).map(([key, value]) => ({
    title: t(`IDS_ANCILLARY_${key}`),
    value: value ? `${formatMoneyCurrency(value)}` : null,
  }));
};

const Ancillaries = ({ ancillaries = [], passengers = [], tickets = [] }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [openDetail, setOpenDetail] = useState(false);

  const totalPriceByType = getTotalPriceByType(ancillaries, t);

  return (
    <>
      <Box className={classes.container}>
        <Box className={classes.header}>
          <Box className={classes.title}>{t('IDS_TEXT_ORDER_ANCILLARIES')}</Box>
          <Button
            variant="text"
            color="primary"
            sx={{ padding: '0 4px' }}
            onClick={() => setOpenDetail(true)}
          >
            {t('IDS_MT_TEXT_VIEW_DETAIL')}
          </Button>
        </Box>
        {totalPriceByType.map((item) => (
          <Box className={classes.infoRow} key={item.title}>
            <Box className={classes.content}>{item.title}</Box>
            <Box className={classes.content}>{item.value}</Box>
          </Box>
        ))}
      </Box>
      <AncillariesDetailDrawer
        ancillaries={ancillaries}
        passengers={passengers}
        tickets={tickets}
        open={openDetail}
        onClose={() => setOpenDetail(false)}
      />
    </>
  );
};

export default Ancillaries;
