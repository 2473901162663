import api from 'utils/api';
import * as constants from 'utils/constants';

export const getHotelBriefBooking = (params = {}) => {
  const option = {
    method: 'get',
    params,
    url: '/hms-premium/booking/notification/brief-booking',
    noAuthentication: false,
    severVice: constants.GATE,
  };
  return api(option);
};

export const confirmRequestBooking = (params = {}, headers = {}) => {
  const option = {
    method: 'put',
    params,
    url: '/hms-premium/hotels/confirm-request-booking',
    noAuthentication: false,
    headers,
    severVice: constants.GATE,
  };
  return api(option);
};

export const cancelRequestBooking = (params = {}, headers = {}) => {
  const option = {
    method: 'put',
    url: '/hms-premium/hotels/cancel-request-booking',
    headers,
    severVice: constants.GATE,
    params,
  };
  return api(option);
};
