import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
} from '@mui/material';
import Button from '@mui/material/Button';
import Slide from '@mui/material/Slide';
import { makeStyles, useTheme } from '@mui/styles';
import { PINK } from 'assets/theme/colors';
import WayFlightItemDetail from 'modules/mobile/flight/payment/orderDetail/WayFlightItemDetail';
import { IconClose } from 'public/icons';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import {
  flightPaymentMethodCode,
  flightPaymentStatus,
  routeStatic,
} from 'utils/constants';
import { getPaymentProperties } from 'utils/helpers';
import Ancillaries from './Ancillaries';
import OrderContact from './OrderContact';
import OrderCustomerInfo from './OrderCustomerInfo';
import OrderExportInvoice from './OrderExportInvoice';
import OrderInfo from './OrderInfo';
import OrderInsurance from './OrderInsurance';
import OrderPaymentInfo from './OrderPaymentInfo';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide ref={ref} {...props} direction="up" />;
});

const useStyles = makeStyles((theme) => ({
  wrapper: {
    backgroundColor: theme.palette.gray.grayLight22,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 8,
  },
  lineBox: {
    display: 'flex',
    width: '100%',
  },
  dialogTitle: {
    margin: 0,
    padding: '4px 12px',
    display: 'grid',
    gridTemplateColumns: '1fr 4fr 1fr',
    placeItems: 'center',
  },
  closeButton: {},
  title: {
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '19px',
    color: theme.palette.black.black3,
  },
  dialogContent: {
    padding: '0px !important',
  },
  wayFlightItemContainer: {
    padding: '0 16px',
    backgroundColor: theme.palette.gray.grayLight23,
  },
}));

const OrderDetail = ({
  open = false,
  handleClose = () => {},
  orderDetail = {},
  airLinesInfo = {},
  airPortsInfo,
}) => {
  const theme = useTheme();
  const classes = useStyles();
  // const classesUtils = utilStyles();
  const router = useLocation();
  const [paymentStatus, setPaymentStatus] = useState({});
  const [showPaymentBtn, setShowPaymentBtn] = useState({});
  const { t } = useTranslation();

  const flightDetails = orderDetail?.tickets?.map((p) => p?.flightDetails);

  useEffect(() => {
    actionGetPaymentStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderDetail]);

  useEffect(() => {
    actionShowButtonPayment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentStatus]);

  const actionGetPaymentStatus = () => {
    const checkSuccess =
      orderDetail?.paymentMethodCode === flightPaymentMethodCode.ATM ||
      orderDetail?.paymentMethodCode === flightPaymentMethodCode.CREDIT ||
      orderDetail?.paymentMethodCode === flightPaymentMethodCode.VISA_MASTER ||
      orderDetail?.paymentMethodCode === flightPaymentMethodCode.QR_CODE;
    const dataStatus = getPaymentProperties(
      orderDetail?.booking?.bookingStatus,
      checkSuccess,
      t
    );
    setPaymentStatus(dataStatus);
  };

  const actionShowButtonPayment = () => {
    let isShowPayment = false;
    if (
      (paymentStatus.type === flightPaymentStatus.HOLDING ||
        paymentStatus.type === flightPaymentStatus.PENDING) &&
      (orderDetail.paymentMethodCode === flightPaymentMethodCode.HOLDING ||
        orderDetail.paymentMethodCode ===
          flightPaymentMethodCode.BANK_TRANSFER ||
        orderDetail.paymentMethodCode ===
          flightPaymentMethodCode.BANK_TRANSFER_2)
    ) {
      isShowPayment = true;
    }
    setShowPaymentBtn(isShowPayment);
  };

  const isTwoWay = orderDetail?.tickets?.length === 2;

  const handleClickPayment = () => {
    router.push({
      pathname: routeStatic.REPAY_BOOKING_FLIGHT.href,
      query: {
        bookingId: orderDetail?.orderCode.substr(1),
      },
    });
  };

  const wayFlightItemContainerStyle = {
    backgroundColor: theme.palette.common.white,
    padding: '16px',
    boxShadow: '0px 8px 8px rgba(0, 0, 0, 0.08)',
    borderRadius: '8px',
    margin: '16px 0',
  };

  return (
    <Dialog
      Dialog
      onClose={handleClose(false)}
      open={open}
      fullScreen
      TransitionComponent={Transition}
    >
      <Box className={classes.dialogTitle}>
        <IconButton
          onClick={handleClose(false)}
          aria-label="close"
          className={classes.closeButton}
        >
          <IconClose />
        </IconButton>
        <Box className={classes.title}>{t('IDS_TEXT_ORDER_DETAIL')}</Box>
      </Box>
      <DialogContent classes={{ root: classes.dialogContent }}>
        <Box className={classes.wrapper}>
          <Box className={classes.wayFlightItemContainer}>
            <WayFlightItemDetail
              item={orderDetail?.tickets[0]}
              airlineInfo={airLinesInfo?.find(
                (ele) =>
                  ele.code ===
                  orderDetail?.tickets[0]?.flightDetails[0]?.operatingAirline
              )}
              orderDetail={orderDetail}
              flightDetail={flightDetails[0]}
              airPortsInfo={airPortsInfo}
              containerStyle={wayFlightItemContainerStyle}
            />
            {isTwoWay && (
              <WayFlightItemDetail
                item={orderDetail?.tickets[1]}
                airlineInfo={airLinesInfo?.find(
                  (ele) =>
                    ele.code ===
                    orderDetail?.tickets[1]?.flightDetails[0]?.operatingAirline
                )}
                orderDetail={orderDetail}
                flightDetail={flightDetails[1]}
                airPortsInfo={airPortsInfo}
                containerStyle={wayFlightItemContainerStyle}
              />
            )}
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
            <OrderInfo
              orderDetail={orderDetail}
              paymentStatus={paymentStatus}
            />
            <Box className={classes.lineBox}>
              <OrderContact item={orderDetail?.contact} />
            </Box>
            <Box className={classes.lineBox}>
              <OrderCustomerInfo
                guests={orderDetail.passengers}
                isFromPayment={true}
              />
            </Box>
            <Box className={classes.lineBox}>
              <OrderPaymentInfo
                payment={orderDetail?.payment}
                paymentStatus={paymentStatus}
              />
            </Box>
            {!!orderDetail?.insurance && (
              <Box className={classes.lineBox}>
                <OrderInsurance insurance={orderDetail?.insurance} />
              </Box>
            )}
            {!!orderDetail?.invoice && (
              <Box className={classes.lineBox}>
                <OrderExportInvoice invoice={orderDetail?.invoice} />
              </Box>
            )}

            {!!orderDetail?.ancillaries?.length && (
              <Box className={classes.lineBox}>
                <Ancillaries
                  ancillaries={orderDetail?.ancillaries}
                  passengers={orderDetail?.passengers}
                  tickets={orderDetail?.tickets}
                />
              </Box>
            )}
            {showPaymentBtn && (
              <Box className={classes.lineBox} mt={3} justifyContent="flex-end">
                <Box mr={2}>
                  <Button
                    type="submit"
                    backgroundColor={PINK}
                    height={48}
                    borderRadius={8}
                    padding={'12px 36px'}
                    handleClick={handleClickPayment}
                    className={classes.rightBox}
                  >
                    <Typography variant="subtitle1">
                      {t('IDS_MT_TEXT_PAYMENT')}
                    </Typography>
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default OrderDetail;
