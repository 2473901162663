import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  linkHotelDetail: {
    textDecoration: 'none !important',
  },
  container: {
    fontSize: 14,
    lineHeight: '17px',
    color: theme.palette.black.black3,
    backgroundColor: theme.palette.white.main,
    boxShadow: '0px 5px 5px rgba(0, 0, 0, 0.03)',
    borderRadius: 8,
    border: '1px solid #EDF2F7',
    width: '100%',
    height: '100%',
    cursor: 'pointer',
    transition: 'box-shadow 0.4s',
    '&:hover': {
      boxShadow: '0px 8px 8px rgba(0, 0, 0, 0.06)',
    },
  },
  topItem: {
    width: '100%',
    position: 'relative',
    overflow: 'hidden',
    borderRadius: '8px 8px 0px 0px',
  },
  imageHotel: {
    width: '100%',
    height: 155,
    borderRadius: '8px 8px 0px 0px',
    position: 'relative',
  },
  backgroundDefault: {
    background: theme.palette.gray.grayLight22,
    position: 'relative',
  },
  iconHeart: {
    position: 'absolute',
    top: -1,
    right: 0,
    zIndex: 2,
  },
  favorite: {
    fill: theme.palette.secondary.main,
    stroke: theme.palette.white.main,
  },
  notFavorite: {
    fill: 'rgba(0, 0, 0, 0.6)',
    stroke: theme.palette.white.main,
  },
  tagSpecial: {
    position: 'absolute',
    right: 12,
    bottom: 6,
    width: 42,
    height: 50,
  },
  wrapTags: {
    display: 'flex',
    position: 'absolute',
    bottom: 6,
    left: 12,
    alignItems: 'center',
  },
  tags: {
    color: '#FFF',
    display: 'inline-block',
    borderRadius: 4,
    lineHeight: '14px',
    fontWeight: 600,
    background: 'rgb(255, 188, 57)',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: 140,
    fontSize: 12,
    padding: '2px 4px',
  },
  wrapFlagTopHotel: {
    background: theme.palette.red.redLight5,
    height: 20,
    color: '#FFF',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
    fontWeight: 600,
    fontSize: '12px',
    padding: '3px 4px 3px 4px',
  },
  bottomItem: {
    // width: '100%',
    // height: 'calc(100% - 155px)',
    padding: 12,
    // minHeight: 180,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  hotelName: {
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    fontSize: 16,
    fontWeight: 600,
  },
  wrapRating: {
    display: 'flex',
    alignItems: 'center',
    margin: '4px 0 6px 0',
  },
  styleMyPlus: {
    marginLeft: 5,
  },
  location: {
    display: 'flex',
    alignItems: 'center',
  },
  textLocation: {
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
  },
  wrapPoint: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 8,
  },
  point: {
    fontWeight: 600,
    color: theme.palette.secondary.main,
    background: theme.palette.secondary.background1,
    padding: '2px 4px',
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 12,
    lineHeight: '14px',
  },
  review: {
    marginLeft: '4px',
  },
  rateLevel: {
    marginLeft: 4,
    color: '#718096',
  },
  lastBooking: {
    background: '#E5F8FE',
    padding: '2px 6px',
    width: 'fit-content',
    marginTop: 6,
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.blue.blueLight8,
  },
  discountPercent: {
    width: 80,
    height: 80,
    borderRadius: '50%',
    background: theme.palette.secondary.main,
    position: 'absolute',
    top: -35,
    left: -23,
  },
  discountPercentNumber: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.white.main,
    marginTop: 46,
    marginLeft: 32,
    position: 'absolute',
    fontSize: 18,
    lineHeight: '21px',
    fontWeight: 600,
  },
  wrapDiscountPriceFavorite: {
    display: 'flex',
    width: '100%',
    alignItems: 'flex-end',
    flexDirection: 'column',
    paddingTop: 6,
  },
  discountSubPriceFavorite: {
    textDecorationLine: 'line-through',
    color: theme.palette.gray.grayDark7,
  },
  discountPriceFavorite: {
    fontSize: 16,
    lineHeight: '21px',
    fontWeight: 600,
  },
  discountPriceFavoriteFL: {
    fontSize: 20,
    lineHeight: '24px',
    fontWeight: 600,
    color: theme.palette.purple.purpleLight2,
  },
  loginText: {
    fontSize: 16,
    lineHeight: '20px',
  },
  discountMember: {
    fontStyle: 'italic',
  },
  priceFlashSale: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    width: '100%',
  },
  totalSlots: {
    width: 72,
    height: 4,
    borderRadius: 100,
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    position: 'relative',
    marginTop: 4,
  },
  textSlots: {
    position: 'absolute',
    zIndex: 2,
    left: -9,
    top: -6,
    display: 'flex',
    alignItems: 'center',
    lineHeight: '14px',
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.white.main,
  },
  usedSlots: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1,
    height: 4,
    borderRadius: 100,
    backgroundColor: theme.palette.secondary.main,
  },
  promotionContent: {
    border: '1px dashed #CBD5E0',
    borderRadius: 8,
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    padding: '6px 8px',
    marginTop: 4,
  },
  pricePromotion: {
    fontSize: 16,
    lineHeight: '19px',
    fontWeight: 600,
    color: theme.palette.secondary.main,
  },
  codeContent: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 12,
    lineHeight: '14px',
  },
  codePromotion: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    margin: '0 2px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: 85,
  },
  discountPromotion: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 4,
    color: theme.palette.white.main,
    padding: 2,
  },
  saleContent: {
    background: 'linear-gradient(99.25deg, #FFBC39 -4.45%, #FF3366 100%)',
    borderRadius: 8,
    padding: 8,
    display: 'flex',
    flexDirection: 'column',
    fontSize: 12,
    lineHeight: '14px',
    fontWeight: 600,
    color: theme.palette.white.main,
  },
  iconHidePrice: {
    width: 36,
    height: 17,
    marginTop: 2,
  },
}));

export default useStyles;
