import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import { GREY_300, RED, WHITE } from 'assets/theme/colors';
import { IconPaymentPendingHotel } from 'public/icons';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  boxStatus: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  divider: {
    height: 1,
    color: GREY_300,
    width: '100%',
  },
  wrapContainer: {
    padding: '32px 0 0',
    position: 'relative',
  },
  container: {
    margin: 'auto',
    maxWidth: 630,
    height: 'auto',
    padding: 24,
    background: WHITE,
    borderRadius: '8px 8px 0 0',
    borderTop: 'solid 4px',
    borderTopColor: RED,
  },
  mainStatus: {
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '19px',
    textAlign: 'center',
    color: theme.palette.black.black3,
    marginTop: '12px',
    marginBottom: '8px',
  },
  desc: {
    fontSize: '14px',
    lineHeight: '22px',
    textAlign: 'center',
    color: theme.palette.black.black3,
  },
}));

const BookingUndefined = () => {
  const classes = useStyles();

  const { t } = useTranslation();

  return (
    <Box className={classes.wrapContainer}>
      <Box className={classes.container}>
        <Box className={classes.boxStatus}>
          <IconPaymentPendingHotel />
          <Box className={classes.mainStatus}>
            {t('IDS_TEXT_FLIGHT_BOOKING_UNDEFINED')}
          </Box>
          <Box className={classes.desc}>
            {t('IDS_TEXT_FLIGHT_BOOKING_RESULT_DESC_UNDEFINED')}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default BookingUndefined;
