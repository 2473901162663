/* eslint-disable no-nested-ternary */
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import { ORANGE, ORANGE_500, WHITE } from 'assets/theme/colors';
import { IconHoldingBookingFlight } from 'public/icons';
import { useTranslation } from 'react-i18next';
import { PINK_MT } from 'setupTheme';
import { getExpiredTimeMillisecond } from 'utils/helpers';

const useStyles = makeStyles((theme) => ({
  boxStatus: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  boxHolder: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'rgba(237, 137, 54, 0.1)',
    color: ORANGE,
    padding: '4px 8px',
    borderRadius: 4,
    marginBottom: 8,
  },
  wrapContainer: {
    padding: '32px 0 0',
    position: 'relative',
  },
  container: {
    margin: 'auto',
    maxWidth: 630,
    height: 'auto',
    padding: 24,
    background: WHITE,
    borderRadius: '8px 8px 0px 0px ',
    borderTop: 'solid 4px',
    borderTopColor: ORANGE_500,
  },
  mainStatus: {
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '19px',
    textAlign: 'center',
    color: theme.palette.black.black3,
    marginTop: '12px',
    marginBottom: '8px',
  },
  desc: {
    fontSize: '14px',
    lineHeight: '20px',
    textAlign: 'center',
    color: theme.palette.black.black3,
  },
}));

const BookingHoldingSucess = ({ orderDetail = {}, contact }) => {
  const classes = useStyles();

  const { t } = useTranslation();
  const paymentExpiredTime = orderDetail?.booking?.paymentExpiredTime;

  return (
    <Box className={classes.wrapContainer}>
      <Box className={classes.container}>
        <Box className={classes.boxStatus}>
          <IconHoldingBookingFlight />
          <Box className={classes.mainStatus}>
            {t('IDS_TEXT_FLIGHT_BOOKING_HOLD_SUCCESS')}
          </Box>
          <Box className={classes.boxHolder}>
            <Box className={classes.desc} sx={{ color: '#ED8936' }}>{`${t(
              'IDS_TEXT_FLIGHT_BOOKING_HOLD_TIME'
            )} ${getExpiredTimeMillisecond(paymentExpiredTime || 0)}`}</Box>
          </Box>
          <Box className={classes.desc}>
            {t('IDS_TEXT_FLIGHT_BOOKING_RESULT_DESC_SUCCESS_PC', {
              email: contact?.email ?? '',
            })}
          </Box>
          <Box className={classes.desc} sx={{ color: PINK_MT }}>
            {t('IDS_TEXT_FLIGHT_HOLDING_SUCCESS_DESC')}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default BookingHoldingSucess;
