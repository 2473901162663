import EastIcon from '@mui/icons-material/East';
import { Divider, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { Col } from 'modules/common/Elements';
import { useTranslation } from 'react-i18next';

const DetailBooking = ({ briefBooking }) => {
  const { t } = useTranslation();
  return (
    <>
      <Box width="100%" mt={3}>
        <Typography
          variant="h5"
          style={{ fontWeight: 'bold', textTransform: 'uppercase' }}
        >
          {t('IDS_TEXT_ORDER_DETAIL')}
        </Typography>
        <Box
          style={{
            marginTop: 16,
            backgroundColor: '#F2F3F3',
            padding: '12px 24px',
            display: 'flex',
            borderRadius: 8,
          }}
        >
          <Box
            style={{
              width: '35%',
              backgroundColor: '#F2F3F3',
              borderRight: '2px solid rgba(117, 117, 117, 0.32)',
            }}
          >
            <Typography
              variant="h5"
              style={{ fontWeight: 'bold', color: '#FF3366' }}
            >
              {briefBooking?.hotelBookingId}
            </Typography>
            <Typography
              variant="body2"
              style={{
                color: '#8C9497',
              }}
            >
              {t('IDS_MT_TEXT_ROOM_CODE')}
            </Typography>
            <Box display="flex" alignItems="center">
              <Col>
                <Typography variant="h6" style={{ color: '#00B6F3' }}>
                  {briefBooking?.checkIn}
                </Typography>
                <Typography variant="body2" style={{ color: '#8C9497' }}>
                  Checkin
                </Typography>
              </Col>
              <Col style={{ marginLeft: 16, marginRight: 12 }}>
                <EastIcon style={{ color: '#8C9497', marginBottom: 4 }} />
                <span style={{ color: '#8C9497', fontSize: 14 }}>
                  {briefBooking?.numNights}
                </span>
              </Col>
              <Col>
                <Typography variant="h6" style={{ color: '#00B6F3' }}>
                  {briefBooking?.checkOut}
                </Typography>
                <Typography variant="body2" style={{ color: '#8C9497' }}>
                  Checkout
                </Typography>
              </Col>
            </Box>
          </Box>
          <Box
            style={{
              width: '65%',
              backgroundColor: '#F2F3F3',
              paddingLeft: 16,
            }}
          >
            <Typography variant="subtitle1" style={{ fontWeight: 600 }}>
              {t('IDS_MT_TEXT_INFO_HOTEL')}
            </Typography>
            <Box display="flex">
              <Typography
                variant="body2"
                style={{
                  color: '#8C9497',
                  width: '60px',
                }}
              >
                {t('IDS_MT_TEXT_HOTEL_NAME')}
              </Typography>
              <Typography variant="body2" style={{}}>
                {briefBooking?.hotelName}
              </Typography>
            </Box>
            <Box display="flex">
              <Typography
                variant="body2"
                style={{
                  color: '#8C9497',
                  width: '60px',
                }}
              >
                {t('IDS_MT_TEXT_HOTEL_ADDRESS')}
              </Typography>
              <Typography variant="body2" style={{}}>
                {briefBooking?.hotelAddress}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box width="100%" mt={3} style={{ border: '1px solid #FF3366' }}>
        <Typography variant="subtitle2" style={{ margin: 12, fontSize: 16 }}>
          {briefBooking?.roomNightText}
        </Typography>
      </Box>
      <Box
        style={{
          marginTop: 8,
          padding: '12px 24px',
          display: 'flex',
          width: '100%',
        }}
      >
        <Box
          style={{
            width: '30%',
            borderRight: '2px solid rgba(117, 117, 117, 0.32)',
          }}
        >
          <Typography
            variant="body2"
            style={{
              color: '#8C9497',
            }}
          >
            {t('IDS_MT_TEXT_HOTEL_STANDARD_GUEST')}
          </Typography>
          <Typography variant="body2" style={{}}>
            {briefBooking?.totalStandardAdults}
          </Typography>
        </Box>
        {briefBooking?.numberExtra && (
          <Box
            style={{
              width: '30%',
              borderRight: '2px solid rgba(117, 117, 117, 0.32)',
              paddingLeft: 16,
            }}
          >
            <Typography
              variant="body2"
              style={{
                color: '#8C9497',
              }}
            >
              {t('IDS_MT_TEXT_HOTEL_SUB_GUEST')}
            </Typography>
            <Typography variant="body2" style={{}}>
              {briefBooking?.numberExtra}
            </Typography>
          </Box>
        )}
        <Box
          style={{
            width: '30%',
            paddingLeft: 64,
          }}
        >
          <Typography
            variant="body2"
            style={{
              color: '#8C9497',
            }}
          >
            {t('IDS_MT_TEXT_HOTEL_TOTAL_GUEST')}
          </Typography>
          <Typography variant="body2" style={{}}>
            {briefBooking?.totalNumberGuest}
          </Typography>
        </Box>
      </Box>
      <Divider
        style={{ backgroundColor: '#D9DBDC', height: 1, width: '100%' }}
      />
    </>
  );
};
export default DetailBooking;
