export const domesticInBound = [
  {
    code: "HAN-SGN",
    depart: "Hà Nội",
    arrival: "Hồ Chí Minh",
    provinceId: "tp33",
    country: "Việt Nam",
    departCountryCode: "VN",
    returnCountryCode: "VN",
    thumb:
      "https://storage.googleapis.com/tripi-assets/mytour/images/locations/hochiminh.png",
  },
  {
    code: "SGN-HAN",
    depart: "Hồ Chí Minh",
    arrival: "Hà Nội",
    provinceId: "tp11",
    country: "Việt Nam",
    departCountryCode: "VN",
    returnCountryCode: "VN",
    thumb:
      "https://storage.googleapis.com/tripi-assets/mytour/images/locations/hanoi.png",
  },
  {
    code: "SGN-CXR",
    depart: "Hồ Chí Minh",
    arrival: "Nha Trang",
    provinceId: "td414",
    country: "Việt Nam",
    departCountryCode: "VN",
    returnCountryCode: "VN",
    thumb:
      "https://storage.googleapis.com/tripi-assets/mytour/images/locations/nha-trang.png",
  },
  {
    code: "HAN-DAD",
    depart: "Hà Nội",
    arrival: "Đà Nẵng",
    provinceId: "tp50",
    country: "Việt Nam",
    departCountryCode: "VN",
    returnCountryCode: "VN",
    thumb:
      "https://storage.googleapis.com/tripi-assets/mytour/images/locations/danang.png",
  },
  {
    code: "DAD-HAN",
    depart: "Đà Nẵng",
    arrival: "Hà Nội",
    provinceId: "tp11",
    country: "Việt Nam",
    departCountryCode: "VN",
    returnCountryCode: "VN",
    thumb:
      "https://storage.googleapis.com/tripi-assets/mytour/images/locations/hanoi.png",
  },
  {
    code: "SGN-DAD",
    depart: "Hồ Chí Minh",
    arrival: "Đà Nẵng",
    provinceId: "tp50",
    country: "Việt Nam",
    departCountryCode: "VN",
    returnCountryCode: "VN",
    thumb:
      "https://storage.googleapis.com/tripi-assets/mytour/images/locations/danang.png",
  },
  {
    code: "DAD-SGN",
    depart: "Đà Nẵng",
    arrival: "Hồ Chí Minh",
    provinceId: "tp33",
    country: "Việt Nam",
    departCountryCode: "VN",
    returnCountryCode: "VN",
    thumb:
      "https://storage.googleapis.com/tripi-assets/mytour/images/locations/hochiminh.png",
  },
  {
    code: "SGN-PQC",
    depart: "Hồ Chí Minh",
    arrival: "Phú Quốc",
    provinceId: "td446",
    country: "Việt Nam",
    departCountryCode: "VN",
    returnCountryCode: "VN",
    thumb:
      "https://storage.googleapis.com/tripi-assets/mytour/images/locations/phu-quoc.png",
  },
  {
    code: "PQC-SGN",
    depart: "Phú Quốc",
    arrival: "Hồ Chí Minh",
    provinceId: "tp33",
    country: "Việt Nam",
    departCountryCode: "VN",
    returnCountryCode: "VN",
    thumb:
      "https://storage.googleapis.com/tripi-assets/mytour/images/locations/hochiminh.png",
  },
  {
    code: "HAN-PQC",
    depart: "Hà Nội",
    arrival: "Phú Quốc",
    provinceId: "td446",
    country: "Việt Nam",
    departCountryCode: "VN",
    returnCountryCode: "VN",
    thumb:
      "https://storage.googleapis.com/tripi-assets/mytour/images/locations/phu-quoc.png",
  },
  {
    code: "PQC-HAN",
    depart: "Phú Quốc",
    arrival: "Hà Nội",
    provinceId: "tp11",
    country: "Việt Nam",
    departCountryCode: "VN",
    returnCountryCode: "VN",
    thumb:
      "https://storage.googleapis.com/tripi-assets/mytour/images/locations/hanoi.png",
  },
  {
    code: "HAN-CXR",
    depart: "Hà Nội",
    arrival: "Nha Trang",
    provinceId: "td414",
    country: "Việt Nam",
    departCountryCode: "VN",
    returnCountryCode: "VN",
    thumb:
      "https://storage.googleapis.com/tripi-assets/mytour/images/locations/nha-trang.png",
  },
  {
    code: "CXR-HAN",
    depart: "Nha Trang",
    arrival: "Hà Nội",
    provinceId: "tp11",
    country: "Việt Nam",
    departCountryCode: "VN",
    returnCountryCode: "VN",
    thumb:
      "https://storage.googleapis.com/tripi-assets/mytour/images/locations/hanoi.png",
  },
  {
    code: "CXR-SGN",
    depart: "Nha Trang",
    arrival: "Hồ Chí Minh",
    provinceId: "tp33",
    country: "Việt Nam",
    departCountryCode: "VN",
    returnCountryCode: "VN",
    thumb:
      "https://storage.googleapis.com/tripi-assets/mytour/images/locations/hanoi.png",
  },
  {
    code: "CXR-DAD",
    depart: "Nha Trang",
    arrival: "Đà Nẵng",
    provinceId: "tp50",
    country: "Việt Nam",
    departCountryCode: "VN",
    returnCountryCode: "VN",
    thumb:
      "https://storage.googleapis.com/tripi-assets/mytour/images/locations/hanoi.png",
  },
  {
    code: "HAN-DLI",
    depart: "Hà Nội",
    arrival: "Đà Lạt",
    provinceId: "td155",
    country: "Việt Nam",
    departCountryCode: "VN",
    returnCountryCode: "VN",
    thumb:
      "https://storage.googleapis.com/tripi-assets/mytour/images/locations/da-lat.png",
  },
  {
    code: "DLI-HAN",
    depart: "Đà Lạt",
    arrival: "Hà Nội",
    provinceId: "tp11",
    country: "Việt Nam",
    departCountryCode: "VN",
    returnCountryCode: "VN",
    thumb:
      "https://storage.googleapis.com/tripi-assets/mytour/images/locations/hanoi.png",
  },
  {
    code: "HAN-VCS",
    depart: "Hà Nội",
    arrival: "Côn Đảo",
    provinceId: "td134",
    country: "Việt Nam",
    departCountryCode: "VN",
    returnCountryCode: "VN",
    thumb:
      "https://storage.googleapis.com/tripi-assets/mytour/images/locations/vung-tau.png",
  },
  {
    code: "VCS-HAN",
    depart: "Côn Đảo",
    arrival: "Hà Nội",
    provinceId: "tp11",
    country: "Việt Nam",
    departCountryCode: "VN",
    returnCountryCode: "VN",
    thumb:
      "https://storage.googleapis.com/tripi-assets/mytour/images/locations/hanoi.png",
  },
  {
    code: "SGN-VCS",
    depart: "Hồ Chí Minh",
    arrival: "Côn Đảo",
    provinceId: "td134",
    country: "Việt Nam",
    departCountryCode: "VN",
    returnCountryCode: "VN",
    thumb:
      "https://storage.googleapis.com/tripi-assets/mytour/images/locations/vung-tau.png",
  },
  {
    code: "VCS-SGN",
    depart: "Côn Đảo",
    arrival: "Hồ Chí Minh",
    provinceId: "tp33",
    country: "Việt Nam",
    departCountryCode: "VN",
    returnCountryCode: "VN",
    thumb:
      "https://storage.googleapis.com/tripi-assets/mytour/images/locations/hochiminh.png",
  },
  {
    code: "HAN-HUI",
    depart: "Hà Nội",
    arrival: "Huế",
    provinceId: "tp1",
    country: "Việt Nam",
    departCountryCode: "VN",
    returnCountryCode: "VN",
    thumb:
      "https://storage.googleapis.com/tripi-assets/mytour/images/locations/hue.png",
  },
  {
    code: "HUI-HAN",
    depart: "Huế",
    arrival: "Hà Nội",
    provinceId: "tp11",
    country: "Việt Nam",
    departCountryCode: "VN",
    returnCountryCode: "VN",
    thumb:
      "https://storage.googleapis.com/tripi-assets/mytour/images/locations/hanoi.png",
  },
  {
    code: "UIH-HAN",
    depart: "Quy Nhơn",
    arrival: "Hà Nội",
    provinceId: "tp11",
    country: "Việt Nam",
    departCountryCode: "VN",
    returnCountryCode: "VN",
    thumb:
      "https://storage.googleapis.com/tripi-assets/mytour/images/locations/hanoi.png",
  },
  {
    code: "HAN-UIH",
    depart: "Hà Nội",
    arrival: "Quy Nhơn",
    provinceId: "td494",
    country: "Việt Nam",
    departCountryCode: "VN",
    returnCountryCode: "VN",
    thumb:
      "https://storage.googleapis.com/tripi-assets/mytour/images/locations/binh-dinh.png",
  },
  {
    code: "HAN-VCA",
    depart: "Hà Nội",
    arrival: "Cần Thơ",
    provinceId: "tp38",
    country: "Việt Nam",
    departCountryCode: "VN",
    returnCountryCode: "VN",
    thumb:
      "https://storage.googleapis.com/tripi-assets/mytour/images/locations/can-tho.png",
  },
  {
    code: "VCA-HAN",
    depart: "Cần Thơ",
    arrival: "Hà Nội",
    provinceId: "tp11",
    country: "Việt Nam",
    departCountryCode: "VN",
    returnCountryCode: "VN",
    thumb:
      "https://storage.googleapis.com/tripi-assets/mytour/images/locations/hanoi.png",
  },
  {
    code: "HAN-TBB",
    depart: "Hà Nội",
    arrival: "Tuy Hoà",
    provinceId: "td638",
    country: "Việt Nam",
    departCountryCode: "VN",
    returnCountryCode: "VN",
    thumb:
      "https://storage.googleapis.com/tripi-assets/mytour/images/locations/phu-yen.png",
  },
  {
    code: "TBB-HAN",
    depart: "Tuy Hoà",
    arrival: "Hà Nội",
    provinceId: "tp11",
    country: "Việt Nam",
    departCountryCode: "VN",
    returnCountryCode: "VN",
    thumb:
      "https://storage.googleapis.com/tripi-assets/mytour/images/locations/hanoi.png",
  },
  {
    code: "HAN-VII",
    depart: "Hà Nội",
    arrival: "Thành phố Vinh",
    provinceId: "td667",
    country: "Việt Nam",
    departCountryCode: "VN",
    returnCountryCode: "VN",
    thumb:
      "https://storage.googleapis.com/tripi-assets/mytour/images/locations/nghe-an.png",
  },
  {
    code: "VII-HAN",
    depart: "Thành phố Vinh",
    arrival: "Hà Nội",
    provinceId: "tp11",
    country: "Việt Nam",
    departCountryCode: "VN",
    returnCountryCode: "VN",
    thumb:
      "https://storage.googleapis.com/tripi-assets/mytour/images/locations/hanoi.png",
  },
  {
    code: "HAN-PXU",
    depart: "Hà Nội",
    arrival: "Pleiku",
    provinceId: "td466",
    country: "Việt Nam",
    departCountryCode: "VN",
    returnCountryCode: "VN",
    thumb: "https://cf.bstatic.com/images/hotel/max1024x768/282/282657139.jpg",
  },
  {
    code: "PXU-HAN",
    depart: "Pleiku",
    arrival: "Hà Nội",
    provinceId: "tp11",
    country: "Việt Nam",
    departCountryCode: "VN",
    returnCountryCode: "VN",
    thumb:
      "https://storage.googleapis.com/tripi-assets/mytour/images/locations/hanoi.png",
  },
  {
    code: "HAN-BMV",
    depart: "Hà Nội",
    arrival: "Ban Mê Thuột",
    provinceId: "td72",
    country: "Việt Nam",
    departCountryCode: "VN",
    returnCountryCode: "VN",
    thumb:
      "https://storage.googleapis.com/tripi-assets/images/banners/buonmethuot1.jpg",
  },
  {
    code: "BMV-HAN",
    depart: "Ban Mê Thuột",
    arrival: "Hà Nội",
    provinceId: "tp11",
    country: "Việt Nam",
    departCountryCode: "VN",
    returnCountryCode: "VN",
    thumb:
      "https://storage.googleapis.com/tripi-assets/mytour/images/locations/hanoi.png",
  },
  {
    code: "DAD-PQC",
    depart: "Đà Nẵng",
    arrival: "Phú Quốc",
    provinceId: "td446",
    country: "Việt Nam",
    departCountryCode: "VN",
    returnCountryCode: "VN",
    thumb:
      "https://storage.googleapis.com/tripi-assets/mytour/images/locations/phu-quoc.png",
  },
  {
    code: "PQC-DAD",
    depart: "Phú Quốc",
    arrival: "Đà Nẵng",
    provinceId: "tp50",
    country: "Việt Nam",
    departCountryCode: "VN",
    returnCountryCode: "VN",
    thumb:
      "https://storage.googleapis.com/tripi-assets/mytour/images/locations/danang.png",
  },
  {
    code: "HPH-PQC",
    depart: "Hải Phòng",
    arrival: "Phú Quốc",
    provinceId: "td446",
    country: "Việt Nam",
    departCountryCode: "VN",
    returnCountryCode: "VN",
    thumb:
      "https://storage.googleapis.com/tripi-assets/mytour/images/locations/phu-quoc.png",
  },
  {
    code: "PQC-HPH",
    depart: "Phú Quốc",
    arrival: "Hải Phòng",
    provinceId: "tp3",
    country: "Việt Nam",
    departCountryCode: "VN",
    returnCountryCode: "VN",
    thumb:
      "https://storage.googleapis.com/tripi-assets/mytour/images/locations/hai-phong.png",
  },
  {
    code: "DAD-HPH",
    depart: "Đà Nẵng",
    arrival: "Hải Phòng",
    provinceId: "tp3",
    country: "Việt Nam",
    departCountryCode: "VN",
    returnCountryCode: "VN",
    thumb:
      "https://storage.googleapis.com/tripi-assets/mytour/images/locations/hai-phong.png",
  },
  {
    code: "HPH-DAD",
    depart: "Hải Phòng",
    arrival: "Đà Nẵng",
    provinceId: "tp50",
    country: "Việt Nam",
    departCountryCode: "VN",
    returnCountryCode: "VN",
    thumb:
      "https://storage.googleapis.com/tripi-assets/mytour/images/locations/danang.png",
  },
  {
    code: "DAD-DLI",
    depart: "Đà Nẵng",
    arrival: "Đà Lạt",
    provinceId: "td155",
    country: "Việt Nam",
    departCountryCode: "VN",
    returnCountryCode: "VN",
    thumb:
      "https://storage.googleapis.com/tripi-assets/mytour/images/locations/da-lat.png",
  },
  {
    code: "DLI-DAD",
    depart: "Đà Lạt",
    arrival: "Đà Nẵng",
    provinceId: "tp50",
    country: "Việt Nam",
    departCountryCode: "VN",
    returnCountryCode: "VN",
    thumb:
      "https://storage.googleapis.com/tripi-assets/mytour/images/locations/danang.png",
  },
  {
    code: "DAD-CXR",
    depart: "Đà Nẵng",
    arrival: "Nha Trang",
    provinceId: "td414",
    country: "Việt Nam",
    departCountryCode: "VN",
    returnCountryCode: "VN",
    thumb:
      "https://storage.googleapis.com/tripi-assets/mytour/images/locations/nha-trang.png",
  },
  {
    code: "DAD-VCA",
    depart: "Đà Nẵng",
    arrival: "Cần Thơ",
    provinceId: "tp38",
    country: "Việt Nam",
    departCountryCode: "VN",
    returnCountryCode: "VN",
    thumb:
      "https://storage.googleapis.com/tripi-assets/mytour/images/locations/can-tho.png",
  },
  {
    code: "DAD-TBB",
    depart: "Đà Nẵng",
    arrival: "Tuy Hoà",
    provinceId: "td638",
    country: "Việt Nam",
    departCountryCode: "VN",
    returnCountryCode: "VN",
    thumb:
      "https://storage.googleapis.com/tripi-assets/mytour/images/locations/phu-yen.png",
  },
  {
    code: "SGN-HPH",
    depart: "Hồ Chí Minh",
    arrival: "Hải Phòng",
    provinceId: "tp3",
    country: "Việt Nam",
    departCountryCode: "VN",
    returnCountryCode: "VN",
    thumb:
      "https://storage.googleapis.com/tripi-assets/mytour/images/locations/hai-phong.png",
  },
  {
    code: "SGN-THD",
    depart: "Hồ Chí Minh",
    arrival: "Thanh Hóa",
    provinceId: "tp16",
    country: "Việt Nam",
    departCountryCode: "VN",
    returnCountryCode: "VN",
    thumb:
      "https://storage.googleapis.com/tripi-assets/mytour/images/locations/thanh-hoa.png",
  },
  {
    code: "THD-SGN",
    depart: "Thanh Hóa",
    arrival: "Hồ Chí Minh",
    provinceId: "tp33",
    country: "Việt Nam",
    departCountryCode: "VN",
    returnCountryCode: "VN",
    thumb:
      "https://storage.googleapis.com/tripi-assets/mytour/images/locations/hochiminh.png",
  },
  {
    code: "HPH-SGN",
    depart: "Hải Phòng",
    arrival: "Hồ Chí Minh",
    provinceId: "tp33",
    country: "Việt Nam",
    departCountryCode: "VN",
    returnCountryCode: "VN",
    thumb:
      "https://storage.googleapis.com/tripi-assets/mytour/images/locations/hochiminh.png",
  },
  {
    code: "SGN-VII",
    depart: "Hồ Chí Minh",
    arrival: "Thành phố Vinh",
    provinceId: "td667",
    country: "Việt Nam",
    departCountryCode: "VN",
    returnCountryCode: "VN",
    thumb:
      "https://storage.googleapis.com/tripi-assets/mytour/images/locations/nghe-an.png",
  },
  {
    code: "VII-SGN",
    depart: "Thành phố Vinh",
    arrival: "Hồ Chí Minh",
    provinceId: "tp33",
    country: "Việt Nam",
    departCountryCode: "VN",
    returnCountryCode: "VN",
    thumb:
      "https://storage.googleapis.com/tripi-assets/mytour/images/locations/hochiminh.png",
  },
  {
    code: "SGN-DLI",
    depart: "Hồ Chí Minh",
    arrival: "Đà Lạt",
    provinceId: "td155",
    country: "Việt Nam",
    departCountryCode: "VN",
    returnCountryCode: "VN",
    thumb:
      "https://storage.googleapis.com/tripi-assets/mytour/images/locations/da-lat.png",
  },
  {
    code: "DLI-SGN",
    depart: "Đà Lạt",
    arrival: "Hồ Chí Minh",
    provinceId: "tp33",
    country: "Việt Nam",
    departCountryCode: "VN",
    returnCountryCode: "VN",
    thumb:
      "https://storage.googleapis.com/tripi-assets/mytour/images/locations/hochiminh.png",
  },
  {
    code: "SGN-VDO",
    depart: "Hồ Chí Minh",
    arrival: "Quảng Ninh",
    provinceId: "tp10",
    country: "Việt Nam",
    departCountryCode: "VN",
    returnCountryCode: "VN",
    thumb:
      "https://storage.googleapis.com/tripi-assets/mytour/images/locations/ha-long.png",
  },
  {
    code: "SGN-HUI",
    depart: "Hồ Chí Minh",
    arrival: "Huế",
    provinceId: "tp1",
    country: "Việt Nam",
    departCountryCode: "VN",
    returnCountryCode: "VN",
    thumb:
      "https://storage.googleapis.com/tripi-assets/mytour/images/locations/hue.png",
  },
  {
    code: "SGN-PXU",
    depart: "Hồ Chí Minh",
    arrival: "Pleiku",
    provinceId: "td466",
    country: "Việt Nam",
    departCountryCode: "VN",
    returnCountryCode: "VN",
    thumb: "https://cf.bstatic.com/images/hotel/max1024x768/282/282657139.jpg",
  },
  {
    code: "SGN-BMV",
    depart: "Hồ Chí Minh",
    arrival: "Ban Mê Thuột",
    provinceId: "td72",
    country: "Việt Nam",
    departCountryCode: "VN",
    returnCountryCode: "VN",
    thumb:
      "https://storage.googleapis.com/tripi-assets/images/banners/buonmethuot2.jpg",
  },
  {
    code: "SGN-TBB",
    depart: "Hồ Chí Minh",
    arrival: "Tuy Hoà",
    provinceId: "td638",
    country: "Việt Nam",
    departCountryCode: "VN",
    returnCountryCode: "VN",
    thumb:
      "https://storage.googleapis.com/tripi-assets/mytour/images/locations/phu-yen.png",
  },
  {
    code: "SGN-UIH",
    depart: "Hồ Chí Minh",
    arrival: "Quy Nhơn",
    provinceId: "td494",
    country: "Việt Nam",
    departCountryCode: "VN",
    returnCountryCode: "VN",
    thumb:
      "https://storage.googleapis.com/tripi-assets/mytour/images/locations/binh-dinh.png",
  },
  {
    code: "SGN-VDH",
    depart: "Hồ Chí Minh",
    arrival: "Quảng Bình",
    provinceId: "tp12",
    country: "Việt Nam",
    departCountryCode: "VN",
    returnCountryCode: "VN",
    thumb:
      "https://pix10.agoda.net/hotelImages/5663774/-1/00f12730a33df7f9a31657bcdf749e93.jpg?s=1024x768",
  },
  {
    code: "HAN-VDH",
    depart: "Hà Nội",
    arrival: "Quảng Bình",
    provinceId: "tp12",
    country: "Việt Nam",
    departCountryCode: "VN",
    returnCountryCode: "VN",
    thumb:
      "https://pix10.agoda.net/hotelImages/5663774/-1/00f12730a33df7f9a31657bcdf749e93.jpg?s=1024x768",
  },
  {
    code: "VDH-HAN",
    depart: "Quảng Bình",
    arrival: "Hà Nội",
    provinceId: "tp11",
    country: "Việt Nam",
    departCountryCode: "VN",
    returnCountryCode: "VN",
    thumb:
      "https://storage.googleapis.com/tripi-assets/mytour/images/locations/hanoi.png",
  },
  {
    code: "VDH-SGN",
    depart: "Quảng Bình",
    arrival: "Hồ Chí Minh",
    provinceId: "tp33",
    country: "Việt Nam",
    departCountryCode: "VN",
    returnCountryCode: "VN",
    thumb:
      "https://storage.googleapis.com/tripi-assets/mytour/images/locations/hochiminh.png",
  },
  {
    code: "DAD-VII",
    depart: "Đà Nẵng",
    arrival: "Thành phố Vinh",
    provinceId: "td667",
    country: "Việt Nam",
    departCountryCode: "VN",
    returnCountryCode: "VN",
    thumb:
      "https://storage.googleapis.com/tripi-assets/mytour/images/locations/nghe-an.png",
  },
  {
    code: "VII-DAD",
    depart: "Thành phố Vinh",
    arrival: "Đà Nẵng",
    provinceId: "tp50",
    country: "Việt Nam",
    departCountryCode: "VN",
    returnCountryCode: "VN",
    thumb:
      "https://storage.googleapis.com/tripi-assets/mytour/images/locations/danang.png",
  },
  {
    code: "VCA-PQC",
    depart: "Cần Thơ",
    arrival: "Phú Quốc",
    provinceId: "td446",
    country: "Việt Nam",
    departCountryCode: "VN",
    returnCountryCode: "VN",
    thumb:
      "https://storage.googleapis.com/tripi-assets/mytour/images/locations/phu-quoc.png",
  },
  {
    code: "PQC-VCA",
    depart: "Phú Quốc",
    arrival: "Cần Thơ",
    provinceId: "tp38",
    country: "Việt Nam",
    departCountryCode: "VN",
    returnCountryCode: "VN",
    thumb:
      "https://storage.googleapis.com/tripi-assets/mytour/images/locations/can-tho.png",
  },
  {
    code: "VCA-VCS",
    depart: "Cần Thơ",
    arrival: "Côn Đảo",
    provinceId: "td134",
    country: "Việt Nam",
    departCountryCode: "VN",
    returnCountryCode: "VN",
    thumb:
      "https://storage.googleapis.com/tripi-assets/mytour/images/locations/vung-tau.png",
  },
  {
    code: "VCS-VCA",
    depart: "Côn Đảo",
    arrival: "Cần Thơ",
    provinceId: "tp38",
    country: "Việt Nam",
    departCountryCode: "VN",
    returnCountryCode: "VN",
    thumb:
      "https://storage.googleapis.com/tripi-assets/mytour/images/locations/can-tho.png",
  },
  {
    code: "HPH-CXR",
    depart: "Hải Phòng",
    arrival: "Nha Trang",
    provinceId: "td414",
    country: "Việt Nam",
    departCountryCode: "VN",
    returnCountryCode: "VN",
    thumb:
      "https://storage.googleapis.com/tripi-assets/mytour/images/locations/nha-trang.png",
  },
  {
    code: "CXR-HPH",
    depart: "Nha Trang",
    arrival: "Hải Phòng",
    provinceId: "td33",
    country: "Việt Nam",
    departCountryCode: "VN",
    returnCountryCode: "VN",
    thumb:
      "https://storage.googleapis.com/tripi-assets/mytour/images/locations/hai-phong.png",
  },
  {
    code: "HPH-DLI",
    depart: "Hải Phòng",
    arrival: "Đà Lạt",
    provinceId: "td155",
    country: "Việt Nam",
    departCountryCode: "VN",
    returnCountryCode: "VN",
    thumb:
      "https://storage.googleapis.com/tripi-assets/mytour/images/locations/da-lat.png",
  },
  {
    code: "DLI-HPH",
    depart: "Đà Lạt",
    arrival: "Hải Phòng",
    provinceId: "tp3",
    country: "Việt Nam",
    departCountryCode: "VN",
    returnCountryCode: "VN",
    thumb:
      "https://storage.googleapis.com/tripi-assets/mytour/images/locations/hai-phong.png",
  },
  {
    code: "HUI-DLI",
    depart: "Huế",
    arrival: "Đà Lạt",
    provinceId: "td155",
    country: "Việt Nam",
    departCountryCode: "VN",
    returnCountryCode: "VN",
    thumb:
      "https://storage.googleapis.com/tripi-assets/mytour/images/locations/da-lat.png",
  },
  {
    code: "DLI-HUI",
    depart: "Đà Lạt",
    arrival: "Huế",
    provinceId: "tp1",
    country: "Việt Nam",
    departCountryCode: "VN",
    returnCountryCode: "VN",
    thumb:
      "https://storage.googleapis.com/tripi-assets/mytour/images/locations/hue.png",
  },
  {
    code: "VII-DLI",
    depart: "Thành phố Vinh",
    arrival: "Đà Lạt",
    provinceId: "td155",
    country: "Việt Nam",
    departCountryCode: "VN",
    returnCountryCode: "VN",
    thumb:
      "https://storage.googleapis.com/tripi-assets/mytour/images/locations/da-lat.png",
  },
  {
    code: "DLI-VII",
    depart: "Đà Lạt",
    arrival: "Thành phố Vinh",
    provinceId: "td667",
    country: "Việt Nam",
    departCountryCode: "VN",
    returnCountryCode: "VN",
    thumb:
      "https://storage.googleapis.com/tripi-assets/mytour/images/locations/nghe-an.png",
  },
  {
    code: "HPH-VCS",
    depart: "Hải Phòng",
    arrival: "Côn Đảo",
    provinceId: "td134",
    country: "Việt Nam",
    departCountryCode: "VN",
    returnCountryCode: "VN",
    thumb:
      "https://storage.googleapis.com/tripi-assets/mytour/images/locations/vung-tau.png",
  },
  {
    code: "VCS-HPH",
    depart: "Côn Đảo",
    arrival: "Hải Phòng",
    provinceId: "tp33",
    country: "Việt Nam",
    departCountryCode: "VN",
    returnCountryCode: "VN",
    thumb:
      "https://storage.googleapis.com/tripi-assets/mytour/images/locations/hai-phong.png",
  },
  {
    code: "HAN-BKK",
    depart: "Hà Nội",
    arrival: "Bangkok, TH",
    country: "Thái Lan",
    departCountryCode: "VN",
    returnCountryCode: "TH",
    thumb:
      "http://wiki-travel.com.vn/Uploads/picture/qiongru-190504020529-bangkok-thai-lan.jpg",
  },
  {
    code: "SGN-BKK",
    depart: "Hồ Chí Minh",
    arrival: "Bangkok, TH",
    country: "Thái Lan",
    departCountryCode: "VN",
    returnCountryCode: "TH",
    thumb:
      "http://wiki-travel.com.vn/Uploads/picture/qiongru-190504020529-bangkok-thai-lan.jpg",
  },
  {
    code: "DAD-BKK",
    depart: "Đà Nẵng",
    arrival: "Bangkok, TH",
    country: "Thái Lan",
    departCountryCode: "VN",
    returnCountryCode: "TH",
    thumb:
      "http://wiki-travel.com.vn/Uploads/picture/qiongru-190504020529-bangkok-thai-lan.jpg",
  },
  {
    code: "HAN-SIN",
    depart: "Hà Nội",
    arrival: "Singapore, SG",
    country: "Singapore",
    departCountryCode: "VN",
    returnCountryCode: "SG",
    thumb:
      "http://c.files.bbci.co.uk/EE51/production/_106090016_gettyimages-1129205654.jpg",
  },
  {
    code: "SGN-SIN",
    depart: "Hồ Chí Minh",
    arrival: "Singapore, SG",
    country: "Singapore",
    departCountryCode: "VN",
    returnCountryCode: "SG",
    thumb:
      "http://c.files.bbci.co.uk/EE51/production/_106090016_gettyimages-1129205654.jpg",
  },
  {
    code: "DAD-SIN",
    depart: "Đà Nẵng",
    arrival: "Singapore, SG",
    country: "Singapore",
    departCountryCode: "VN",
    returnCountryCode: "SG",
    thumb:
      "http://c.files.bbci.co.uk/EE51/production/_106090016_gettyimages-1129205654.jpg",
  },
  {
    code: "HAN-KUL",
    depart: "Hà Nội",
    arrival: "Kuala Lumpur, KUL",
    country: "Malaysia",
    departCountryCode: "VN",
    returnCountryCode: "MY",
    thumb:
      "https://ik.imagekit.io/tvlk/blog/2022/04/kinh-nghiem-du-lich-malaysia-1.jpg",
  },
  {
    code: "SGN-KUL",
    depart: "Hồ Chí Minh",
    arrival: "Kuala Lumpur, KUL",
    country: "Malaysia",
    departCountryCode: "VN",
    returnCountryCode: "MY",
    thumb:
      "https://ik.imagekit.io/tvlk/blog/2022/04/kinh-nghiem-du-lich-malaysia-1.jpg",
  },
  {
    code: "DAD-KUL",
    depart: "Đà Nẵng",
    arrival: "Kuala Lumpur, KUL",
    country: "Malaysia",
    departCountryCode: "VN",
    returnCountryCode: "MY",
    thumb:
      "https://ik.imagekit.io/tvlk/blog/2022/04/kinh-nghiem-du-lich-malaysia-1.jpg",
  },
  {
    code: "HAN-ICN",
    depart: "Hà Nội",
    arrival: "Seoul Incheon, KR",
    country: "Hàn Quốc",
    departCountryCode: "VN",
    returnCountryCode: "KR",
    thumb: "https://avt.edu.vn/wp-content/uploads/2019/03/dai-hoc-o-seoul.jpg",
  },
  {
    code: "HAN-PUS",
    depart: "Hà Nội",
    arrival: "Busan, KR",
    country: "Hàn Quốc",
    departCountryCode: "VN",
    returnCountryCode: "KR",
    thumb:
      "https://korea.net.vn/wp-content/uploads/2017/03/thanh-pho-busan.jpg",
  },
  {
    code: "HAN-NRT",
    depart: "Hà Nội",
    arrival: "Narita Airport, JP",
    country: "Nhật Bản",
    departCountryCode: "VN",
    returnCountryCode: "JP",
    thumb:
      "https://nipiko.com/wp-content/uploads/2021/10/narita-airport-to-tokyo-800x600-1.jpg",
  },
  {
    code: "HAN-KIX",
    depart: "Hà Nội",
    arrival: "Osaka Kansai, JP",
    country: "Nhật Bản",
    departCountryCode: "VN",
    returnCountryCode: "JP",
    thumb:
      "https://songhantourist.com/upload/articles-images/1606529980-tim-hieu-ve-thanh-pho-osaka-nhat-ban-co-gi-hap-dan.jpg",
  },
  {
    code: "SGN-ICN",
    depart: "Hồ Chí Minh",
    arrival: "Seoul Incheon, KR",
    country: "Hàn Quốc",
    departCountryCode: "VN",
    returnCountryCode: "JP",
    thumb: "https://avt.edu.vn/wp-content/uploads/2019/03/dai-hoc-o-seoul.jpg",
  },
  {
    code: "SGN-PUS",
    depart: "Hồ Chí Minh",
    arrival: "Busan, KR",
    country: "Hàn Quốc",
    departCountryCode: "VN",
    returnCountryCode: "KR",
    thumb:
      "https://korea.net.vn/wp-content/uploads/2017/03/thanh-pho-busan.jpg",
  },
  {
    code: "SGN-NRT",
    depart: "Hồ Chí Minh",
    arrival: "Narita Airport, JP",
    country: "Nhật Bản",
    departCountryCode: "VN",
    returnCountryCode: "JP",
    thumb:
      "https://nipiko.com/wp-content/uploads/2021/10/narita-airport-to-tokyo-800x600-1.jpg",
  },
  {
    code: "SGN-KIX",
    depart: "Hồ Chí Minh",
    arrival: "Osaka Kansai, JP",
    country: "Nhật Bản",
    departCountryCode: "VN",
    returnCountryCode: "JP",
    thumb:
      "https://songhantourist.com/upload/articles-images/1606529980-tim-hieu-ve-thanh-pho-osaka-nhat-ban-co-gi-hap-dan.jpg",
  },
  {
    code: "DAD-ICN",
    depart: "Đà Nẵng",
    arrival: "Seoul Incheon, KR",
    country: "Hàn Quốc",
    departCountryCode: "VN",
    returnCountryCode: "KR",
    thumb: "https://avt.edu.vn/wp-content/uploads/2019/03/dai-hoc-o-seoul.jpg",
  },
  {
    code: "DAD-NRT",
    depart: "Đà Nẵng",
    arrival: "Narita Airport, JP",
    country: "Nhật Bản",
    departCountryCode: "VN",
    returnCountryCode: "JP",
    thumb:
      "https://nipiko.com/wp-content/uploads/2021/10/narita-airport-to-tokyo-800x600-1.jpg",
  },
  {
    code: "HAN-DEL",
    depart: "Hà Nội",
    arrival: "Delhi, India",
    country: "Ấn Độ",
    departCountryCode: "VN",
    returnCountryCode: "IN",
    thumb:
      "https://vyctravel.com/libs/upload/ckfinder/images/cate/1522400052_Banner%20%E1%BA%A4n%20%C4%90%E1%BB%98.jpg",
  },
  {
    code: "HAN-CDG",
    depart: "Hà Nội",
    arrival: "Paris, FR",
    country: "Pháp",
    departCountryCode: "VN",
    returnCountryCode: "FR",
    thumb:
      "https://www.cathaypacific.com/content/dam/destinations/paris/cityguide-gallery/paris-cityscape-920x500.jpg",
  },
  {
    code: "HAN-LHR",
    depart: "Hà Nội",
    arrival: "London Heathrow, GB",
    country: "Anh",
    departCountryCode: "VN",
    returnCountryCode: "GB",
    thumb:
      "https://menback.com/wp-content/uploads/2021/06/dong-ho-bigben-london.jpg",
  },
  {
    code: "HAN-SYD",
    depart: "Hà Nội",
    arrival: "Sydney, AU",
    country: "Úc",
    departCountryCode: "VN",
    returnCountryCode: "AU",
    thumb:
      "https://ngayhoiduhocuc.vn/wp-content/uploads/2021/12/0c67ff64-cf54-4886-91dd-89aa601463af.jpg",
  },
  {
    code: "SGN-SFO",
    depart: "Hồ Chí Minh",
    arrival: "San Francisco, US",
    country: "Mỹ",
    departCountryCode: "VN",
    returnCountryCode: "US",
    thumb:
      "https://www.cathaypacific.com/content/dam/destinations/san-francisco/cityguide-gallery/san-francisco_golden-gate-bridge_920x500.jpg",
  },
  {
    code: "SGN-LHR",
    depart: "Hồ Chí Minh",
    arrival: "London Heathrow, GB",
    country: "Anh",
    departCountryCode: "VN",
    returnCountryCode: "GB",
    thumb:
      "https://menback.com/wp-content/uploads/2021/06/dong-ho-bigben-london.jpg",
  },
  {
    code: "SGN-CDG",
    depart: "Hồ Chí Minh",
    arrival: "Paris, FR",
    country: "Pháp",
    departCountryCode: "VN",
    returnCountryCode: "FR",
    thumb:
      "https://www.cathaypacific.com/content/dam/destinations/paris/cityguide-gallery/paris-cityscape-920x500.jpg",
  },
  {
    code: "SGN-SYD",
    depart: "Hồ Chí Minh",
    arrival: "Sydney, AU",
    country: "Úc",
    departCountryCode: "VN",
    returnCountryCode: "AU",
    thumb:
      "https://ngayhoiduhocuc.vn/wp-content/uploads/2021/12/0c67ff64-cf54-4886-91dd-89aa601463af.jpg",
  },
];
