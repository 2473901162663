/* eslint-disable no-nested-ternary */
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Grow from '@mui/material/Grow';
import Snackbar from '@mui/material/Snackbar';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

function GrowTransition(props) {
  return <Grow {...props} />;
}

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '16px 0',
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
    borderBottom: `1px solid ${theme.palette.gray.grayLight22}`,
  },
  line: { display: 'flex', justifyContent: 'space-between' },
  bold: {
    fontSize: 16,
    lineHeight: '20px',
    color: theme.palette.black.black3,
    fontWeight: 600,
  },
  infoText: {
    fontSize: 14,
    lineHeight: '16px',
    color: theme.palette.black.black3,
  },
  blueText: {
    color: theme.palette.blue.blueLight3,
  },
  useNowButton: {
    backgroundColor: `${theme.palette.secondary.main} !important`,
    color: `${theme.palette.white.main} !important`,
    alignSelf: 'flex-end',
    padding: '6px 12px !important',
    borderRadius: '8px !important',
  },
}));

const PhoneCardInfo = (props) => {
  const { phoneCard, networkOperator, value = 0 } = props;
  const { t } = useTranslation();
  const [showSnackBar, setShowSnackBar] = useState(false);
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box className={classes.line}>
        <Box className={classes.bold}>{`${t('IDS_MT_PHONE_CARD')} ${
          networkOperator?.name
        }`}</Box>
        <Box className={classes.bold}>
          {value?.toLocaleString('de-DE') + 'đ'}
        </Box>
      </Box>
      <Box className={classes.line}>
        <Box className={classes.infoText}>{t('IDS_MT_PIN_CODE')}</Box>
        <Box className={clsx(classes.infoText, classes.blueText)}>
          {phoneCard?.pinCode}
        </Box>
      </Box>
      <Box className={classes.line}>
        <Box className={classes.infoText}>{t('IDS_MT_SERIAL')}</Box>
        <Box className={clsx(classes.infoText, classes.blueText)}>
          {phoneCard?.serial}
        </Box>
      </Box>
      <ButtonBase
        className={classes.useNowButton}
        onClick={() => {
          navigator.clipboard.writeText(phoneCard?.pinCode);
          setShowSnackBar(true);
        }}
      >
        {t('IDS_MT_USE_NOW')}
      </ButtonBase>
      <Snackbar
        autoHideDuration={3000}
        open={showSnackBar}
        onClose={() => setShowSnackBar(false)}
        TransitionComponent={GrowTransition}
        message="Copied!"
      />
    </Box>
  );
};

export default PhoneCardInfo;
