import api from 'utils/api';

export const getPhoneCardBooking = (params) => {
  const option = {
    method: 'get',
    url: '/mobile-card/bookings',
    params,
    noAuthentication: false,
  };
  return api(option);
};
