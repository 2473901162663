/* eslint-disable no-nested-ternary */
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import { GREEN, ORANGE, ORANGE_100, RED_500, WHITE } from 'assets/theme/colors';
import FlightBankTransfer from 'modules/desktop/flight/payment/orderDetail/FlightBankTransfer';
import moment from 'moment';
import { IconBookingFlightSuccess } from 'public/icons';
import { Trans, useTranslation } from 'react-i18next';
import { PINK_MT } from 'setupTheme';
import { C_DATE_TIME_FORMAT, FULL_TIME_FORMAT } from 'utils/moment';

const useStyles = makeStyles((theme) => ({
  boxStatus: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  boxHolder: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: ORANGE_100,
    color: ORANGE,
    padding: '4px 8px',
    borderRadius: 4,
    marginBottom: 8,
  },
  divider: {
    borderBottom: `1px solid ${theme.palette.gray.grayLight22}`,
    width: '100%',
  },
  wrapContainer: {
    padding: '32px 0 0',
    position: 'relative',
  },
  container: {
    margin: 'auto',
    maxWidth: 630,
    height: 'auto',
    padding: 24,
    background: WHITE,
    borderRadius: '8px 8px 0 0',
    borderTop: 'solid 4px',
    borderTopColor: GREEN,
  },
  mainStatus: {
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '19px',
    textAlign: 'center',
    color: theme.palette.black.black3,
    marginTop: '12px',
    marginBottom: '8px',
  },
  desc: {
    fontSize: '14px',
    lineHeight: '22px',
    textAlign: 'center',
    color: theme.palette.black.black3,
  },
  bankTransferWarning: {
    fontSize: '14px',
    lineHeight: '20px',
    textAlign: 'center',
    color: PINK_MT,
    marginTop: 16,
  },
}));

const BookingBankTranfer = ({
  bankTransferInfo = {},
  bankTransferOptions = [],
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const expiredTime = moment(bankTransferInfo?.expiredTime, C_DATE_TIME_FORMAT);

  return (
    <Box className={classes.wrapContainer}>
      <Box className={classes.container}>
        <Box className={classes.boxStatus}>
          <IconBookingFlightSuccess />
          <Box className={classes.mainStatus}>
            {t('IDS_TEXT_FLIGHT_BOOKING_SUCCESS')}
          </Box>
          <Box className={classes.desc}>
            <Trans
              i18nKey="IDS_TEXT_FLIGHT_BOOKING_BANK_TRANSFER_1"
              values={{
                time: expiredTime.format(FULL_TIME_FORMAT),
              }}
              components={[
                <b style={{ color: RED_500 }} />,
                <div style={{ fontSize: 12, lineHeight: '14px' }} />,
              ]}
            />
          </Box>
        </Box>
        {moment().isBefore(expiredTime) && (
          <>
            <Box className={classes.divider} sx={{ marginTop: '16px' }} />
            <FlightBankTransfer
              bankTransferInfo={bankTransferInfo}
              bankTransferOptions={bankTransferOptions}
            />
            <Box className={classes.bankTransferWarning}>
              {t('IDS_TEXT_BANKTRANSFER_WARNING')}
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default BookingBankTranfer;
