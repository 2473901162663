import { Box, ButtonBase } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { GREY_300, WHITE } from 'assets/theme/colors';
import clsx from 'clsx';
import { IconCopy } from 'public/icons';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {
  formatMoneyCurrency,
  createDownloadFile,
} from 'utils/helpersDataHotel';

const useStyles = makeStyles((theme) => ({
  boxCode: {
    background: WHITE,
    borderRadius: 20,
    display: 'flex',
    justifyContent: 'space-around',
    textAlign: 'center',
    alignItems: 'center',
    padding: '24px 0',
  },
  copy: {
    display: 'flex',
    color: '#00B6F3 !important',
    alginItems: 'center',
    fontSize: '14px',
    lineHeight: '17px',
    gap: '4px',
  },
  iconBank: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  bankItem: {
    borderRadius: 4,
    border: `1px solid ${GREY_300}`,
    height: 24,
    width: 48,
    backgroundColor: WHITE,
  },
  wrapBank: {
    display: 'flex',
    // alignItems: 'center',
    justifyContent: 'space-between',
    whiteSpace: 'nowrap',
    fontSize: 14,
    lineHeight: '17px',
    marginTop: 12,
  },
  bankText: {
    fontWeight: 600,
    maxWidth: 220,
  },
  divider: {
    borderBottom: `1px solid ${theme.palette.gray.grayLight23}`,
    width: '100%',
  },
  flightBankTransferContainer: {
    background: '#F7FAFC',
    padding: 12,
    marginTop: '16px',
    marginBottom: 0,
    border: '1px dashed #CBD5E0',
    borderRadius: 8,
  },
  title: {
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '17px',
    color: theme.palette.black.black3,
  },
  info: {
    fontSize: '14px',
    lineHeight: '17px',
    color: theme.palette.black.black3,
  },
  rightInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    gap: '4px',
  },
  imgOrder: {},
  bank: {
    display: 'flex',
    justifyContent: 'flex-end',
    alginItems: 'center',
  },
  infoBank: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 600,
    maxWidth: 161,
    fontSize: '14px',
    lineHeight: '17px',
    color: theme.palette.black.black3,
  },
}));

const FlightBankTransfer = ({
  bankTransferInfo = {},
  bankTransferOptions = [],
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleCoppyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    toast.success(t('IDS_COPY_SUCCESS'));
  };

  return (
    <Box className={classes.flightBankTransferContainer}>
      <Box className={classes.title}>
        {t('IDS_MT_TEXT_INFO_PAYMENT_TRANSFER')}
      </Box>
      {bankTransferOptions?.map((el, index) => (
        <Box key={`${el?.bankId}${el?.accountNumber}`}>
          <Box display="flex" justifyContent="space-between" mt={12 / 8}>
            <Box className={classes.info}>
              {t('IDS_MT_TEXT_INFO_PAYMENT_TRANSFER_BANK')}
            </Box>
            <Box className={classes.bank}>
              <Box
                className={classes.infoBank}
                sx={{ textAlign: 'end', fontWeight: 600 }}
              >
                {el?.bankName}
              </Box>
              <Box className={classes.bankItem}>
                <img src={el?.bankLogo} className={classes.iconBank} alt="" />
              </Box>
            </Box>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            mt={12 / 8}
            mb={12 / 8}
          >
            <Box className={classes.info}>
              {t('IDS_MT_TEXT_INFO_PAYMENT_TRANSFER_NUMBER_ACCOUNT')}
            </Box>
            <Box className={classes.rightInfo}>
              <Box className={clsx(classes.info, classes.bankText)}>
                {el?.accountNumber}
              </Box>
              <ButtonBase
                className={classes.copy}
                onClick={() => {
                  handleCoppyToClipboard(el?.accountNumber);
                }}
              >
                <IconCopy />
                {t('IDS_MT_TEXT_INFO_COPY')}
              </ButtonBase>
            </Box>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            mt={12 / 8}
            mb={12 / 8}
          >
            <Box className={classes.info}>{t('IDS_ACCOUNT_NAME')}</Box>
            <Box
              className={classes.info}
              sx={{ maxWidth: '60%', fontWeight: 600 }}
            >
              {el?.accountName}
            </Box>
          </Box>
        </Box>
      ))}
      <Box className={classes.divider} />
      <Box display="flex" justifyContent="space-between" mt={12 / 8}>
        <Box className={classes.info}>{t('IDS_MT_TEXT_AMOUNT_MONEY')}</Box>
        <Box className={classes.rightInfo}>
          <Box className={clsx(classes.info, classes.bankText)}>
            {`${formatMoneyCurrency(bankTransferInfo?.totalAmount)}`}
          </Box>
          <ButtonBase
            className={classes.copy}
            onClick={() => {
              handleCoppyToClipboard(
                `${formatMoneyCurrency(bankTransferInfo?.totalAmount)}`
              );
            }}
          >
            <IconCopy />
            {t('IDS_MT_TEXT_INFO_COPY')}
          </ButtonBase>
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between" mt={12 / 8}>
        <Box className={classes.info}>{t('IDS_MT_TEXT_CONTENT_TRANSFER')}</Box>
        <Box className={classes.rightInfo}>
          <Box className={clsx(classes.info, classes.bankText)}>
            {bankTransferInfo?.message}
          </Box>
          <ButtonBase
            className={classes.copy}
            onClick={() => {
              handleCoppyToClipboard(bankTransferInfo?.message);
            }}
          >
            <IconCopy />
            {t('IDS_MT_TEXT_INFO_COPY')}
          </ButtonBase>
        </Box>
      </Box>
      {bankTransferOptions?.[0]?.qrImage && (
        <Box
          display="flex"
          justifyContent="center"
          mt={12 / 8}
          flexDirection="column"
          alignItems="center"
        >
          <Box className={classes.imgOrder}>
            <img
              src={bankTransferOptions?.[0]?.qrImage}
              alt=""
              style={{
                width: 214,
                height: 214,
                objectFit: 'cover',
                border: '1px solid #E2E8F0',
                borderRadius: 8,
              }}
            />
          </Box>
          <Box
            mt={1 / 2}
            fontSize="14px"
            color="#00B6F3"
            fontWeight="600"
            onClick={() => {
              createDownloadFile(bankTransferOptions?.[0]?.qrImage, 'qr_code');
            }}
          >
            {t('IDS_TEXT_QR_MOBILE')}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default FlightBankTransfer;
