/* eslint-disable no-nested-ternary */
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import { WHITE } from 'assets/theme/colors';
import { IconBookingFlightFail } from 'public/icons';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  boxStatus: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  container: {
    margin: 'auto',
    maxWidth: 630,
    height: 'auto',
    padding: '24px 0 24px 0',
    background: WHITE,
    borderRadius: '20px',
  },
  mainStatus: {
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '19px',
    textAlign: 'center',
    color: theme.palette.black.black3,
    marginTop: '12px',
    marginBottom: '8px',
  },
  desc: {
    fontSize: '14px',
    lineHeight: '22px',
    textAlign: 'center',
    color: theme.palette.black.black3,
  },
}));

const BookingFail = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Box className={classes.container}>
      <Box className={classes.boxStatus}>
        <IconBookingFlightFail />
        <Box className={classes.mainStatus}>{t('IDS_TEXT_FAIL')}</Box>
        <Box className={classes.desc}>
          {t('IDS_TEXT_FLIGHT_BOOKING_RESULT_DESC_FAIL')}
        </Box>
      </Box>
    </Box>
  );
};

export default BookingFail;
